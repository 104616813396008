@charset "UTF-8";
/* For use in app/styles/color-palette.scss */
.flag-icon-background {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat; }

.flag-icon {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  width: 1.33333333em;
  line-height: 1em; }

.flag-icon:before {
  content: "\00a0"; }

.flag-icon.flag-icon-squared {
  width: 1em; }

.flag-icon-ad {
  background-image: url(../images/flags/4x3/ad.svg); }

.flag-icon-ad.flag-icon-squared {
  background-image: url(../images/1x1/ad.svg); }

.flag-icon-ae {
  background-image: url(../images/flags/4x3/ae.svg); }

.flag-icon-ae.flag-icon-squared {
  background-image: url(../images/flags/1x1/ae.svg); }

.flag-icon-af {
  background-image: url(../images/flags/4x3/af.svg); }

.flag-icon-af.flag-icon-squared {
  background-image: url(../images/flags/1x1/af.svg); }

.flag-icon-ag {
  background-image: url(../images/flags/4x3/ag.svg); }

.flag-icon-ag.flag-icon-squared {
  background-image: url(../images/flags/1x1/ag.svg); }

.flag-icon-ai {
  background-image: url(../images/flags/4x3/ai.svg); }

.flag-icon-ai.flag-icon-squared {
  background-image: url(../images/flags/1x1/ai.svg); }

.flag-icon-al {
  background-image: url(../images/flags/4x3/al.svg); }

.flag-icon-al.flag-icon-squared {
  background-image: url(../images/flags/1x1/al.svg); }

.flag-icon-am {
  background-image: url(../images/flags/4x3/am.svg); }

.flag-icon-am.flag-icon-squared {
  background-image: url(../images/flags/1x1/am.svg); }

.flag-icon-ao {
  background-image: url(../images/flags/4x3/ao.svg); }

.flag-icon-ao.flag-icon-squared {
  background-image: url(../images/flags/1x1/ao.svg); }

.flag-icon-aq {
  background-image: url(../images/flags/4x3/aq.svg); }

.flag-icon-aq.flag-icon-squared {
  background-image: url(../images/flags/1x1/aq.svg); }

.flag-icon-ar {
  background-image: url(../images/flags/4x3/ar.svg); }

.flag-icon-ar.flag-icon-squared {
  background-image: url(../images/flags/1x1/ar.svg); }

.flag-icon-as {
  background-image: url(../images/flags/4x3/as.svg); }

.flag-icon-as.flag-icon-squared {
  background-image: url(../images/flags/1x1/as.svg); }

.flag-icon-at {
  background-image: url(../images/flags/4x3/at.svg); }

.flag-icon-at.flag-icon-squared {
  background-image: url(../images/flags/1x1/at.svg); }

.flag-icon-au {
  background-image: url(../images/flags/4x3/au.svg); }

.flag-icon-au.flag-icon-squared {
  background-image: url(../images/flags/1x1/au.svg); }

.flag-icon-aw {
  background-image: url(../images/flags/4x3/aw.svg); }

.flag-icon-aw.flag-icon-squared {
  background-image: url(../images/flags/1x1/aw.svg); }

.flag-icon-ax {
  background-image: url(../images/flags/4x3/ax.svg); }

.flag-icon-ax.flag-icon-squared {
  background-image: url(../images/flags/1x1/ax.svg); }

.flag-icon-az {
  background-image: url(../images/flags/4x3/az.svg); }

.flag-icon-az.flag-icon-squared {
  background-image: url(../images/flags/1x1/az.svg); }

.flag-icon-ba {
  background-image: url(../images/flags/4x3/ba.svg); }

.flag-icon-ba.flag-icon-squared {
  background-image: url(../images/flags/1x1/ba.svg); }

.flag-icon-bb {
  background-image: url(../images/flags/4x3/bb.svg); }

.flag-icon-bb.flag-icon-squared {
  background-image: url(../images/flags/1x1/bb.svg); }

.flag-icon-bd {
  background-image: url(../images/flags/4x3/bd.svg); }

.flag-icon-bd.flag-icon-squared {
  background-image: url(../images/flags/1x1/bd.svg); }

.flag-icon-be {
  background-image: url(../images/flags/4x3/be.svg); }

.flag-icon-be.flag-icon-squared {
  background-image: url(../images/flags/1x1/be.svg); }

.flag-icon-bf {
  background-image: url(../images/flags/4x3/bf.svg); }

.flag-icon-bf.flag-icon-squared {
  background-image: url(../images/flags/1x1/bf.svg); }

.flag-icon-bg {
  background-image: url(../images/flags/4x3/bg.svg); }

.flag-icon-bg.flag-icon-squared {
  background-image: url(../images/flags/1x1/bg.svg); }

.flag-icon-bh {
  background-image: url(../images/flags/4x3/bh.svg); }

.flag-icon-bh.flag-icon-squared {
  background-image: url(../images/flags/1x1/bh.svg); }

.flag-icon-bi {
  background-image: url(../images/flags/4x3/bi.svg); }

.flag-icon-bi.flag-icon-squared {
  background-image: url(../images/flags/1x1/bi.svg); }

.flag-icon-bj {
  background-image: url(../images/flags/4x3/bj.svg); }

.flag-icon-bj.flag-icon-squared {
  background-image: url(../images/flags/1x1/bj.svg); }

.flag-icon-bl {
  background-image: url(../images/flags/4x3/bl.svg); }

.flag-icon-bl.flag-icon-squared {
  background-image: url(../images/flags/1x1/bl.svg); }

.flag-icon-bm {
  background-image: url(../images/flags/4x3/bm.svg); }

.flag-icon-bm.flag-icon-squared {
  background-image: url(../images/flags/1x1/bm.svg); }

.flag-icon-bn {
  background-image: url(../images/flags/4x3/bn.svg); }

.flag-icon-bn.flag-icon-squared {
  background-image: url(../images/flags/1x1/bn.svg); }

.flag-icon-bo {
  background-image: url(../images/flags/4x3/bo.svg); }

.flag-icon-bo.flag-icon-squared {
  background-image: url(../images/flags/1x1/bo.svg); }

.flag-icon-bq {
  background-image: url(../images/flags/4x3/bq.svg); }

.flag-icon-bq.flag-icon-squared {
  background-image: url(../images/flags/1x1/bq.svg); }

.flag-icon-br {
  background-image: url(../images/flags/4x3/br.svg); }

.flag-icon-br.flag-icon-squared {
  background-image: url(../images/flags/1x1/br.svg); }

.flag-icon-bs {
  background-image: url(../images/flags/4x3/bs.svg); }

.flag-icon-bs.flag-icon-squared {
  background-image: url(../images/flags/1x1/bs.svg); }

.flag-icon-bt {
  background-image: url(../images/flags/4x3/bt.svg); }

.flag-icon-bt.flag-icon-squared {
  background-image: url(../images/flags/1x1/bt.svg); }

.flag-icon-bv {
  background-image: url(../images/flags/4x3/bv.svg); }

.flag-icon-bv.flag-icon-squared {
  background-image: url(../images/flags/1x1/bv.svg); }

.flag-icon-bw {
  background-image: url(../images/flags/4x3/bw.svg); }

.flag-icon-bw.flag-icon-squared {
  background-image: url(../images/flags/1x1/bw.svg); }

.flag-icon-by {
  background-image: url(../images/flags/4x3/by.svg); }

.flag-icon-by.flag-icon-squared {
  background-image: url(../images/flags/1x1/by.svg); }

.flag-icon-bz {
  background-image: url(../images/flags/4x3/bz.svg); }

.flag-icon-bz.flag-icon-squared {
  background-image: url(../images/flags/1x1/bz.svg); }

.flag-icon-ca {
  background-image: url(../images/flags/4x3/ca.svg); }

.flag-icon-ca.flag-icon-squared {
  background-image: url(../images/flags/1x1/ca.svg); }

.flag-icon-cc {
  background-image: url(../images/flags/4x3/cc.svg); }

.flag-icon-cc.flag-icon-squared {
  background-image: url(../images/flags/1x1/cc.svg); }

.flag-icon-cd {
  background-image: url(../images/flags/4x3/cd.svg); }

.flag-icon-cd.flag-icon-squared {
  background-image: url(../images/flags/1x1/cd.svg); }

.flag-icon-cf {
  background-image: url(../images/flags/4x3/cf.svg); }

.flag-icon-cf.flag-icon-squared {
  background-image: url(../images/flags/1x1/cf.svg); }

.flag-icon-cg {
  background-image: url(../images/flags/4x3/cg.svg); }

.flag-icon-cg.flag-icon-squared {
  background-image: url(../images/flags/1x1/cg.svg); }

.flag-icon-ch {
  background-image: url(../images/flags/4x3/ch.svg); }

.flag-icon-ch.flag-icon-squared {
  background-image: url(../images/flags/1x1/ch.svg); }

.flag-icon-ci {
  background-image: url(../images/flags/4x3/ci.svg); }

.flag-icon-ci.flag-icon-squared {
  background-image: url(../images/flags/1x1/ci.svg); }

.flag-icon-ck {
  background-image: url(../images/flags/4x3/ck.svg); }

.flag-icon-ck.flag-icon-squared {
  background-image: url(../images/flags/1x1/ck.svg); }

.flag-icon-cl {
  background-image: url(../images/flags/4x3/cl.svg); }

.flag-icon-cl.flag-icon-squared {
  background-image: url(../images/flags/1x1/cl.svg); }

.flag-icon-cm {
  background-image: url(../images/flags/4x3/cm.svg); }

.flag-icon-cm.flag-icon-squared {
  background-image: url(../images/flags/1x1/cm.svg); }

.flag-icon-cn {
  background-image: url(../images/flags/4x3/cn.svg); }

.flag-icon-cn.flag-icon-squared {
  background-image: url(../images/flags/1x1/cn.svg); }

.flag-icon-co {
  background-image: url(../images/flags/4x3/co.svg); }

.flag-icon-co.flag-icon-squared {
  background-image: url(../images/flags/1x1/co.svg); }

.flag-icon-cr {
  background-image: url(../images/flags/4x3/cr.svg); }

.flag-icon-cr.flag-icon-squared {
  background-image: url(../images/flags/1x1/cr.svg); }

.flag-icon-cu {
  background-image: url(../images/flags/4x3/cu.svg); }

.flag-icon-cu.flag-icon-squared {
  background-image: url(../images/flags/1x1/cu.svg); }

.flag-icon-cv {
  background-image: url(../images/flags/4x3/cv.svg); }

.flag-icon-cv.flag-icon-squared {
  background-image: url(../images/flags/1x1/cv.svg); }

.flag-icon-cw {
  background-image: url(../images/flags/4x3/cw.svg); }

.flag-icon-cw.flag-icon-squared {
  background-image: url(../images/flags/1x1/cw.svg); }

.flag-icon-cx {
  background-image: url(../images/flags/4x3/cx.svg); }

.flag-icon-cx.flag-icon-squared {
  background-image: url(../images/flags/1x1/cx.svg); }

.flag-icon-cy {
  background-image: url(../images/flags/4x3/cy.svg); }

.flag-icon-cy.flag-icon-squared {
  background-image: url(../images/flags/1x1/cy.svg); }

.flag-icon-cz {
  background-image: url(../images/flags/4x3/cz.svg); }

.flag-icon-cz.flag-icon-squared {
  background-image: url(../images/flags/1x1/cz.svg); }

.flag-icon-de {
  background-image: url(../images/flags/4x3/de.svg); }

.flag-icon-de.flag-icon-squared {
  background-image: url(../images/flags/1x1/de.svg); }

.flag-icon-dj {
  background-image: url(../images/flags/4x3/dj.svg); }

.flag-icon-dj.flag-icon-squared {
  background-image: url(../images/flags/1x1/dj.svg); }

.flag-icon-dk {
  background-image: url(../images/flags/4x3/dk.svg); }

.flag-icon-dk.flag-icon-squared {
  background-image: url(../images/flags/1x1/dk.svg); }

.flag-icon-dm {
  background-image: url(../images/flags/4x3/dm.svg); }

.flag-icon-dm.flag-icon-squared {
  background-image: url(../images/flags/1x1/dm.svg); }

.flag-icon-do {
  background-image: url(../images/flags/4x3/do.svg); }

.flag-icon-do.flag-icon-squared {
  background-image: url(../images/flags/1x1/do.svg); }

.flag-icon-dz {
  background-image: url(../images/flags/4x3/dz.svg); }

.flag-icon-dz.flag-icon-squared {
  background-image: url(../images/flags/1x1/dz.svg); }

.flag-icon-ec {
  background-image: url(../images/flags/4x3/ec.svg); }

.flag-icon-ec.flag-icon-squared {
  background-image: url(../images/flags/1x1/ec.svg); }

.flag-icon-ee {
  background-image: url(../images/flags/4x3/ee.svg); }

.flag-icon-ee.flag-icon-squared {
  background-image: url(../images/flags/1x1/ee.svg); }

.flag-icon-eg {
  background-image: url(../images/flags/4x3/eg.svg); }

.flag-icon-eg.flag-icon-squared {
  background-image: url(../images/flags/1x1/eg.svg); }

.flag-icon-eh {
  background-image: url(../images/flags/4x3/eh.svg); }

.flag-icon-eh.flag-icon-squared {
  background-image: url(../images/flags/1x1/eh.svg); }

.flag-icon-er {
  background-image: url(../images/flags/4x3/er.svg); }

.flag-icon-er.flag-icon-squared {
  background-image: url(../images/flags/1x1/er.svg); }

.flag-icon-es {
  background-image: url(../images/flags/4x3/es.svg); }

.flag-icon-es.flag-icon-squared {
  background-image: url(../images/flags/1x1/es.svg); }

.flag-icon-et {
  background-image: url(../images/flags/4x3/et.svg); }

.flag-icon-et.flag-icon-squared {
  background-image: url(../images/flags/1x1/et.svg); }

.flag-icon-fi {
  background-image: url(../images/flags/4x3/fi.svg); }

.flag-icon-fi.flag-icon-squared {
  background-image: url(../images/flags/1x1/fi.svg); }

.flag-icon-fj {
  background-image: url(../images/flags/4x3/fj.svg); }

.flag-icon-fj.flag-icon-squared {
  background-image: url(../images/flags/1x1/fj.svg); }

.flag-icon-fk {
  background-image: url(../images/flags/4x3/fk.svg); }

.flag-icon-fk.flag-icon-squared {
  background-image: url(../images/flags/1x1/fk.svg); }

.flag-icon-fm {
  background-image: url(../images/flags/4x3/fm.svg); }

.flag-icon-fm.flag-icon-squared {
  background-image: url(../images/flags/1x1/fm.svg); }

.flag-icon-fo {
  background-image: url(../images/flags/4x3/fo.svg); }

.flag-icon-fo.flag-icon-squared {
  background-image: url(../images/flags/1x1/fo.svg); }

.flag-icon-fr {
  background-image: url(../images/flags/4x3/fr.svg); }

.flag-icon-fr.flag-icon-squared {
  background-image: url(../images/flags/1x1/fr.svg); }

.flag-icon-ga {
  background-image: url(../images/flags/4x3/ga.svg); }

.flag-icon-ga.flag-icon-squared {
  background-image: url(../images/flags/1x1/ga.svg); }

.flag-icon-gb {
  background-image: url(../images/flags/4x3/gb.svg); }

.flag-icon-gb.flag-icon-squared {
  background-image: url(../images/flags/1x1/gb.svg); }

.flag-icon-gd {
  background-image: url(../images/flags/4x3/gd.svg); }

.flag-icon-gd.flag-icon-squared {
  background-image: url(../images/flags/1x1/gd.svg); }

.flag-icon-ge {
  background-image: url(../images/flags/4x3/ge.svg); }

.flag-icon-ge.flag-icon-squared {
  background-image: url(../images/flags/1x1/ge.svg); }

.flag-icon-gf {
  background-image: url(../images/flags/4x3/gf.svg); }

.flag-icon-gf.flag-icon-squared {
  background-image: url(../images/flags/1x1/gf.svg); }

.flag-icon-gg {
  background-image: url(../images/flags/4x3/gg.svg); }

.flag-icon-gg.flag-icon-squared {
  background-image: url(../images/flags/1x1/gg.svg); }

.flag-icon-gh {
  background-image: url(../images/flags/4x3/gh.svg); }

.flag-icon-gh.flag-icon-squared {
  background-image: url(../images/flags/1x1/gh.svg); }

.flag-icon-gi {
  background-image: url(../images/flags/4x3/gi.svg); }

.flag-icon-gi.flag-icon-squared {
  background-image: url(../images/flags/1x1/gi.svg); }

.flag-icon-gl {
  background-image: url(../images/flags/4x3/gl.svg); }

.flag-icon-gl.flag-icon-squared {
  background-image: url(../images/flags/1x1/gl.svg); }

.flag-icon-gm {
  background-image: url(../images/flags/4x3/gm.svg); }

.flag-icon-gm.flag-icon-squared {
  background-image: url(../images/flags/1x1/gm.svg); }

.flag-icon-gn {
  background-image: url(../images/flags/4x3/gn.svg); }

.flag-icon-gn.flag-icon-squared {
  background-image: url(../images/flags/1x1/gn.svg); }

.flag-icon-gp {
  background-image: url(../images/flags/4x3/gp.svg); }

.flag-icon-gp.flag-icon-squared {
  background-image: url(../images/flags/1x1/gp.svg); }

.flag-icon-gq {
  background-image: url(../images/flags/4x3/gq.svg); }

.flag-icon-gq.flag-icon-squared {
  background-image: url(../images/flags/1x1/gq.svg); }

.flag-icon-gr {
  background-image: url(../images/flags/4x3/gr.svg); }

.flag-icon-gr.flag-icon-squared {
  background-image: url(../images/flags/1x1/gr.svg); }

.flag-icon-gs {
  background-image: url(../images/flags/4x3/gs.svg); }

.flag-icon-gs.flag-icon-squared {
  background-image: url(../images/flags/1x1/gs.svg); }

.flag-icon-gt {
  background-image: url(../images/flags/4x3/gt.svg); }

.flag-icon-gt.flag-icon-squared {
  background-image: url(../images/flags/1x1/gt.svg); }

.flag-icon-gu {
  background-image: url(../images/flags/4x3/gu.svg); }

.flag-icon-gu.flag-icon-squared {
  background-image: url(../images/flags/1x1/gu.svg); }

.flag-icon-gw {
  background-image: url(../images/flags/4x3/gw.svg); }

.flag-icon-gw.flag-icon-squared {
  background-image: url(../images/flags/1x1/gw.svg); }

.flag-icon-gy {
  background-image: url(../images/flags/4x3/gy.svg); }

.flag-icon-gy.flag-icon-squared {
  background-image: url(../images/flags/1x1/gy.svg); }

.flag-icon-hk {
  background-image: url(../images/flags/4x3/hk.svg); }

.flag-icon-hk.flag-icon-squared {
  background-image: url(../images/flags/1x1/hk.svg); }

.flag-icon-hm {
  background-image: url(../images/flags/4x3/hm.svg); }

.flag-icon-hm.flag-icon-squared {
  background-image: url(../images/flags/1x1/hm.svg); }

.flag-icon-hn {
  background-image: url(../images/flags/4x3/hn.svg); }

.flag-icon-hn.flag-icon-squared {
  background-image: url(../images/flags/1x1/hn.svg); }

.flag-icon-hr {
  background-image: url(../images/flags/4x3/hr.svg); }

.flag-icon-hr.flag-icon-squared {
  background-image: url(../images/flags/1x1/hr.svg); }

.flag-icon-ht {
  background-image: url(../images/flags/4x3/ht.svg); }

.flag-icon-ht.flag-icon-squared {
  background-image: url(../images/flags/1x1/ht.svg); }

.flag-icon-hu {
  background-image: url(../images/flags/4x3/hu.svg); }

.flag-icon-hu.flag-icon-squared {
  background-image: url(../images/flags/1x1/hu.svg); }

.flag-icon-id {
  background-image: url(../images/flags/4x3/id.svg); }

.flag-icon-id.flag-icon-squared {
  background-image: url(../images/flags/1x1/id.svg); }

.flag-icon-ie {
  background-image: url(../images/flags/4x3/ie.svg); }

.flag-icon-ie.flag-icon-squared {
  background-image: url(../images/flags/1x1/ie.svg); }

.flag-icon-il {
  background-image: url(../images/flags/4x3/il.svg); }

.flag-icon-il.flag-icon-squared {
  background-image: url(../images/flags/1x1/il.svg); }

.flag-icon-im {
  background-image: url(../images/flags/4x3/im.svg); }

.flag-icon-im.flag-icon-squared {
  background-image: url(../images/flags/1x1/im.svg); }

.flag-icon-in {
  background-image: url(../images/flags/4x3/in.svg); }

.flag-icon-in.flag-icon-squared {
  background-image: url(../images/flags/1x1/in.svg); }

.flag-icon-io {
  background-image: url(../images/flags/4x3/io.svg); }

.flag-icon-io.flag-icon-squared {
  background-image: url(../images/flags/1x1/io.svg); }

.flag-icon-iq {
  background-image: url(../images/flags/4x3/iq.svg); }

.flag-icon-iq.flag-icon-squared {
  background-image: url(../images/flags/1x1/iq.svg); }

.flag-icon-ir {
  background-image: url(../images/flags/4x3/ir.svg); }

.flag-icon-ir.flag-icon-squared {
  background-image: url(../images/flags/1x1/ir.svg); }

.flag-icon-is {
  background-image: url(../images/flags/4x3/is.svg); }

.flag-icon-is.flag-icon-squared {
  background-image: url(../images/flags/1x1/is.svg); }

.flag-icon-it {
  background-image: url(../images/flags/4x3/it.svg); }

.flag-icon-it.flag-icon-squared {
  background-image: url(../images/flags/1x1/it.svg); }

.flag-icon-je {
  background-image: url(../images/flags/4x3/je.svg); }

.flag-icon-je.flag-icon-squared {
  background-image: url(../images/flags/1x1/je.svg); }

.flag-icon-jm {
  background-image: url(../images/flags/4x3/jm.svg); }

.flag-icon-jm.flag-icon-squared {
  background-image: url(../images/flags/1x1/jm.svg); }

.flag-icon-jo {
  background-image: url(../images/flags/4x3/jo.svg); }

.flag-icon-jo.flag-icon-squared {
  background-image: url(../images/flags/1x1/jo.svg); }

.flag-icon-jp {
  background-image: url(../images/flags/4x3/jp.svg); }

.flag-icon-jp.flag-icon-squared {
  background-image: url(../images/flags/1x1/jp.svg); }

.flag-icon-ke {
  background-image: url(../images/flags/4x3/ke.svg); }

.flag-icon-ke.flag-icon-squared {
  background-image: url(../images/flags/1x1/ke.svg); }

.flag-icon-kg {
  background-image: url(../images/flags/4x3/kg.svg); }

.flag-icon-kg.flag-icon-squared {
  background-image: url(../images/flags/1x1/kg.svg); }

.flag-icon-kh {
  background-image: url(../images/flags/4x3/kh.svg); }

.flag-icon-kh.flag-icon-squared {
  background-image: url(../images/flags/1x1/kh.svg); }

.flag-icon-ki {
  background-image: url(../images/flags/4x3/ki.svg); }

.flag-icon-ki.flag-icon-squared {
  background-image: url(../images/flags/1x1/ki.svg); }

.flag-icon-km {
  background-image: url(../images/flags/4x3/km.svg); }

.flag-icon-km.flag-icon-squared {
  background-image: url(../images/flags/1x1/km.svg); }

.flag-icon-kn {
  background-image: url(../images/flags/4x3/kn.svg); }

.flag-icon-kn.flag-icon-squared {
  background-image: url(../images/flags/1x1/kn.svg); }

.flag-icon-kp {
  background-image: url(../images/flags/4x3/kp.svg); }

.flag-icon-kp.flag-icon-squared {
  background-image: url(../images/flags/1x1/kp.svg); }

.flag-icon-kr {
  background-image: url(../images/flags/4x3/kr.svg); }

.flag-icon-kr.flag-icon-squared {
  background-image: url(../images/flags/1x1/kr.svg); }

.flag-icon-kw {
  background-image: url(../images/flags/4x3/kw.svg); }

.flag-icon-kw.flag-icon-squared {
  background-image: url(../images/flags/1x1/kw.svg); }

.flag-icon-ky {
  background-image: url(../images/flags/4x3/ky.svg); }

.flag-icon-ky.flag-icon-squared {
  background-image: url(../images/flags/1x1/ky.svg); }

.flag-icon-kz {
  background-image: url(../images/flags/4x3/kz.svg); }

.flag-icon-kz.flag-icon-squared {
  background-image: url(../images/flags/1x1/kz.svg); }

.flag-icon-la {
  background-image: url(../images/flags/4x3/la.svg); }

.flag-icon-la.flag-icon-squared {
  background-image: url(../images/flags/1x1/la.svg); }

.flag-icon-lb {
  background-image: url(../images/flags/4x3/lb.svg); }

.flag-icon-lb.flag-icon-squared {
  background-image: url(../images/flags/1x1/lb.svg); }

.flag-icon-lc {
  background-image: url(../images/flags/4x3/lc.svg); }

.flag-icon-lc.flag-icon-squared {
  background-image: url(../images/flags/1x1/lc.svg); }

.flag-icon-li {
  background-image: url(../images/flags/4x3/li.svg); }

.flag-icon-li.flag-icon-squared {
  background-image: url(../images/flags/1x1/li.svg); }

.flag-icon-lk {
  background-image: url(../images/flags/4x3/lk.svg); }

.flag-icon-lk.flag-icon-squared {
  background-image: url(../images/flags/1x1/lk.svg); }

.flag-icon-lr {
  background-image: url(../images/flags/4x3/lr.svg); }

.flag-icon-lr.flag-icon-squared {
  background-image: url(../images/flags/1x1/lr.svg); }

.flag-icon-ls {
  background-image: url(../images/flags/4x3/ls.svg); }

.flag-icon-ls.flag-icon-squared {
  background-image: url(../images/flags/1x1/ls.svg); }

.flag-icon-lt {
  background-image: url(../images/flags/4x3/lt.svg); }

.flag-icon-lt.flag-icon-squared {
  background-image: url(../images/flags/1x1/lt.svg); }

.flag-icon-lu {
  background-image: url(../images/flags/4x3/lu.svg); }

.flag-icon-lu.flag-icon-squared {
  background-image: url(../images/flags/1x1/lu.svg); }

.flag-icon-lv {
  background-image: url(../images/flags/4x3/lv.svg); }

.flag-icon-lv.flag-icon-squared {
  background-image: url(../images/flags/1x1/lv.svg); }

.flag-icon-ly {
  background-image: url(../images/flags/4x3/ly.svg); }

.flag-icon-ly.flag-icon-squared {
  background-image: url(../images/flags/1x1/ly.svg); }

.flag-icon-ma {
  background-image: url(../images/flags/4x3/ma.svg); }

.flag-icon-ma.flag-icon-squared {
  background-image: url(../images/flags/1x1/ma.svg); }

.flag-icon-mc {
  background-image: url(../images/flags/4x3/mc.svg); }

.flag-icon-mc.flag-icon-squared {
  background-image: url(../images/flags/1x1/mc.svg); }

.flag-icon-md {
  background-image: url(../images/flags/4x3/md.svg); }

.flag-icon-md.flag-icon-squared {
  background-image: url(../images/flags/1x1/md.svg); }

.flag-icon-me {
  background-image: url(../images/flags/4x3/me.svg); }

.flag-icon-me.flag-icon-squared {
  background-image: url(../images/flags/1x1/me.svg); }

.flag-icon-mf {
  background-image: url(../images/flags/4x3/mf.svg); }

.flag-icon-mf.flag-icon-squared {
  background-image: url(../images/flags/1x1/mf.svg); }

.flag-icon-mg {
  background-image: url(../images/flags/4x3/mg.svg); }

.flag-icon-mg.flag-icon-squared {
  background-image: url(../images/flags/1x1/mg.svg); }

.flag-icon-mh {
  background-image: url(../images/flags/4x3/mh.svg); }

.flag-icon-mh.flag-icon-squared {
  background-image: url(../images/flags/1x1/mh.svg); }

.flag-icon-mk {
  background-image: url(../images/flags/4x3/mk.svg); }

.flag-icon-mk.flag-icon-squared {
  background-image: url(../images/flags/1x1/mk.svg); }

.flag-icon-ml {
  background-image: url(../images/flags/4x3/ml.svg); }

.flag-icon-ml.flag-icon-squared {
  background-image: url(../images/flags/1x1/ml.svg); }

.flag-icon-mm {
  background-image: url(../images/flags/4x3/mm.svg); }

.flag-icon-mm.flag-icon-squared {
  background-image: url(../images/flags/1x1/mm.svg); }

.flag-icon-mn {
  background-image: url(../images/flags/4x3/mn.svg); }

.flag-icon-mn.flag-icon-squared {
  background-image: url(../images/flags/1x1/mn.svg); }

.flag-icon-mo {
  background-image: url(../images/flags/4x3/mo.svg); }

.flag-icon-mo.flag-icon-squared {
  background-image: url(../images/flags/1x1/mo.svg); }

.flag-icon-mp {
  background-image: url(../images/flags/4x3/mp.svg); }

.flag-icon-mp.flag-icon-squared {
  background-image: url(../images/flags/1x1/mp.svg); }

.flag-icon-mq {
  background-image: url(../images/flags/4x3/mq.svg); }

.flag-icon-mq.flag-icon-squared {
  background-image: url(../images/flags/1x1/mq.svg); }

.flag-icon-mr {
  background-image: url(../images/flags/4x3/mr.svg); }

.flag-icon-mr.flag-icon-squared {
  background-image: url(../images/flags/1x1/mr.svg); }

.flag-icon-ms {
  background-image: url(../images/flags/4x3/ms.svg); }

.flag-icon-ms.flag-icon-squared {
  background-image: url(../images/flags/1x1/ms.svg); }

.flag-icon-mt {
  background-image: url(../images/flags/4x3/mt.svg); }

.flag-icon-mt.flag-icon-squared {
  background-image: url(../images/flags/1x1/mt.svg); }

.flag-icon-mu {
  background-image: url(../images/flags/4x3/mu.svg); }

.flag-icon-mu.flag-icon-squared {
  background-image: url(../images/flags/1x1/mu.svg); }

.flag-icon-mv {
  background-image: url(../images/flags/4x3/mv.svg); }

.flag-icon-mv.flag-icon-squared {
  background-image: url(../images/flags/1x1/mv.svg); }

.flag-icon-mw {
  background-image: url(../images/flags/4x3/mw.svg); }

.flag-icon-mw.flag-icon-squared {
  background-image: url(../images/flags/1x1/mw.svg); }

.flag-icon-mx {
  background-image: url(../images/flags/4x3/mx.svg); }

.flag-icon-mx.flag-icon-squared {
  background-image: url(../images/flags/1x1/mx.svg); }

.flag-icon-my {
  background-image: url(../images/flags/4x3/my.svg); }

.flag-icon-my.flag-icon-squared {
  background-image: url(../images/flags/1x1/my.svg); }

.flag-icon-mz {
  background-image: url(../images/flags/4x3/mz.svg); }

.flag-icon-mz.flag-icon-squared {
  background-image: url(../images/flags/1x1/mz.svg); }

.flag-icon-na {
  background-image: url(../images/flags/4x3/na.svg); }

.flag-icon-na.flag-icon-squared {
  background-image: url(../images/flags/1x1/na.svg); }

.flag-icon-nc {
  background-image: url(../images/flags/4x3/nc.svg); }

.flag-icon-nc.flag-icon-squared {
  background-image: url(../images/flags/1x1/nc.svg); }

.flag-icon-ne {
  background-image: url(../images/flags/4x3/ne.svg); }

.flag-icon-ne.flag-icon-squared {
  background-image: url(../images/flags/1x1/ne.svg); }

.flag-icon-nf {
  background-image: url(../images/flags/4x3/nf.svg); }

.flag-icon-nf.flag-icon-squared {
  background-image: url(../images/flags/1x1/nf.svg); }

.flag-icon-ng {
  background-image: url(../images/flags/4x3/ng.svg); }

.flag-icon-ng.flag-icon-squared {
  background-image: url(../images/flags/1x1/ng.svg); }

.flag-icon-ni {
  background-image: url(../images/flags/4x3/ni.svg); }

.flag-icon-ni.flag-icon-squared {
  background-image: url(../images/flags/1x1/ni.svg); }

.flag-icon-nl {
  background-image: url(../images/flags/4x3/nl.svg); }

.flag-icon-nl.flag-icon-squared {
  background-image: url(../images/flags/1x1/nl.svg); }

.flag-icon-no {
  background-image: url(../images/flags/4x3/no.svg); }

.flag-icon-no.flag-icon-squared {
  background-image: url(../images/flags/1x1/no.svg); }

.flag-icon-np {
  background-image: url(../images/flags/4x3/np.svg); }

.flag-icon-np.flag-icon-squared {
  background-image: url(../images/flags/1x1/np.svg); }

.flag-icon-nr {
  background-image: url(../images/flags/4x3/nr.svg); }

.flag-icon-nr.flag-icon-squared {
  background-image: url(../images/flags/1x1/nr.svg); }

.flag-icon-nu {
  background-image: url(../images/flags/4x3/nu.svg); }

.flag-icon-nu.flag-icon-squared {
  background-image: url(../images/flags/1x1/nu.svg); }

.flag-icon-nz {
  background-image: url(../images/flags/4x3/nz.svg); }

.flag-icon-nz.flag-icon-squared {
  background-image: url(../images/flags/1x1/nz.svg); }

.flag-icon-om {
  background-image: url(../images/flags/4x3/om.svg); }

.flag-icon-om.flag-icon-squared {
  background-image: url(../images/flags/1x1/om.svg); }

.flag-icon-pa {
  background-image: url(../images/flags/4x3/pa.svg); }

.flag-icon-pa.flag-icon-squared {
  background-image: url(../images/flags/1x1/pa.svg); }

.flag-icon-pe {
  background-image: url(../images/flags/4x3/pe.svg); }

.flag-icon-pe.flag-icon-squared {
  background-image: url(../images/flags/1x1/pe.svg); }

.flag-icon-pf {
  background-image: url(../images/flags/4x3/pf.svg); }

.flag-icon-pf.flag-icon-squared {
  background-image: url(../images/flags/1x1/pf.svg); }

.flag-icon-pg {
  background-image: url(../images/flags/4x3/pg.svg); }

.flag-icon-pg.flag-icon-squared {
  background-image: url(../images/flags/1x1/pg.svg); }

.flag-icon-ph {
  background-image: url(../images/flags/4x3/ph.svg); }

.flag-icon-ph.flag-icon-squared {
  background-image: url(../images/flags/1x1/ph.svg); }

.flag-icon-pk {
  background-image: url(../images/flags/4x3/pk.svg); }

.flag-icon-pk.flag-icon-squared {
  background-image: url(../images/flags/1x1/pk.svg); }

.flag-icon-pl {
  background-image: url(../images/flags/4x3/pl.svg); }

.flag-icon-pl.flag-icon-squared {
  background-image: url(../images/flags/1x1/pl.svg); }

.flag-icon-pm {
  background-image: url(../images/flags/4x3/pm.svg); }

.flag-icon-pm.flag-icon-squared {
  background-image: url(../images/flags/1x1/pm.svg); }

.flag-icon-pn {
  background-image: url(../images/flags/4x3/pn.svg); }

.flag-icon-pn.flag-icon-squared {
  background-image: url(../images/flags/1x1/pn.svg); }

.flag-icon-pr {
  background-image: url(../images/flags/4x3/pr.svg); }

.flag-icon-pr.flag-icon-squared {
  background-image: url(../images/flags/1x1/pr.svg); }

.flag-icon-ps {
  background-image: url(../images/flags/4x3/ps.svg); }

.flag-icon-ps.flag-icon-squared {
  background-image: url(../images/flags/1x1/ps.svg); }

.flag-icon-pt {
  background-image: url(../images/flags/4x3/pt.svg); }

.flag-icon-pt.flag-icon-squared {
  background-image: url(../images/flags/1x1/pt.svg); }

.flag-icon-pw {
  background-image: url(../images/flags/4x3/pw.svg); }

.flag-icon-pw.flag-icon-squared {
  background-image: url(../images/flags/1x1/pw.svg); }

.flag-icon-py {
  background-image: url(../images/flags/4x3/py.svg); }

.flag-icon-py.flag-icon-squared {
  background-image: url(../images/flags/1x1/py.svg); }

.flag-icon-qa {
  background-image: url(../images/flags/4x3/qa.svg); }

.flag-icon-qa.flag-icon-squared {
  background-image: url(../images/flags/1x1/qa.svg); }

.flag-icon-re {
  background-image: url(../images/flags/4x3/re.svg); }

.flag-icon-re.flag-icon-squared {
  background-image: url(../images/flags/1x1/re.svg); }

.flag-icon-ro {
  background-image: url(../images/flags/4x3/ro.svg); }

.flag-icon-ro.flag-icon-squared {
  background-image: url(../images/flags/1x1/ro.svg); }

.flag-icon-rs {
  background-image: url(../images/flags/4x3/rs.svg); }

.flag-icon-rs.flag-icon-squared {
  background-image: url(../images/flags/1x1/rs.svg); }

.flag-icon-ru {
  background-image: url(../images/flags/4x3/ru.svg); }

.flag-icon-ru.flag-icon-squared {
  background-image: url(../images/flags/1x1/ru.svg); }

.flag-icon-rw {
  background-image: url(../images/flags/4x3/rw.svg); }

.flag-icon-rw.flag-icon-squared {
  background-image: url(../images/flags/1x1/rw.svg); }

.flag-icon-sa {
  background-image: url(../images/flags/4x3/sa.svg); }

.flag-icon-sa.flag-icon-squared {
  background-image: url(../images/flags/1x1/sa.svg); }

.flag-icon-sb {
  background-image: url(../images/flags/4x3/sb.svg); }

.flag-icon-sb.flag-icon-squared {
  background-image: url(../images/flags/1x1/sb.svg); }

.flag-icon-sc {
  background-image: url(../images/flags/4x3/sc.svg); }

.flag-icon-sc.flag-icon-squared {
  background-image: url(../images/flags/1x1/sc.svg); }

.flag-icon-sd {
  background-image: url(../images/flags/4x3/sd.svg); }

.flag-icon-sd.flag-icon-squared {
  background-image: url(../images/flags/1x1/sd.svg); }

.flag-icon-se {
  background-image: url(../images/flags/4x3/se.svg); }

.flag-icon-se.flag-icon-squared {
  background-image: url(../images/flags/1x1/se.svg); }

.flag-icon-sg {
  background-image: url(../images/flags/4x3/sg.svg); }

.flag-icon-sg.flag-icon-squared {
  background-image: url(../images/flags/1x1/sg.svg); }

.flag-icon-sh {
  background-image: url(../images/flags/4x3/sh.svg); }

.flag-icon-sh.flag-icon-squared {
  background-image: url(../images/flags/1x1/sh.svg); }

.flag-icon-si {
  background-image: url(../images/flags/4x3/si.svg); }

.flag-icon-si.flag-icon-squared {
  background-image: url(../images/flags/1x1/si.svg); }

.flag-icon-sj {
  background-image: url(../images/flags/4x3/sj.svg); }

.flag-icon-sj.flag-icon-squared {
  background-image: url(../images/flags/1x1/sj.svg); }

.flag-icon-sk {
  background-image: url(../images/flags/4x3/sk.svg); }

.flag-icon-sk.flag-icon-squared {
  background-image: url(../images/flags/1x1/sk.svg); }

.flag-icon-sl {
  background-image: url(../images/flags/4x3/sl.svg); }

.flag-icon-sl.flag-icon-squared {
  background-image: url(../images/flags/1x1/sl.svg); }

.flag-icon-sm {
  background-image: url(../images/flags/4x3/sm.svg); }

.flag-icon-sm.flag-icon-squared {
  background-image: url(../images/flags/1x1/sm.svg); }

.flag-icon-sn {
  background-image: url(../images/flags/4x3/sn.svg); }

.flag-icon-sn.flag-icon-squared {
  background-image: url(../images/flags/1x1/sn.svg); }

.flag-icon-so {
  background-image: url(../images/flags/4x3/so.svg); }

.flag-icon-so.flag-icon-squared {
  background-image: url(../images/flags/1x1/so.svg); }

.flag-icon-sr {
  background-image: url(../images/flags/4x3/sr.svg); }

.flag-icon-sr.flag-icon-squared {
  background-image: url(../images/flags/1x1/sr.svg); }

.flag-icon-ss {
  background-image: url(../images/flags/4x3/ss.svg); }

.flag-icon-ss.flag-icon-squared {
  background-image: url(../images/flags/1x1/ss.svg); }

.flag-icon-st {
  background-image: url(../images/flags/4x3/st.svg); }

.flag-icon-st.flag-icon-squared {
  background-image: url(../images/flags/1x1/st.svg); }

.flag-icon-sv {
  background-image: url(../images/flags/4x3/sv.svg); }

.flag-icon-sv.flag-icon-squared {
  background-image: url(../images/flags/1x1/sv.svg); }

.flag-icon-sx {
  background-image: url(../images/flags/4x3/sx.svg); }

.flag-icon-sx.flag-icon-squared {
  background-image: url(../images/flags/1x1/sx.svg); }

.flag-icon-sy {
  background-image: url(../images/flags/4x3/sy.svg); }

.flag-icon-sy.flag-icon-squared {
  background-image: url(../images/flags/1x1/sy.svg); }

.flag-icon-sz {
  background-image: url(../images/flags/4x3/sz.svg); }

.flag-icon-sz.flag-icon-squared {
  background-image: url(../images/flags/1x1/sz.svg); }

.flag-icon-tc {
  background-image: url(../images/flags/4x3/tc.svg); }

.flag-icon-tc.flag-icon-squared {
  background-image: url(../images/flags/1x1/tc.svg); }

.flag-icon-td {
  background-image: url(../images/flags/4x3/td.svg); }

.flag-icon-td.flag-icon-squared {
  background-image: url(../images/flags/1x1/td.svg); }

.flag-icon-tf {
  background-image: url(../images/flags/4x3/tf.svg); }

.flag-icon-tf.flag-icon-squared {
  background-image: url(../images/flags/1x1/tf.svg); }

.flag-icon-tg {
  background-image: url(../images/flags/4x3/tg.svg); }

.flag-icon-tg.flag-icon-squared {
  background-image: url(../images/flags/1x1/tg.svg); }

.flag-icon-th {
  background-image: url(../images/flags/4x3/th.svg); }

.flag-icon-th.flag-icon-squared {
  background-image: url(../images/flags/1x1/th.svg); }

.flag-icon-tj {
  background-image: url(../images/flags/4x3/tj.svg); }

.flag-icon-tj.flag-icon-squared {
  background-image: url(../images/flags/1x1/tj.svg); }

.flag-icon-tk {
  background-image: url(../images/flags/4x3/tk.svg); }

.flag-icon-tk.flag-icon-squared {
  background-image: url(../images/flags/1x1/tk.svg); }

.flag-icon-tl {
  background-image: url(../images/flags/4x3/tl.svg); }

.flag-icon-tl.flag-icon-squared {
  background-image: url(../images/flags/1x1/tl.svg); }

.flag-icon-tm {
  background-image: url(../images/flags/4x3/tm.svg); }

.flag-icon-tm.flag-icon-squared {
  background-image: url(../images/flags/1x1/tm.svg); }

.flag-icon-tn {
  background-image: url(../images/flags/4x3/tn.svg); }

.flag-icon-tn.flag-icon-squared {
  background-image: url(../images/flags/1x1/tn.svg); }

.flag-icon-to {
  background-image: url(../images/flags/4x3/to.svg); }

.flag-icon-to.flag-icon-squared {
  background-image: url(../images/flags/1x1/to.svg); }

.flag-icon-tr {
  background-image: url(../images/flags/4x3/tr.svg); }

.flag-icon-tr.flag-icon-squared {
  background-image: url(../images/flags/1x1/tr.svg); }

.flag-icon-tt {
  background-image: url(../images/flags/4x3/tt.svg); }

.flag-icon-tt.flag-icon-squared {
  background-image: url(../images/flags/1x1/tt.svg); }

.flag-icon-tv {
  background-image: url(../images/flags/4x3/tv.svg); }

.flag-icon-tv.flag-icon-squared {
  background-image: url(../images/flags/1x1/tv.svg); }

.flag-icon-tw {
  background-image: url(../images/flags/4x3/tw.svg); }

.flag-icon-tw.flag-icon-squared {
  background-image: url(../images/flags/1x1/tw.svg); }

.flag-icon-tz {
  background-image: url(../images/flags/4x3/tz.svg); }

.flag-icon-tz.flag-icon-squared {
  background-image: url(../images/flags/1x1/tz.svg); }

.flag-icon-ua {
  background-image: url(../images/flags/4x3/ua.svg); }

.flag-icon-ua.flag-icon-squared {
  background-image: url(../images/flags/1x1/ua.svg); }

.flag-icon-ug {
  background-image: url(../images/flags/4x3/ug.svg); }

.flag-icon-ug.flag-icon-squared {
  background-image: url(../images/flags/1x1/ug.svg); }

.flag-icon-um {
  background-image: url(../images/flags/4x3/um.svg); }

.flag-icon-um.flag-icon-squared {
  background-image: url(../images/flags/1x1/um.svg); }

.flag-icon-us {
  background-image: url(../images/flags/4x3/us.svg); }

.flag-icon-us.flag-icon-squared {
  background-image: url(../images/flags/1x1/us.svg); }

.flag-icon-uy {
  background-image: url(../images/flags/4x3/uy.svg); }

.flag-icon-uy.flag-icon-squared {
  background-image: url(../images/flags/1x1/uy.svg); }

.flag-icon-uz {
  background-image: url(../images/flags/4x3/uz.svg); }

.flag-icon-uz.flag-icon-squared {
  background-image: url(../images/flags/1x1/uz.svg); }

.flag-icon-va {
  background-image: url(../images/flags/4x3/va.svg); }

.flag-icon-va.flag-icon-squared {
  background-image: url(../images/flags/1x1/va.svg); }

.flag-icon-vc {
  background-image: url(../images/flags/4x3/vc.svg); }

.flag-icon-vc.flag-icon-squared {
  background-image: url(../images/flags/1x1/vc.svg); }

.flag-icon-ve {
  background-image: url(../images/flags/4x3/ve.svg); }

.flag-icon-ve.flag-icon-squared {
  background-image: url(../images/flags/1x1/ve.svg); }

.flag-icon-vg {
  background-image: url(../images/flags/4x3/vg.svg); }

.flag-icon-vg.flag-icon-squared {
  background-image: url(../images/flags/1x1/vg.svg); }

.flag-icon-vi {
  background-image: url(../images/flags/4x3/vi.svg); }

.flag-icon-vi.flag-icon-squared {
  background-image: url(../images/flags/1x1/vi.svg); }

.flag-icon-vn {
  background-image: url(../images/flags/4x3/vn.svg); }

.flag-icon-vn.flag-icon-squared {
  background-image: url(../images/flags/1x1/vn.svg); }

.flag-icon-vu {
  background-image: url(../images/flags/4x3/vu.svg); }

.flag-icon-vu.flag-icon-squared {
  background-image: url(../images/flags/1x1/vu.svg); }

.flag-icon-wf {
  background-image: url(../images/flags/4x3/wf.svg); }

.flag-icon-wf.flag-icon-squared {
  background-image: url(../images/flags/1x1/wf.svg); }

.flag-icon-ws {
  background-image: url(../images/flags/4x3/ws.svg); }

.flag-icon-ws.flag-icon-squared {
  background-image: url(../images/flags/1x1/ws.svg); }

.flag-icon-ye {
  background-image: url(../images/flags/4x3/ye.svg); }

.flag-icon-ye.flag-icon-squared {
  background-image: url(../images/flags/1x1/ye.svg); }

.flag-icon-yt {
  background-image: url(../images/flags/4x3/yt.svg); }

.flag-icon-yt.flag-icon-squared {
  background-image: url(../images/flags/1x1/yt.svg); }

.flag-icon-za {
  background-image: url(../images/flags/4x3/za.svg); }

.flag-icon-za.flag-icon-squared {
  background-image: url(../images/flags/1x1/za.svg); }

.flag-icon-zm {
  background-image: url(../images/flags/4x3/zm.svg); }

.flag-icon-zm.flag-icon-squared {
  background-image: url(../images/flags/1x1/zm.svg); }

.flag-icon-zw {
  background-image: url(../images/flags/4x3/zw.svg); }

.flag-icon-zw.flag-icon-squared {
  background-image: url(../images/flags/1x1/zw.svg); }

.flag-icon-es-ct {
  background-image: url(../images/flags/4x3/es-ct.svg); }

.flag-icon-es-ct.flag-icon-squared {
  background-image: url(../images/flags/1x1/es-ct.svg); }

.flag-icon-eu {
  background-image: url(../images/flags/4x3/eu.svg); }

.flag-icon-eu.flag-icon-squared {
  background-image: url(../images/flags/1x1/eu.svg); }

.flag-icon-gb-eng {
  background-image: url(../images/flags/4x3/gb-eng.svg); }

.flag-icon-gb-eng.flag-icon-squared {
  background-image: url(../images/flags/1x1/gb-eng.svg); }

.flag-icon-gb-nir {
  background-image: url(../images/flags/4x3/gb-nir.svg); }

.flag-icon-gb-nir.flag-icon-squared {
  background-image: url(../images/flags/1x1/gb-nir.svg); }

.flag-icon-gb-sct {
  background-image: url(../images/flags/4x3/gb-sct.svg); }

.flag-icon-gb-sct.flag-icon-squared {
  background-image: url(../images/flags/1x1/gb-sct.svg); }

.flag-icon-gb-wls {
  background-image: url(../images/flags/4x3/gb-wls.svg); }

.flag-icon-gb-wls.flag-icon-squared {
  background-image: url(../images/flags/1x1/gb-wls.svg); }

.flag-icon-un {
  background-image: url(../images/flags/4x3/un.svg); }

.flag-icon-un.flag-icon-squared {
  background-image: url(../images/flags/1x1/un.svg); }

.flag-icon-xk {
  background-image: url(../images/flags/4x3/xk.svg); }

.flag-icon-xk.flag-icon-squared {
  background-image: url(../images/flags/1x1/xk.svg); }

/* fallback */
/* fallback */
@font-face {
  font-family: 'Roboto-Bold';
  src: url("../fonts/Roboto-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

/* fallback */
@font-face {
  font-family: 'Roboto-Regular';
  src: url("../fonts/Roboto-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

/* fallback */
@font-face {
  font-family: 'Roboto Condensed Bold';
  src: url("../fonts/RobotoCondensed-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

/* fallback */
@font-face {
  font-family: 'Roboto Condensed Regular';
  src: url("../fonts/RobotoCondensed-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Roboto Condensed italic';
  src: url("../fonts/RobotoCondensed-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

/* fallback */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: local("Material Icons"), local("MaterialIcons-Regular"), url(https://fonts.gstatic.com/s/materialicons/v22/2fcrYFNaTjcS6g4U3t-Y5ZjZjT5FdEJ140U2DJYC3mY.woff2) format("woff2"); }

/* fallback */
@font-face {
  font-family: 'Material Icons Outlined';
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/MaterialIcons-Regular.eot");
  src: local("Material Icons"), local("MaterialIcons-Regular"), url("../fonts/ico-material/gok-H7zzDkdnRel8-DQ6KAXJ69wP1tGnf4ZGhUcel5euIg.woff2") format("woff2"); }

@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon.eot?h7od0q");
  src: url("../fonts/icomoon.eot?h7od0q#iefix") format("embedded-opentype"), url("../fonts/icomoon.ttf?h7od0q") format("truetype"), url("../fonts/icomoon.woff?h7od0q") format("woff"), url("../fonts/icomoon.svg?h7od0q#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .fontx--default .material-icons {
    font-size: 24px; }
  .fontx--x2 .material-icons {
    font-size: 26px; }
  .material-icons.outlined {
    font-family: 'Material Icons Outlined' !important; }
  .material-icons.deg180 {
    transform: rotate(180deg); }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  font-size: 24px;
  speak-as: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  .fontx--default [class^="icon-"], .fontx--default
  [class*=" icon-"] {
    font-size: 24px; }
  .fontx--x2 [class^="icon-"], .fontx--x2
  [class*=" icon-"] {
    font-size: 26px; }

.icon-agronomy:before {
  content: "\e900"; }

.icon-astronauta-ingravido:before {
  content: "\e901"; }

.icon-book:before {
  content: "\e902"; }

.icon-books .path1:before {
  content: "\e903";
  color: white; }

.icon-books .path2:before {
  content: "\e904";
  margin-left: -1em;
  color: #02247d; }

.icon-books .path3:before {
  content: "\e905";
  margin-left: -1em;
  color: black; }

.icon-books .path4:before {
  content: "\e906";
  margin-left: -1em;
  color: white; }

.icon-books .path5:before {
  content: "\e907";
  margin-left: -1em;
  color: #02247d; }

.icon-books .path6:before {
  content: "\e908";
  margin-left: -1em;
  color: black; }

.icon-books .path7:before {
  content: "\e909";
  margin-left: -1em;
  color: #02247d; }

.icon-books .path8:before {
  content: "\e90a";
  margin-left: -1em;
  color: black; }

.icon-books .path9:before {
  content: "\e90b";
  margin-left: -1em;
  color: #02247d; }

.icon-books .path10:before {
  content: "\e90c";
  margin-left: -1em;
  color: black; }

.icon-cap:before {
  content: "\e90d"; }

.icon-clasificacion:before {
  content: "\e90e"; }

.icon-compass:before {
  content: "\e90f"; }

.icon-conversation:before {
  content: "\e910"; }

.icon-cursor:before {
  content: "\e911"; }

.icon-facebook-logo:before {
  content: "\e912"; }

.icon-gear:before {
  content: "\e913"; }

.icon-graduation-hat:before {
  content: "\e914"; }

.icon-heart:before {
  content: "\e915"; }

.icon-heartbeat:before {
  content: "\e916"; }

.icon-help:before {
  content: "\e917"; }

.icon-instagram:before {
  content: "\e918"; }

.icon-lab-microscope:before {
  content: "\e919"; }

.icon-laurel:before {
  content: "\e91a"; }

.icon-lifesaver:before {
  content: "\e91b"; }

.icon-linkedin-logo:before {
  content: "\e91c"; }

.icon-lotus:before {
  content: "\e91d"; }

.icon-mortarboard:before {
  content: "\e91e"; }

.icon-office:before {
  content: "\e91f"; }

.icon-office365:before {
  content: "\e920"; }

.icon-paint-palette:before {
  content: "\e921"; }

.icon-shooting-star:before {
  content: "\e922"; }

.icon-social-care:before {
  content: "\e923"; }

.icon-solar-energy:before {
  content: "\e924"; }

.icon-star:before {
  content: "\e925"; }

.icon-starfish:before {
  content: "\e926"; }

.icon-student .path1:before {
  content: "\e927";
  color: black; }

.icon-student .path2:before {
  content: "\e928";
  margin-left: -1em;
  color: #02247d; }

.icon-student .path3:before {
  content: "\e929";
  margin-left: -1em;
  color: black; }

.icon-student .path4:before {
  content: "\e92a";
  margin-left: -1em;
  color: #02247d; }

.icon-student .path5:before {
  content: "\e92b";
  margin-left: -1em;
  color: black; }

.icon-student .path6:before {
  content: "\e92c";
  margin-left: -1em;
  color: #02247d; }

.icon-student .path7:before {
  content: "\e92d";
  margin-left: -1em;
  color: black; }

.icon-student .path8:before {
  content: "\e92e";
  margin-left: -1em;
  color: #02247d; }

.icon-student .path9:before {
  content: "\e92f";
  margin-left: -1em;
  color: black; }

.icon-student .path10:before {
  content: "\e930";
  margin-left: -1em;
  color: #02247d; }

.icon-student .path11:before {
  content: "\e931";
  margin-left: -1em;
  color: black; }

.icon-student .path12:before {
  content: "\e932";
  margin-left: -1em;
  color: #02247d; }

.icon-student .path13:before {
  content: "\e933";
  margin-left: -1em;
  color: black; }

.icon-student .path14:before {
  content: "\e934";
  margin-left: -1em;
  color: #02247d; }

.icon-student .path15:before {
  content: "\e935";
  margin-left: -1em;
  color: black; }

.icon-student .path16:before {
  content: "\e936";
  margin-left: -1em;
  color: black; }

.icon-student .path17:before {
  content: "\e937";
  margin-left: -1em;
  color: #fffeff; }

.icon-student .path18:before {
  content: "\e938";
  margin-left: -1em;
  color: black; }

.icon-student .path19:before {
  content: "\e939";
  margin-left: -1em;
  color: black; }

.icon-student .path20:before {
  content: "\e93a";
  margin-left: -1em;
  color: black; }

.icon-student .path21:before {
  content: "\e93b";
  margin-left: -1em;
  color: black; }

.icon-student .path22:before {
  content: "\e93c";
  margin-left: -1em;
  color: black; }

.icon-student .path23:before {
  content: "\e93d";
  margin-left: -1em;
  color: #02247d; }

.icon-student .path24:before {
  content: "\e93e";
  margin-left: -1em;
  color: black; }

.icon-student .path25:before {
  content: "\e93f";
  margin-left: -1em;
  color: white; }

.icon-student .path26:before {
  content: "\e940";
  margin-left: -1em;
  color: #02247d; }

.icon-student .path27:before {
  content: "\e941";
  margin-left: -1em;
  color: black; }

.icon-student .path28:before {
  content: "\e942";
  margin-left: -1em;
  color: black; }

.icon-student .path29:before {
  content: "\e943";
  margin-left: -1em;
  color: #02247d; }

.icon-student .path30:before {
  content: "\e944";
  margin-left: -1em;
  color: black; }

.icon-student .path31:before {
  content: "\e945";
  margin-left: -1em;
  color: black; }

.icon-student .path32:before {
  content: "\e946";
  margin-left: -1em;
  color: black; }

.icon-student .path33:before {
  content: "\e947";
  margin-left: -1em;
  color: #02247d; }

.icon-student .path34:before {
  content: "\e948";
  margin-left: -1em;
  color: black; }

.icon-student .path35:before {
  content: "\e949";
  margin-left: -1em;
  color: black; }

.icon-student .path36:before {
  content: "\e94a";
  margin-left: -1em;
  color: black; }

.icon-student .path37:before {
  content: "\e94b";
  margin-left: -1em;
  color: black; }

.icon-student .path38:before {
  content: "\e94c";
  margin-left: -1em;
  color: white; }

.icon-student .path39:before {
  content: "\e94d";
  margin-left: -1em;
  color: #02247d; }

.icon-student .path40:before {
  content: "\e94e";
  margin-left: -1em;
  color: black; }

.icon-student .path41:before {
  content: "\e94f";
  margin-left: -1em;
  color: black; }

.icon-student .path42:before {
  content: "\e950";
  margin-left: -1em;
  color: black; }

.icon-student .path43:before {
  content: "\e951";
  margin-left: -1em;
  color: #02247d; }

.icon-student .path44:before {
  content: "\e952";
  margin-left: -1em;
  color: black; }

.icon-teamwork:before {
  content: "\e953"; }

.icon-training:before {
  content: "\e954"; }

.icon-trophy:before {
  content: "\e955"; }

.icon-twitter-logo-silhouette:before {
  content: "\e956"; }

.icon-university:before {
  content: "\e957"; }

.icon-university-building:before {
  content: "\e958"; }

.icon-veterinary:before {
  content: "\e959"; }

.icon-whatsapp-logo:before {
  content: "\e95a"; }

.icon-winner .path1:before {
  content: "\e95b";
  color: #ff901d; }

.icon-winner .path2:before {
  content: "\e95c";
  margin-left: -1em;
  color: #ff901d; }

.icon-winner .path3:before {
  content: "\e95d";
  margin-left: -1em;
  color: #ff901d; }

.icon-winner .path4:before {
  content: "\e95e";
  margin-left: -1em;
  color: #ff901d; }

.icon-winner .path5:before {
  content: "\e95f";
  margin-left: -1em;
  color: #ff901d; }

.icon-winner .path6:before {
  content: "\e960";
  margin-left: -1em;
  color: #ff901d; }

.icon-winner .path7:before {
  content: "\e961";
  margin-left: -1em;
  color: #ff901d; }

.icon-winner .path8:before {
  content: "\e962";
  margin-left: -1em;
  color: #ff901d; }

.icon-winner .path9:before {
  content: "\e963";
  margin-left: -1em;
  color: #ff901d; }

.icon-winner .path10:before {
  content: "\e964";
  margin-left: -1em;
  color: #ff901d; }

.icon-winner .path11:before {
  content: "\e965";
  margin-left: -1em;
  color: #ff901d; }

.icon-winner .path12:before {
  content: "\e966";
  margin-left: -1em;
  color: #ff901d; }

.icon-winner .path13:before {
  content: "\e967";
  margin-left: -1em;
  color: #ff901d; }

.icon-winner .path14:before {
  content: "\e968";
  margin-left: -1em;
  color: #ff901d; }

.icon-winner .path15:before {
  content: "\e969";
  margin-left: -1em;
  color: #f7bc14; }

.icon-winner .path16:before {
  content: "\e96a";
  margin-left: -1em;
  color: #f7bc14; }

.icon-winner .path17:before {
  content: "\e96b";
  margin-left: -1em;
  color: #ffb125; }

.icon-winner .path18:before {
  content: "\e96c";
  margin-left: -1em;
  color: #ffb125; }

.icon-winner .path19:before {
  content: "\e96d";
  margin-left: -1em;
  color: #fed130; }

.icon-winner .path20:before {
  content: "\e96e";
  margin-left: -1em;
  color: #f7bc14; }

.icon-winner .path21:before {
  content: "\e96f";
  margin-left: -1em;
  color: #fed130; }

.icon-winner .path22:before {
  content: "\e970";
  margin-left: -1em;
  color: #f7bc14; }

.icon-winner .path23:before {
  content: "\e971";
  margin-left: -1em;
  color: #ffb125; }

.icon-winner .path24:before {
  content: "\e972";
  margin-left: -1em;
  color: #ff901d; }

.icon-winner .path25:before {
  content: "\e973";
  margin-left: -1em;
  color: #ffb125; }

.icon-winner .path26:before {
  content: "\e974";
  margin-left: -1em;
  color: #ff901d; }

.icon-youtube:before {
  content: "\e975"; }

.getmdl-select {
  outline: none; }
  .getmdl-select .mdl-textfield__input {
    cursor: pointer; }
  .getmdl-select .selected {
    background-color: #ddd; }
  .getmdl-select .mdl-icon-toggle__label {
    float: right;
    margin-top: -30px;
    color: rgba(0, 0, 0, 0.4);
    transform: rotate(0);
    transition: transform 0.3s; }
  .getmdl-select.is-focused .mdl-icon-toggle__label {
    color: #3f51b5;
    transform: rotate(180deg); }
  .getmdl-select .mdl-menu__container {
    width: 100% !important;
    margin-top: 2px; }
    .getmdl-select .mdl-menu__container .mdl-menu {
      width: 100%; }
      .getmdl-select .mdl-menu__container .mdl-menu .mdl-menu__item {
        font-size: 16px; }
        .fontx--default .getmdl-select .mdl-menu__container .mdl-menu .mdl-menu__item {
          font-size: 16px; }
        .fontx--x2 .getmdl-select .mdl-menu__container .mdl-menu .mdl-menu__item {
          font-size: 18px; }

.getmdl-select__fix-height .mdl-menu__container .mdl-menu {
  overflow-y: auto;
  max-height: 288px !important; }

.getmdl-select__fix-height .mdl-menu.mdl-menu--top-left {
  bottom: auto;
  top: 0; }

.theme--default .mdl-menu__container.is-visible .mdl-menu {
  background-color: #fff; }

.theme--dark .mdl-menu__container.is-visible .mdl-menu {
  background-color: #222222; }

.theme--default .mdl-menu__container.is-visible .mdl-menu .mdl-menu__item:active {
  background-color: #2b2b2b; }

.theme--dark .mdl-menu__container.is-visible .mdl-menu .mdl-menu__item:active {
  background-color: #2b2b2b; }

.theme--default .mdl-menu__container.is-visible .mdl-menu .mdl-menu__item:hover {
  background-color: #2b2b2b; }

.theme--dark .mdl-menu__container.is-visible .mdl-menu .mdl-menu__item:hover {
  background-color: #2b2b2b; }

/* Ripple effect */
.ripple-nojs {
  background-position: center !important;
  transition: background 0.8s !important; }
  .ripple-nojs:hover {
    background: #F2F2F2 radial-gradient(circle, transparent 1%, #F2F2F2 1%) center/15000% !important; }
  .ripple-nojs:active {
    background-color: #6eb9f7;
    background-size: 100%;
    transition: background 0s; }

#form_inscripcion_programas iframe body {
  overflow-x: hidden; }

.paginate_custom {
  display: flex;
  flex-direction: row;
  align-items: center; }
  .paginate_custom a.mdl-button.circle {
    display: flex;
    text-decoration: none;
    justify-content: center;
    align-items: center;
    border: 1px solid #33691e;
    border-radius: 50%;
    height: 61px;
    width: 61px;
    min-width: initial;
    padding: 0; }
    .paginate_custom a.mdl-button.circle:hover {
      background-color: transparent; }
    .paginate_custom a.mdl-button.circle span {
      color: #8bc34a; }
  .paginate_custom .action_page {
    padding-left: 20px; }
    .paginate_custom .action_page p.title_indi {
      font-family: 'Roboto-Bold';
      text-transform: uppercase;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 1.25px;
      margin-bottom: 5px;
      color: #33691e; }
      .theme--default .paginate_custom .action_page p.title_indi {
        color: #fff; }
      .theme--dark .paginate_custom .action_page p.title_indi {
        color: #fff; }
    .paginate_custom .action_page p.num_indi {
      font-family: 'Roboto-Regular';
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.25px;
      margin: 0; }
      .theme--default .paginate_custom .action_page p.num_indi {
        color: rgba(0, 0, 0, 0.6); }
      .theme--dark .paginate_custom .action_page p.num_indi {
        color: rgba(255, 255, 255, 0.6); }

.card_home {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  height: 480px;
  background-color: #ddd;
  transition: all 100ms ease-in-out; }
  .card_home.green {
    background-color: #8bc34a; }
  .card_home.yellow {
    background-color: #ffc107; }
  .card_home.blue {
    background-color: #2196f3; }
  .card_home.violet {
    background-color: #9c27b0; }
  .card_home:hover {
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.3);
    transform: scale(1.03);
    z-index: 1; }
  .card_home .card_tag {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px; }
    .card_home .card_tag p {
      font-family: 'Roboto-Regular';
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.4px;
      margin-bottom: 0;
      color: #fff; }
      .fontx--default .card_home .card_tag p {
        font-size: 15px; }
      .fontx--x2 .card_home .card_tag p {
        font-size: 15px; }
      .theme--default .card_home .card_tag p {
        color: #fff; }
      .theme--dark .card_home .card_tag p {
        color: #fff; }
    .card_home .card_tag span {
      color: #f1f8e9;
      padding-right: 3px; }
  .card_home .card_body {
    padding-bottom: 40px; }
    .card_home .card_body p {
      font-family: 'Roboto Condensed Regular';
      font-size: 36px;
      letter-spacing: 0.26px;
      line-height: 42px;
      color: #fff; }
      .theme--default .card_home .card_body p {
        color: #fff; }
      .theme--dark .card_home .card_body p {
        color: #fff; }
  .card_home .card_footer {
    display: flex;
    flex-direction: row; }
    .card_home .card_footer .colm_footer {
      width: 50%;
      flex-basis: 50%;
      display: flex;
      align-items: center; }
      .card_home .card_footer .colm_footer p.date_num {
        font-family: 'Roboto Condensed Italic';
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 2px;
        margin-bottom: 0; }
      .card_home .card_footer .colm_footer.right {
        justify-content: flex-end; }
        .card_home .card_footer .colm_footer.right a.mdl-button.custom,
        .card_home .card_footer .colm_footer.right button.mdl-button.custom {
          color: rgba(0, 0, 0, 0.54); }

.card_mdl {
  display: flex;
  flex-direction: row;
  background-color: #fff;
  border: 1px solid transparent; }
  .theme--default .card_mdl {
    background-color: #fff; }
  .theme--dark .card_mdl {
    background-color: #222222; }
  .card_mdl.green:hover {
    border: 1px solid #33691e;
    box-shadow: 0 8px 17px 2px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2); }
  .card_mdl.violet:hover {
    border: 1px solid #9c27b0;
    box-shadow: 0 8px 17px 2px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2); }
  .card_mdl.orange:hover {
    border: 1px solid #f44336;
    box-shadow: 0 8px 17px 2px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2); }
  .card_mdl.blue:hover {
    border: 1px solid #2196f3;
    box-shadow: 0 8px 17px 2px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2); }
  .card_mdl.yellow:hover {
    border: 1px solid #ffc107;
    box-shadow: 0 8px 17px 2px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2); }
  .card_mdl.red:hover {
    border: 1px solid #e91e63;
    box-shadow: 0 8px 17px 2px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2); }
  .card_mdl .card_mdl_img {
    flex-basis: 120px; }
    .card_mdl .card_mdl_img img {
      width: 120px;
      height: 120px;
      object-fit: cover;
      max-width: 100%; }
  .card_mdl .card_mdl_body {
    flex: 1 1 auto; }
    .card_mdl .card_mdl_body .copy_card_mdl {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      padding: 0 30px; }
      .card_mdl .card_mdl_body .copy_card_mdl p {
        font-size: 24px;
        font-family: 'Roboto Condensed Bold';
        letter-spacing: -0.2px; }
        @media only screen and (min-width: 1025px) {
          .card_mdl .card_mdl_body .copy_card_mdl p {
            width: 60%; } }
        .card_mdl .card_mdl_body .copy_card_mdl p.green {
          color: #33691e; }
        .card_mdl .card_mdl_body .copy_card_mdl p.violet {
          color: #9c27b0; }
        .card_mdl .card_mdl_body .copy_card_mdl p.orange {
          color: #f44336; }
        .card_mdl .card_mdl_body .copy_card_mdl p.blue {
          color: #2196f3; }
        .card_mdl .card_mdl_body .copy_card_mdl p.yellow {
          color: #ffc107; }
        .card_mdl .card_mdl_body .copy_card_mdl p.red {
          color: #e91e63; }

.top_bar {
  display: none;
  background-color: #33691e;
  flex-direction: row; }
  @media only screen and (min-width: 1025px) {
    .top_bar {
      display: flex; } }
  .top_bar .top_colm_equal {
    padding: 0 25px;
    display: flex;
    flex: 1 1 auto;
    flex-direction: row; }
    .top_bar .top_colm_equal button.mdl-button.custom {
      color: rgba(255, 255, 255, 0.6);
      font-weight: bold;
      font-family: "Roboto-Bold";
      letter-spacing: 1.25px; }
      .fontx--default .top_bar .top_colm_equal button.mdl-button.custom {
        font-size: 18px; }
      .fontx--x2 .top_bar .top_colm_equal button.mdl-button.custom {
        font-size: 20px; }
      .top_bar .top_colm_equal button.mdl-button.custom.icon_ctn {
        font-family: initial;
        font-weight: normal;
        margin-right: 15px; }
    .top_bar .top_colm_equal ul li.mdl-menu__item {
      display: flex; }
      .top_bar .top_colm_equal ul li.mdl-menu__item a {
        text-decoration: none;
        width: 100%;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.87); }
        .theme--default .top_bar .top_colm_equal ul li.mdl-menu__item a {
          color: rgba(0, 0, 0, 0.6); }
        .theme--dark .top_bar .top_colm_equal ul li.mdl-menu__item a {
          color: rgba(255, 255, 255, 0.6); }
    .top_bar .top_colm_equal._small {
      flex-basis: 25%; }
    .top_bar .top_colm_equal._left {
      margin-left: auto;
      justify-content: flex-end; }
    .top_bar .top_colm_equal .ctn_form_search {
      margin-right: 15px; }
      .top_bar .top_colm_equal .ctn_form_search .mdl-textfield--expandable {
        padding: 0; }
        .top_bar .top_colm_equal .ctn_form_search .mdl-textfield--expandable .mdl-textfield__expandable-holder {
          margin-left: 0;
          margin-right: 32px; }
          .top_bar .top_colm_equal .ctn_form_search .mdl-textfield--expandable .mdl-textfield__expandable-holder .mdl-textfield__input {
            color: rgba(255, 255, 255, 0.815);
            font-family: 'Roboto-Regular'; }
        .top_bar .top_colm_equal .ctn_form_search .mdl-textfield--expandable .mdl-button--icon {
          top: 0;
          right: 0;
          color: rgba(255, 255, 255, 0.6); }
  .top_bar .mdl-menu.lang_mdl {
    width: 200px; }
    .top_bar .mdl-menu.lang_mdl .mdl-menu__item {
      padding: 0; }
      .top_bar .mdl-menu.lang_mdl .mdl-menu__item:first-child {
        cursor: initial;
        border-bottom: 1px solid #ddd; }
        .theme--default .top_bar .mdl-menu.lang_mdl .mdl-menu__item:first-child {
          border-bottom: 1px solid rgba(221, 221, 221, 0.15); }
        .theme--dark .top_bar .mdl-menu.lang_mdl .mdl-menu__item:first-child {
          border-bottom: 1px solid rgba(221, 221, 221, 0.15); }
        .top_bar .mdl-menu.lang_mdl .mdl-menu__item:first-child:hover {
          background: transparent; }
      .top_bar .mdl-menu.lang_mdl .mdl-menu__item a {
        font-family: 'Roboto-Regular';
        padding: 0 20px;
        display: flex;
        flex-direction: row;
        align-items: center; }
        .top_bar .mdl-menu.lang_mdl .mdl-menu__item a span {
          color: transparent;
          margin-left: auto; }
        .top_bar .mdl-menu.lang_mdl .mdl-menu__item a.is-active {
          background: #fff8e1; }
          .theme--default .top_bar .mdl-menu.lang_mdl .mdl-menu__item a.is-active {
            background: rgba(255, 248, 225, 0.04); }
          .theme--dark .top_bar .mdl-menu.lang_mdl .mdl-menu__item a.is-active {
            background: rgba(255, 248, 225, 0.04); }
          .top_bar .mdl-menu.lang_mdl .mdl-menu__item a.is-active span {
            color: #ffc107; }
      .top_bar .mdl-menu.lang_mdl .mdl-menu__item p.tlt_home {
        padding: 0 20px;
        display: flex;
        flex-direction: row;
        font-family: 'Roboto Condensed Bold';
        align-items: center;
        color: rgba(0, 0, 0, 0.87);
        font-size: 16px;
        letter-spacing: .15px;
        margin-bottom: 0;
        width: 100%; }
        .theme--default .top_bar .mdl-menu.lang_mdl .mdl-menu__item p.tlt_home {
          color: #fff; }
        .theme--dark .top_bar .mdl-menu.lang_mdl .mdl-menu__item p.tlt_home {
          color: #fff; }
        .top_bar .mdl-menu.lang_mdl .mdl-menu__item p.tlt_home span {
          color: rgba(0, 0, 0, 0.6);
          margin-left: auto; }
          .theme--default .top_bar .mdl-menu.lang_mdl .mdl-menu__item p.tlt_home span {
            color: #fff; }
          .theme--dark .top_bar .mdl-menu.lang_mdl .mdl-menu__item p.tlt_home span {
            color: #fff; }
  .top_bar .mdl-menu.access_mdl {
    width: 200px; }
    .top_bar .mdl-menu.access_mdl .mdl-menu__item {
      padding: 0; }
      .top_bar .mdl-menu.access_mdl .mdl-menu__item:first-child {
        cursor: initial;
        border-bottom: 1px solid #ddd; }
        .theme--default .top_bar .mdl-menu.access_mdl .mdl-menu__item:first-child {
          border-bottom: 1px solid rgba(221, 221, 221, 0.15); }
        .theme--dark .top_bar .mdl-menu.access_mdl .mdl-menu__item:first-child {
          border-bottom: 1px solid rgba(221, 221, 221, 0.15); }
        .top_bar .mdl-menu.access_mdl .mdl-menu__item:first-child:hover {
          background: transparent; }
      .top_bar .mdl-menu.access_mdl .mdl-menu__item a {
        font-family: 'Roboto-Regular';
        padding: 0 20px;
        display: flex;
        flex-direction: row;
        align-items: center; }
        .top_bar .mdl-menu.access_mdl .mdl-menu__item a span {
          color: rgba(0, 0, 0, 0.6);
          margin-left: auto; }
          .theme--default .top_bar .mdl-menu.access_mdl .mdl-menu__item a span {
            color: rgba(255, 255, 255, 0.6); }
          .theme--dark .top_bar .mdl-menu.access_mdl .mdl-menu__item a span {
            color: rgba(255, 255, 255, 0.6); }
        .top_bar .mdl-menu.access_mdl .mdl-menu__item a.is-active {
          background: #fff8e1; }
          .theme--default .top_bar .mdl-menu.access_mdl .mdl-menu__item a.is-active {
            background: rgba(255, 248, 225, 0.04); }
          .theme--dark .top_bar .mdl-menu.access_mdl .mdl-menu__item a.is-active {
            background: rgba(255, 248, 225, 0.04); }
          .top_bar .mdl-menu.access_mdl .mdl-menu__item a.is-active span {
            color: #ffc107; }
      .top_bar .mdl-menu.access_mdl .mdl-menu__item p.tlt_home {
        padding: 0 20px;
        display: flex;
        flex-direction: row;
        font-family: 'Roboto Condensed Bold';
        align-items: center;
        color: rgba(0, 0, 0, 0.87);
        font-size: 16px;
        letter-spacing: .15px;
        margin-bottom: 0;
        width: 100%; }
        .theme--default .top_bar .mdl-menu.access_mdl .mdl-menu__item p.tlt_home {
          color: #fff; }
        .theme--dark .top_bar .mdl-menu.access_mdl .mdl-menu__item p.tlt_home {
          color: #fff; }
        .top_bar .mdl-menu.access_mdl .mdl-menu__item p.tlt_home span {
          color: rgba(0, 0, 0, 0.6);
          margin-left: auto; }
          .theme--default .top_bar .mdl-menu.access_mdl .mdl-menu__item p.tlt_home span {
            color: #fff; }
          .theme--dark .top_bar .mdl-menu.access_mdl .mdl-menu__item p.tlt_home span {
            color: #fff; }

header.custom {
  position: relative;
  min-height: 56px;
  display: flex;
  align-items: center;
  background-color: #fafafa; }
  @media only screen and (min-width: 480px) {
    header.custom {
      min-height: 77px; } }
  .theme--default header.custom {
    background-color: #fff; }
  .theme--dark header.custom {
    background-color: #222222; }
  header.custom .box_logo {
    position: absolute;
    z-index: 101;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    display: none;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    text-align: center;
    background-color: #33691e;
    top: 0;
    left: 0;
    right: 50px;
    height: 132px;
    width: 460px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px; }
    @media only screen and (min-width: 1025px) {
      header.custom .box_logo {
        display: flex; } }
    header.custom .box_logo img {
      max-width: 100%;
      width: 300px;
      object-fit: contain;
      height: 90px; }
  header.custom .custom_colm {
    flex-basis: 50%;
    padding: 0 3px; }
    @media only screen and (min-width: 1025px) {
      header.custom .custom_colm {
        padding: 0 25px; } }
    header.custom .custom_colm .mv_mdl {
      display: flex;
      flex-direction: row;
      justify-content: flex-start; }
      @media only screen and (min-width: 1025px) {
        header.custom .custom_colm .mv_mdl {
          display: none; } }
      header.custom .custom_colm .mv_mdl a.mdl-button.hamb,
      header.custom .custom_colm .mv_mdl button.mdl-button.hamb {
        display: flex;
        align-items: center;
        flex-direction: row;
        text-transform: initial; }
        header.custom .custom_colm .mv_mdl a.mdl-button.hamb span,
        header.custom .custom_colm .mv_mdl button.mdl-button.hamb span {
          color: rgba(0, 0, 0, 0.6); }
          .theme--default header.custom .custom_colm .mv_mdl a.mdl-button.hamb span, .theme--default
          header.custom .custom_colm .mv_mdl button.mdl-button.hamb span {
            color: rgba(0, 0, 0, 0.6); }
          .theme--dark header.custom .custom_colm .mv_mdl a.mdl-button.hamb span, .theme--dark
          header.custom .custom_colm .mv_mdl button.mdl-button.hamb span {
            color: rgba(255, 255, 255, 0.6); }
        header.custom .custom_colm .mv_mdl a.mdl-button.hamb span.name_hamb,
        header.custom .custom_colm .mv_mdl button.mdl-button.hamb span.name_hamb {
          font-family: 'Roboto Condensed Bold';
          color: #8bc34a;
          letter-spacing: 0.25px;
          font-size: 20px;
          padding-left: 10px; }
      header.custom .custom_colm .mv_mdl a.mdl-button.icons,
      header.custom .custom_colm .mv_mdl button.mdl-button.icons {
        margin-right: 15px; }
        header.custom .custom_colm .mv_mdl a.mdl-button.icons:last-child,
        header.custom .custom_colm .mv_mdl button.mdl-button.icons:last-child {
          margin-right: 5px; }
        header.custom .custom_colm .mv_mdl a.mdl-button.icons span,
        header.custom .custom_colm .mv_mdl button.mdl-button.icons span {
          color: rgba(0, 0, 0, 0.6); }
          .theme--default header.custom .custom_colm .mv_mdl a.mdl-button.icons span, .theme--default
          header.custom .custom_colm .mv_mdl button.mdl-button.icons span {
            color: rgba(0, 0, 0, 0.6); }
          .theme--dark header.custom .custom_colm .mv_mdl a.mdl-button.icons span, .theme--dark
          header.custom .custom_colm .mv_mdl button.mdl-button.icons span {
            color: rgba(255, 255, 255, 0.6); }
      header.custom .custom_colm .mv_mdl .ctn_lang ul.mdl-menu .mdl-menu__item a.lang {
        font-family: 'Roboto-Regular';
        text-decoration: none;
        width: 100%;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.87); }
        .theme--default header.custom .custom_colm .mv_mdl .ctn_lang ul.mdl-menu .mdl-menu__item a.lang {
          color: rgba(255, 255, 255, 0.6); }
        .theme--dark header.custom .custom_colm .mv_mdl .ctn_lang ul.mdl-menu .mdl-menu__item a.lang {
          color: rgba(255, 255, 255, 0.6); }
        header.custom .custom_colm .mv_mdl .ctn_lang ul.mdl-menu .mdl-menu__item a.lang span {
          color: transparent;
          visibility: 0;
          margin-left: auto;
          padding-right: 0; }
        header.custom .custom_colm .mv_mdl .ctn_lang ul.mdl-menu .mdl-menu__item a.lang.is-active {
          background-color: #fff8e1; }
          .theme--default header.custom .custom_colm .mv_mdl .ctn_lang ul.mdl-menu .mdl-menu__item a.lang.is-active {
            background-color: rgba(255, 248, 225, 0.07); }
          .theme--dark header.custom .custom_colm .mv_mdl .ctn_lang ul.mdl-menu .mdl-menu__item a.lang.is-active {
            background-color: rgba(255, 248, 225, 0.07); }
          header.custom .custom_colm .mv_mdl .ctn_lang ul.mdl-menu .mdl-menu__item a.lang.is-active span {
            color: #ffc107;
            visibility: 1; }
    header.custom .custom_colm ._right {
      text-align: right;
      margin-left: auto; }
      header.custom .custom_colm ._right a.mdl-button.login,
      header.custom .custom_colm ._right button.mdl-button.login {
        color: #fff;
        font-weight: bold;
        padding: 0 20px;
        height: 48px;
        background-color: #ffc107;
        letter-spacing: 1.25px;
        line-height: 16px;
        min-width: 180px; }
        header.custom .custom_colm ._right a.mdl-button.login span.material-icons,
        header.custom .custom_colm ._right button.mdl-button.login span.material-icons {
          padding-right: 10px; }
        header.custom .custom_colm ._right a.mdl-button.login span.text,
        header.custom .custom_colm ._right button.mdl-button.login span.text {
          position: relative;
          top: 2px; }
      header.custom .custom_colm ._right a.mdl-button.custom,
      header.custom .custom_colm ._right button.mdl-button.custom {
        color: rgba(0, 0, 0, 0.87);
        font-weight: bold;
        height: 48px;
        letter-spacing: 1.25px;
        line-height: 16px;
        margin-right: 20px; }
        .theme--default header.custom .custom_colm ._right a.mdl-button.custom, .theme--default
        header.custom .custom_colm ._right button.mdl-button.custom {
          color: #8bc34a; }
        .theme--dark header.custom .custom_colm ._right a.mdl-button.custom, .theme--dark
        header.custom .custom_colm ._right button.mdl-button.custom {
          color: #8bc34a; }
        header.custom .custom_colm ._right a.mdl-button.custom span,
        header.custom .custom_colm ._right button.mdl-button.custom span {
          padding-right: 15px; }
      header.custom .custom_colm ._right .mv_mdl {
        display: flex;
        flex-direction: row;
        justify-content: flex-end; }
        @media only screen and (min-width: 1025px) {
          header.custom .custom_colm ._right .mv_mdl {
            display: none; } }
      header.custom .custom_colm ._right .hd_mdl {
        display: none; }
        @media only screen and (min-width: 1025px) {
          header.custom .custom_colm ._right .hd_mdl {
            display: flex;
            justify-content: flex-end; } }
        header.custom .custom_colm ._right .hd_mdl a.mdl-button.enlace span,
        header.custom .custom_colm ._right .hd_mdl button.mdl-button.enlace span {
          color: #8bc34a; }
        header.custom .custom_colm ._right .hd_mdl .act-login {
          position: relative; }
        header.custom .custom_colm ._right .hd_mdl ul.login_access {
          width: 200px; }
          header.custom .custom_colm ._right .hd_mdl ul.login_access li.mdl-menu__item {
            display: flex; }
            header.custom .custom_colm ._right .hd_mdl ul.login_access li.mdl-menu__item a {
              text-decoration: none;
              width: 100%;
              font-size: 14px;
              color: rgba(0, 0, 0, 0.87); }
              .theme--default header.custom .custom_colm ._right .hd_mdl ul.login_access li.mdl-menu__item a {
                color: rgba(0, 0, 0, 0.6); }
              .theme--dark header.custom .custom_colm ._right .hd_mdl ul.login_access li.mdl-menu__item a {
                color: rgba(255, 255, 255, 0.6); }

.menu_mv_mdl {
  position: fixed;
  z-index: 102;
  top: 0;
  overflow: scroll;
  height: 100%;
  transform: translateX(-101%);
  background-color: #fff;
  transform-style: preserve-3d;
  will-change: transform;
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: transform; }
  .menu_mv_mdl.style_right {
    right: 0;
    transform: translateX(100%); }
    .menu_mv_mdl.style_right.is-active {
      width: 100%;
      box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.3);
      transform: translateX(0); }
      @media only screen and (min-width: 480px) {
        .menu_mv_mdl.style_right.is-active {
          width: 320px; } }
  .theme--default .menu_mv_mdl {
    background-color: #fafafa; }
  .theme--dark .menu_mv_mdl {
    background-color: #121212; }
  .menu_mv_mdl.is-active {
    width: 100%;
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.3);
    transform: translateX(0); }
    @media only screen and (min-width: 480px) {
      .menu_mv_mdl.is-active {
        width: 320px; } }
  .menu_mv_mdl .ctn_mdl_resp .menu_mdl_header {
    padding: 0 18px;
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 56px;
    background-color: #8bc34a; }
    .menu_mv_mdl .ctn_mdl_resp .menu_mdl_header span.auth {
      color: #f1f8e9; }
    .menu_mv_mdl .ctn_mdl_resp .menu_mdl_header span.logo_name {
      font-family: 'Roboto Condensed Bold';
      color: #fff;
      font-size: 20px;
      letter-spacing: 0.25px; }
    .menu_mv_mdl .ctn_mdl_resp .menu_mdl_header a.mdl-button {
      position: relative;
      right: -16px;
      min-width: 20px;
      margin-left: auto;
      color: #f1f8e9; }
  .menu_mv_mdl .ctn_mdl_resp .ctn_user {
    padding: 0 18px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 56px;
    background-color: #8bc34a; }
    .menu_mv_mdl .ctn_mdl_resp .ctn_user p {
      font-family: 'Roboto Condensed Bold';
      color: #fff;
      font-size: 20px;
      letter-spacing: 0.25px; }
  .menu_mv_mdl .action_primary {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #FAFAFA; }
    .menu_mv_mdl .action_primary:last-child {
      margin-bottom: 20px; }
    .theme--default .menu_mv_mdl .action_primary {
      border-bottom: 1px solid 1px solid rgba(250, 250, 250, 0.07); }
    .theme--dark .menu_mv_mdl .action_primary {
      border-bottom: 1px solid 1px solid rgba(250, 250, 250, 0.07); }
    .menu_mv_mdl .action_primary p.title_stn {
      font-family: 'Roboto Condensed Bold';
      line-height: 24px;
      letter-spacing: 0.15px;
      margin: 0;
      height: 48px;
      padding: 0 16px;
      width: auto;
      display: flex;
      flex-direction: row;
      align-items: center; }
      .menu_mv_mdl .action_primary p.title_stn.second {
        color: rgba(0, 0, 0, 0.6); }
        .theme--default .menu_mv_mdl .action_primary p.title_stn.second {
          color: rgba(255, 255, 255, 0.6); }
        .theme--dark .menu_mv_mdl .action_primary p.title_stn.second {
          color: rgba(255, 255, 255, 0.6); }
      .theme--default .menu_mv_mdl .action_primary p.title_stn {
        color: #fff; }
      .theme--dark .menu_mv_mdl .action_primary p.title_stn {
        color: #fff; }
      .menu_mv_mdl .action_primary p.title_stn span {
        color: rgba(0, 0, 0, 0.6);
        margin-left: auto;
        padding-right: 0; }
        .theme--default .menu_mv_mdl .action_primary p.title_stn span {
          color: rgba(255, 255, 255, 0.6); }
        .theme--dark .menu_mv_mdl .action_primary p.title_stn span {
          color: rgba(255, 255, 255, 0.6); }
    .menu_mv_mdl .action_primary a.mdl-button {
      font-family: 'Roboto Condensed Bold';
      line-height: 24px;
      letter-spacing: 0.15px;
      position: relative;
      text-align: left;
      text-transform: initial;
      height: 48px;
      width: auto;
      display: flex;
      flex-direction: row;
      align-items: center; }
      .theme--default .menu_mv_mdl .action_primary a.mdl-button {
        color: #fff; }
      .theme--dark .menu_mv_mdl .action_primary a.mdl-button {
        color: #fff; }
      .menu_mv_mdl .action_primary a.mdl-button span {
        padding-right: 10px; }
      .menu_mv_mdl .action_primary a.mdl-button.access {
        font-family: 'Roboto-Regular';
        color: rgba(0, 0, 0, 0.6); }
        .theme--default .menu_mv_mdl .action_primary a.mdl-button.access {
          color: rgba(255, 255, 255, 0.6); }
        .theme--dark .menu_mv_mdl .action_primary a.mdl-button.access {
          color: rgba(255, 255, 255, 0.6); }
        .menu_mv_mdl .action_primary a.mdl-button.access span {
          margin-left: auto;
          padding-right: 0; }
        .menu_mv_mdl .action_primary a.mdl-button.access.is-active span {
          color: #ffc107; }
      .menu_mv_mdl .action_primary a.mdl-button.lang {
        font-family: 'Roboto-Regular';
        color: rgba(0, 0, 0, 0.6); }
        .theme--default .menu_mv_mdl .action_primary a.mdl-button.lang {
          color: rgba(255, 255, 255, 0.6); }
        .theme--dark .menu_mv_mdl .action_primary a.mdl-button.lang {
          color: rgba(255, 255, 255, 0.6); }
        .menu_mv_mdl .action_primary a.mdl-button.lang span {
          color: transparent;
          visibility: 0;
          margin-left: auto;
          padding-right: 0; }
        .menu_mv_mdl .action_primary a.mdl-button.lang.is-active {
          background-color: #fff8e1; }
          .theme--default .menu_mv_mdl .action_primary a.mdl-button.lang.is-active {
            background-color: rgba(255, 248, 225, 0.07); }
          .theme--dark .menu_mv_mdl .action_primary a.mdl-button.lang.is-active {
            background-color: rgba(255, 248, 225, 0.07); }
          .menu_mv_mdl .action_primary a.mdl-button.lang.is-active span {
            color: #ffc107;
            visibility: 1; }
      .menu_mv_mdl .action_primary a.mdl-button.green_link {
        color: rgba(0, 0, 0, 0.6); }
        .theme--default .menu_mv_mdl .action_primary a.mdl-button.green_link {
          color: rgba(255, 255, 255, 0.6); }
        .theme--dark .menu_mv_mdl .action_primary a.mdl-button.green_link {
          color: rgba(255, 255, 255, 0.6); }
        .menu_mv_mdl .action_primary a.mdl-button.green_link span {
          color: #8bc34a; }
        .menu_mv_mdl .action_primary a.mdl-button.green_link:hover {
          color: #8bc34a; }
      .menu_mv_mdl .action_primary a.mdl-button.login {
        color: #ffc107; }

.bg_black {
  display: none;
  position: fixed;
  top: 0;
  z-index: 101;
  background-color: rgba(250, 250, 250, 0.75);
  height: 100%;
  width: 100%; }
  .bg_black.is-active {
    display: block; }

footer.custom {
  padding: 45px 20px;
  background-color: #33691e; }

footer .copy_footer_ifo {
  margin-right: 40px; }
  footer .copy_footer_ifo .ctn_logo img {
    width: 300px;
    height: 90px;
    object-fit: contain; }
  footer .copy_footer_ifo .ctn_social {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 10px; }
    footer .copy_footer_ifo .ctn_social a.mdl-button {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 24px; }
      footer .copy_footer_ifo .ctn_social a.mdl-button span {
        font-size: 20px;
        color: rgba(255, 255, 255, 0.6); }
  footer .copy_footer_ifo .ctn_body p {
    color: rgba(255, 255, 255, 0.6);
    font-family: 'Roboto-Regular';
    line-height: 1;
    font-size: 14px;
    letter-spacing: 0.25px; }
    .fontx--default footer .copy_footer_ifo .ctn_body p {
      font-size: 16px; }
    .fontx--x2 footer .copy_footer_ifo .ctn_body p {
      font-size: 16px; }

footer .row_footer {
  display: flex;
  flex-direction: column; }
  @media only screen and (min-width: 480px) {
    footer .row_footer {
      flex-direction: row; } }
  footer .row_footer > .colm_footer {
    flex-basis: 100%; }
    @media only screen and (min-width: 480px) {
      footer .row_footer > .colm_footer {
        flex-basis: 100%; } }
    @media only screen and (min-width: 480px) {
      footer .row_footer > .colm_footer.mdl_custom {
        flex-basis: 33.33333%; } }
    footer .row_footer > .colm_footer .list_footer {
      display: flex;
      align-items: center;
      height: 100%;
      padding-top: 15px; }
      footer .row_footer > .colm_footer .list_footer .list_footer_center {
        width: 100%; }
      footer .row_footer > .colm_footer .list_footer a.mdl-button {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: #fff;
        margin-bottom: 20px; }
        footer .row_footer > .colm_footer .list_footer a.mdl-button span {
          color: rgba(255, 255, 255, 0.6);
          margin-left: auto; }
      @media only screen and (min-width: 1025px) {
        footer .row_footer > .colm_footer .list_footer {
          display: none; } }
    footer .row_footer > .colm_footer .row_int {
      display: none; }
      @media only screen and (min-width: 1025px) {
        footer .row_footer > .colm_footer .row_int {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap; } }
      footer .row_footer > .colm_footer .row_int .colm_int {
        flex-basis: 33.333333%; }
        footer .row_footer > .colm_footer .row_int .colm_int .copy_links {
          display: flex;
          width: 85%;
          flex-direction: column; }
          footer .row_footer > .colm_footer .row_int .colm_int .copy_links p.title_links {
            font-family: 'Roboto Condensed Bold';
            font-size: 16px;
            line-height: 24px;
            color: #fff;
            letter-spacing: 0.15px;
            margin-bottom: 5px; }
            .fontx--default footer .row_footer > .colm_footer .row_int .colm_int .copy_links p.title_links {
              font-size: 18px; }
            .fontx--x2 footer .row_footer > .colm_footer .row_int .colm_int .copy_links p.title_links {
              font-size: 18px; }
          footer .row_footer > .colm_footer .row_int .colm_int .copy_links a.mdl-button {
            display: flex;
            color: rgba(255, 255, 255, 0.6);
            overflow: initial;
            align-items: center;
            text-align: left;
            padding: 2px 3px 3px;
            height: auto;
            min-width: initial;
            font-family: 'Roboto-Regular';
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 0.25px;
            text-transform: initial;
            margin-bottom: 0px; }
            .fontx--default footer .row_footer > .colm_footer .row_int .colm_int .copy_links a.mdl-button {
              font-size: 18px; }
            .fontx--x2 footer .row_footer > .colm_footer .row_int .colm_int .copy_links a.mdl-button {
              font-size: 18px; }

/**
 * material-design-lite - Material Design Components in CSS, JS and HTML
 * @version v1.3.0
 * @license Apache-2.0
 * @copyright 2015 Google, Inc.
 * @link https://github.com/google/material-design-lite
 */
html {
  color: rgba(0, 0, 0, 0.87); }

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none; }

::selection {
  background: #b3d4fc;
  text-shadow: none; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0; }

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle; }

fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

textarea {
  resize: vertical; }

.browserupgrade {
  margin: .2em 0;
  background: #ccc;
  color: #000;
  padding: .2em 0; }

.hidden {
  display: none !important; }

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto; }

.invisible {
  visibility: hidden; }

.clearfix:before,
.clearfix:after {
  content: " ";
  display: table; }

.clearfix:after {
  clear: both; }

@media print {
  *,
  *:before,
  *:after,
  *:first-letter {
    background: transparent !important;
    color: #000 !important;
    box-shadow: none !important; }
  a,
  a:visited {
    text-decoration: underline; }
  a[href]:after {
    content: " (" attr(href) ")"; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  a[href^="#"]:after,
  a[href^="javascript:"]:after {
    content: ""; }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; } }

a,
.mdl-accordion,
.mdl-button,
.mdl-card,
.mdl-checkbox,
.mdl-dropdown-menu,
.mdl-icon-toggle,
.mdl-item,
.mdl-radio,
.mdl-slider,
.mdl-switch,
.mdl-tabs__tab {
  -webkit-tap-highlight-color: transparent;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0); }

html {
  width: 100%;
  height: 100%;
  -ms-touch-action: manipulation;
  touch-action: manipulation; }

body {
  width: 100%;
  min-height: 100%; }

main {
  display: block; }

*[hidden] {
  display: none !important; }

html,
body {
  font-family: "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px; }
  .fontx--default html, .fontx--default
  body {
    font-size: 14px; }
  .fontx--x2 html, .fontx--x2
  body {
    font-size: 16px; }

h1,
h2,
h3,
h4,
h5,
h6,
p {
  padding: 0; }

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.35;
  letter-spacing: -.02em;
  opacity: .54;
  font-size: .6em; }

h1 {
  font-size: 56px;
  line-height: 1.35;
  letter-spacing: -.02em;
  margin: 24px 0; }

h1,
h2 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400; }

h2 {
  font-size: 45px;
  line-height: 48px; }

h2,
h3 {
  margin: 24px 0; }

h3 {
  font-size: 34px;
  line-height: 40px; }
  .fontx--default h3 {
    font-size: 34px; }
  .fontx--x2 h3 {
    font-size: 36px; }

h3,
h4 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400; }

h4 {
  font-size: 24px;
  line-height: 32px;
  -moz-osx-font-smoothing: grayscale;
  margin: 24px 0 16px; }
  .fontx--default h4 {
    font-size: 24px; }
  .fontx--x2 h4 {
    font-size: 26px; }

h5 {
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: .02em; }
  .fontx--default h5 {
    font-size: 20px; }
  .fontx--x2 h5 {
    font-size: 22px; }

h5,
h6 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  margin: 24px 0 16px; }

h6 {
  font-size: 16px;
  letter-spacing: .04em; }
  .fontx--default h6 {
    font-size: 16px; }
  .fontx--x2 h6 {
    font-size: 18px; }

h6,
p {
  font-weight: 400;
  line-height: 24px; }

p {
  font-size: 14px;
  letter-spacing: 0;
  margin: 0 0 16px; }
  .fontx--default p {
    font-size: 14px; }
  .fontx--x2 p {
    font-size: 16px; }

a {
  color: #448aff;
  font-weight: 500; }

blockquote {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  position: relative;
  font-size: 24px;
  font-weight: 300;
  font-style: italic;
  line-height: 1.35;
  letter-spacing: .08em; }
  .fontx--default blockquote {
    font-size: 24px; }
  .fontx--x2 blockquote {
    font-size: 26px; }

blockquote:before {
  position: absolute;
  left: -.5em;
  content: 'â€œ'; }

blockquote:after {
  content: 'â€';
  margin-left: -.05em; }

mark {
  background-color: #f4ff81; }

dt {
  font-weight: 700; }

address {
  font-size: 12px;
  line-height: 1;
  font-style: normal; }
  .fontx--default address {
    font-size: 12px; }
  .fontx--x2 address {
    font-size: 14px; }

address,
ul,
ol {
  font-weight: 400;
  letter-spacing: 0; }

ul,
ol {
  font-size: 14px;
  line-height: 24px; }
  .fontx--default ul, .fontx--default
  ol {
    font-size: 14px; }
  .fontx--x2 ul, .fontx--x2
  ol {
    font-size: 16px; }

.mdl-typography--display-4,
.mdl-typography--display-4-color-contrast {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 112px;
  font-weight: 300;
  line-height: 1;
  letter-spacing: -.04em; }

.mdl-typography--display-4-color-contrast {
  opacity: .54; }

.mdl-typography--display-3,
.mdl-typography--display-3-color-contrast {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 56px;
  font-weight: 400;
  line-height: 1.35;
  letter-spacing: -.02em; }

.mdl-typography--display-3-color-contrast {
  opacity: .54; }

.mdl-typography--display-2,
.mdl-typography--display-2-color-contrast {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 45px;
  font-weight: 400;
  line-height: 48px; }

.mdl-typography--display-2-color-contrast {
  opacity: .54; }

.mdl-typography--display-1,
.mdl-typography--display-1-color-contrast {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 34px;
  font-weight: 400;
  line-height: 40px; }
  .fontx--default .mdl-typography--display-1, .fontx--default
  .mdl-typography--display-1-color-contrast {
    font-size: 34px; }
  .fontx--x2 .mdl-typography--display-1, .fontx--x2
  .mdl-typography--display-1-color-contrast {
    font-size: 36px; }

.mdl-typography--display-1-color-contrast {
  opacity: .54; }

.mdl-typography--headline,
.mdl-typography--headline-color-contrast {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  -moz-osx-font-smoothing: grayscale; }
  .fontx--default .mdl-typography--headline, .fontx--default
  .mdl-typography--headline-color-contrast {
    font-size: 24px; }
  .fontx--x2 .mdl-typography--headline, .fontx--x2
  .mdl-typography--headline-color-contrast {
    font-size: 26px; }

.mdl-typography--headline-color-contrast {
  opacity: .87; }

.mdl-typography--title,
.mdl-typography--title-color-contrast {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: .02em; }
  .fontx--default .mdl-typography--title, .fontx--default
  .mdl-typography--title-color-contrast {
    font-size: 20px; }
  .fontx--x2 .mdl-typography--title, .fontx--x2
  .mdl-typography--title-color-contrast {
    font-size: 22px; }

.mdl-typography--title-color-contrast {
  opacity: .87; }

.mdl-typography--subhead,
.mdl-typography--subhead-color-contrast {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: .04em; }
  .fontx--default .mdl-typography--subhead, .fontx--default
  .mdl-typography--subhead-color-contrast {
    font-size: 16px; }
  .fontx--x2 .mdl-typography--subhead, .fontx--x2
  .mdl-typography--subhead-color-contrast {
    font-size: 18px; }

.mdl-typography--subhead-color-contrast {
  opacity: .87; }

.mdl-typography--body-2,
.mdl-typography--body-2-color-contrast {
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0; }
  .fontx--default .mdl-typography--body-2, .fontx--default
  .mdl-typography--body-2-color-contrast {
    font-size: 14px; }
  .fontx--x2 .mdl-typography--body-2, .fontx--x2
  .mdl-typography--body-2-color-contrast {
    font-size: 16px; }

.mdl-typography--body-2-color-contrast {
  opacity: .87; }

.mdl-typography--body-1,
.mdl-typography--body-1-color-contrast {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0; }
  .fontx--default .mdl-typography--body-1, .fontx--default
  .mdl-typography--body-1-color-contrast {
    font-size: 14px; }
  .fontx--x2 .mdl-typography--body-1, .fontx--x2
  .mdl-typography--body-1-color-contrast {
    font-size: 16px; }

.mdl-typography--body-1-color-contrast {
  opacity: .87; }

.mdl-typography--body-2-force-preferred-font,
.mdl-typography--body-2-force-preferred-font-color-contrast {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0; }
  .fontx--default .mdl-typography--body-2-force-preferred-font, .fontx--default
  .mdl-typography--body-2-force-preferred-font-color-contrast {
    font-size: 14px; }
  .fontx--x2 .mdl-typography--body-2-force-preferred-font, .fontx--x2
  .mdl-typography--body-2-force-preferred-font-color-contrast {
    font-size: 16px; }

.mdl-typography--body-2-force-preferred-font-color-contrast {
  opacity: .87; }

.mdl-typography--body-1-force-preferred-font,
.mdl-typography--body-1-force-preferred-font-color-contrast {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0; }
  .fontx--default .mdl-typography--body-1-force-preferred-font, .fontx--default
  .mdl-typography--body-1-force-preferred-font-color-contrast {
    font-size: 14px; }
  .fontx--x2 .mdl-typography--body-1-force-preferred-font, .fontx--x2
  .mdl-typography--body-1-force-preferred-font-color-contrast {
    font-size: 16px; }

.mdl-typography--body-1-force-preferred-font-color-contrast {
  opacity: .87; }

.mdl-typography--caption,
.mdl-typography--caption-force-preferred-font {
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0; }
  .fontx--default .mdl-typography--caption, .fontx--default
  .mdl-typography--caption-force-preferred-font {
    font-size: 12px; }
  .fontx--x2 .mdl-typography--caption, .fontx--x2
  .mdl-typography--caption-force-preferred-font {
    font-size: 14px; }

.mdl-typography--caption-force-preferred-font {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif; }

.mdl-typography--caption-color-contrast,
.mdl-typography--caption-force-preferred-font-color-contrast {
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0;
  opacity: .54; }
  .fontx--default .mdl-typography--caption-color-contrast, .fontx--default
  .mdl-typography--caption-force-preferred-font-color-contrast {
    font-size: 12px; }
  .fontx--x2 .mdl-typography--caption-color-contrast, .fontx--x2
  .mdl-typography--caption-force-preferred-font-color-contrast {
    font-size: 14px; }

.mdl-typography--caption-force-preferred-font-color-contrast,
.mdl-typography--menu {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif; }

.mdl-typography--menu {
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0; }
  .fontx--default .mdl-typography--menu {
    font-size: 14px; }
  .fontx--x2 .mdl-typography--menu {
    font-size: 16px; }

.mdl-typography--menu-color-contrast {
  opacity: .87; }

.mdl-typography--menu-color-contrast,
.mdl-typography--button,
.mdl-typography--button-color-contrast {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0; }
  .fontx--default .mdl-typography--menu-color-contrast, .fontx--default
  .mdl-typography--button, .fontx--default
  .mdl-typography--button-color-contrast {
    font-size: 14px; }
  .fontx--x2 .mdl-typography--menu-color-contrast, .fontx--x2
  .mdl-typography--button, .fontx--x2
  .mdl-typography--button-color-contrast {
    font-size: 16px; }

.mdl-typography--button,
.mdl-typography--button-color-contrast {
  text-transform: uppercase; }

.mdl-typography--button-color-contrast {
  opacity: .87; }

.mdl-typography--text-left {
  text-align: left; }

.mdl-typography--text-right {
  text-align: right; }

.mdl-typography--text-center {
  text-align: center; }

.mdl-typography--text-justify {
  text-align: justify; }

.mdl-typography--text-nowrap {
  white-space: nowrap; }

.mdl-typography--text-lowercase {
  text-transform: lowercase; }

.mdl-typography--text-uppercase {
  text-transform: uppercase; }

.mdl-typography--text-capitalize {
  text-transform: capitalize; }

.mdl-typography--font-thin {
  font-weight: 200 !important; }

.mdl-typography--font-light {
  font-weight: 300 !important; }

.mdl-typography--font-regular {
  font-weight: 400 !important; }

.mdl-typography--font-medium {
  font-weight: 500 !important; }

.mdl-typography--font-bold {
  font-weight: 700 !important; }

.mdl-typography--font-black {
  font-weight: 900 !important; }

.material-icons {
  font-family: 'Material Icons';
  font-weight: 400;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  word-wrap: normal;
  -moz-font-feature-settings: 'liga';
  font-feature-settings: 'liga';
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased; }
  .fontx--default .material-icons {
    font-size: 24px; }
  .fontx--x2 .material-icons {
    font-size: 26px; }

.mdl-color-text--red {
  color: #f44336 !important; }

.mdl-color--red {
  background-color: #f44336 !important; }

.mdl-color-text--red-50 {
  color: #ffebee !important; }

.mdl-color--red-50 {
  background-color: #ffebee !important; }

.mdl-color-text--red-100 {
  color: #ffcdd2 !important; }

.mdl-color--red-100 {
  background-color: #ffcdd2 !important; }

.mdl-color-text--red-200 {
  color: #ef9a9a !important; }

.mdl-color--red-200 {
  background-color: #ef9a9a !important; }

.mdl-color-text--red-300 {
  color: #e57373 !important; }

.mdl-color--red-300 {
  background-color: #e57373 !important; }

.mdl-color-text--red-400 {
  color: #ef5350 !important; }

.mdl-color--red-400 {
  background-color: #ef5350 !important; }

.mdl-color-text--red-500 {
  color: #f44336 !important; }

.mdl-color--red-500 {
  background-color: #f44336 !important; }

.mdl-color-text--red-600 {
  color: #e53935 !important; }

.mdl-color--red-600 {
  background-color: #e53935 !important; }

.mdl-color-text--red-700 {
  color: #d32f2f !important; }

.mdl-color--red-700 {
  background-color: #d32f2f !important; }

.mdl-color-text--red-800 {
  color: #c62828 !important; }

.mdl-color--red-800 {
  background-color: #c62828 !important; }

.mdl-color-text--red-900 {
  color: #b71c1c !important; }

.mdl-color--red-900 {
  background-color: #b71c1c !important; }

.mdl-color-text--red-A100 {
  color: #ff8a80 !important; }

.mdl-color--red-A100 {
  background-color: #ff8a80 !important; }

.mdl-color-text--red-A200 {
  color: #ff5252 !important; }

.mdl-color--red-A200 {
  background-color: #ff5252 !important; }

.mdl-color-text--red-A400 {
  color: #ff1744 !important; }

.mdl-color--red-A400 {
  background-color: #ff1744 !important; }

.mdl-color-text--red-A700 {
  color: #d50000 !important; }

.mdl-color--red-A700 {
  background-color: #d50000 !important; }

.mdl-color-text--pink {
  color: #e91e63 !important; }

.mdl-color--pink {
  background-color: #e91e63 !important; }

.mdl-color-text--pink-50 {
  color: #fce4ec !important; }

.mdl-color--pink-50 {
  background-color: #fce4ec !important; }

.mdl-color-text--pink-100 {
  color: #f8bbd0 !important; }

.mdl-color--pink-100 {
  background-color: #f8bbd0 !important; }

.mdl-color-text--pink-200 {
  color: #f48fb1 !important; }

.mdl-color--pink-200 {
  background-color: #f48fb1 !important; }

.mdl-color-text--pink-300 {
  color: #f06292 !important; }

.mdl-color--pink-300 {
  background-color: #f06292 !important; }

.mdl-color-text--pink-400 {
  color: #ec407a !important; }

.mdl-color--pink-400 {
  background-color: #ec407a !important; }

.mdl-color-text--pink-500 {
  color: #e91e63 !important; }

.mdl-color--pink-500 {
  background-color: #e91e63 !important; }

.mdl-color-text--pink-600 {
  color: #d81b60 !important; }

.mdl-color--pink-600 {
  background-color: #d81b60 !important; }

.mdl-color-text--pink-700 {
  color: #c2185b !important; }

.mdl-color--pink-700 {
  background-color: #c2185b !important; }

.mdl-color-text--pink-800 {
  color: #ad1457 !important; }

.mdl-color--pink-800 {
  background-color: #ad1457 !important; }

.mdl-color-text--pink-900 {
  color: #880e4f !important; }

.mdl-color--pink-900 {
  background-color: #880e4f !important; }

.mdl-color-text--pink-A100 {
  color: #ff80ab !important; }

.mdl-color--pink-A100 {
  background-color: #ff80ab !important; }

.mdl-color-text--pink-A200 {
  color: #ff4081 !important; }

.mdl-color--pink-A200 {
  background-color: #ff4081 !important; }

.mdl-color-text--pink-A400 {
  color: #f50057 !important; }

.mdl-color--pink-A400 {
  background-color: #f50057 !important; }

.mdl-color-text--pink-A700 {
  color: #c51162 !important; }

.mdl-color--pink-A700 {
  background-color: #c51162 !important; }

.mdl-color-text--purple {
  color: #9c27b0 !important; }

.mdl-color--purple {
  background-color: #9c27b0 !important; }

.mdl-color-text--purple-50 {
  color: #f3e5f5 !important; }

.mdl-color--purple-50 {
  background-color: #f3e5f5 !important; }

.mdl-color-text--purple-100 {
  color: #e1bee7 !important; }

.mdl-color--purple-100 {
  background-color: #e1bee7 !important; }

.mdl-color-text--purple-200 {
  color: #ce93d8 !important; }

.mdl-color--purple-200 {
  background-color: #ce93d8 !important; }

.mdl-color-text--purple-300 {
  color: #ba68c8 !important; }

.mdl-color--purple-300 {
  background-color: #ba68c8 !important; }

.mdl-color-text--purple-400 {
  color: #ab47bc !important; }

.mdl-color--purple-400 {
  background-color: #ab47bc !important; }

.mdl-color-text--purple-500 {
  color: #9c27b0 !important; }

.mdl-color--purple-500 {
  background-color: #9c27b0 !important; }

.mdl-color-text--purple-600 {
  color: #8e24aa !important; }

.mdl-color--purple-600 {
  background-color: #8e24aa !important; }

.mdl-color-text--purple-700 {
  color: #7b1fa2 !important; }

.mdl-color--purple-700 {
  background-color: #7b1fa2 !important; }

.mdl-color-text--purple-800 {
  color: #6a1b9a !important; }

.mdl-color--purple-800 {
  background-color: #6a1b9a !important; }

.mdl-color-text--purple-900 {
  color: #4a148c !important; }

.mdl-color--purple-900 {
  background-color: #4a148c !important; }

.mdl-color-text--purple-A100 {
  color: #ea80fc !important; }

.mdl-color--purple-A100 {
  background-color: #ea80fc !important; }

.mdl-color-text--purple-A200 {
  color: #e040fb !important; }

.mdl-color--purple-A200 {
  background-color: #e040fb !important; }

.mdl-color-text--purple-A400 {
  color: #d500f9 !important; }

.mdl-color--purple-A400 {
  background-color: #d500f9 !important; }

.mdl-color-text--purple-A700 {
  color: #a0f !important; }

.mdl-color--purple-A700 {
  background-color: #a0f !important; }

.mdl-color-text--deep-purple {
  color: #673ab7 !important; }

.mdl-color--deep-purple {
  background-color: #673ab7 !important; }

.mdl-color-text--deep-purple-50 {
  color: #ede7f6 !important; }

.mdl-color--deep-purple-50 {
  background-color: #ede7f6 !important; }

.mdl-color-text--deep-purple-100 {
  color: #d1c4e9 !important; }

.mdl-color--deep-purple-100 {
  background-color: #d1c4e9 !important; }

.mdl-color-text--deep-purple-200 {
  color: #b39ddb !important; }

.mdl-color--deep-purple-200 {
  background-color: #b39ddb !important; }

.mdl-color-text--deep-purple-300 {
  color: #9575cd !important; }

.mdl-color--deep-purple-300 {
  background-color: #9575cd !important; }

.mdl-color-text--deep-purple-400 {
  color: #7e57c2 !important; }

.mdl-color--deep-purple-400 {
  background-color: #7e57c2 !important; }

.mdl-color-text--deep-purple-500 {
  color: #673ab7 !important; }

.mdl-color--deep-purple-500 {
  background-color: #673ab7 !important; }

.mdl-color-text--deep-purple-600 {
  color: #5e35b1 !important; }

.mdl-color--deep-purple-600 {
  background-color: #5e35b1 !important; }

.mdl-color-text--deep-purple-700 {
  color: #512da8 !important; }

.mdl-color--deep-purple-700 {
  background-color: #512da8 !important; }

.mdl-color-text--deep-purple-800 {
  color: #4527a0 !important; }

.mdl-color--deep-purple-800 {
  background-color: #4527a0 !important; }

.mdl-color-text--deep-purple-900 {
  color: #311b92 !important; }

.mdl-color--deep-purple-900 {
  background-color: #311b92 !important; }

.mdl-color-text--deep-purple-A100 {
  color: #b388ff !important; }

.mdl-color--deep-purple-A100 {
  background-color: #b388ff !important; }

.mdl-color-text--deep-purple-A200 {
  color: #7c4dff !important; }

.mdl-color--deep-purple-A200 {
  background-color: #7c4dff !important; }

.mdl-color-text--deep-purple-A400 {
  color: #651fff !important; }

.mdl-color--deep-purple-A400 {
  background-color: #651fff !important; }

.mdl-color-text--deep-purple-A700 {
  color: #6200ea !important; }

.mdl-color--deep-purple-A700 {
  background-color: #6200ea !important; }

.mdl-color-text--indigo {
  color: #3f51b5 !important; }

.mdl-color--indigo {
  background-color: #3f51b5 !important; }

.mdl-color-text--indigo-50 {
  color: #e8eaf6 !important; }

.mdl-color--indigo-50 {
  background-color: #e8eaf6 !important; }

.mdl-color-text--indigo-100 {
  color: #c5cae9 !important; }

.mdl-color--indigo-100 {
  background-color: #c5cae9 !important; }

.mdl-color-text--indigo-200 {
  color: #9fa8da !important; }

.mdl-color--indigo-200 {
  background-color: #9fa8da !important; }

.mdl-color-text--indigo-300 {
  color: #7986cb !important; }

.mdl-color--indigo-300 {
  background-color: #7986cb !important; }

.mdl-color-text--indigo-400 {
  color: #5c6bc0 !important; }

.mdl-color--indigo-400 {
  background-color: #5c6bc0 !important; }

.mdl-color-text--indigo-500 {
  color: #3f51b5 !important; }

.mdl-color--indigo-500 {
  background-color: #3f51b5 !important; }

.mdl-color-text--indigo-600 {
  color: #3949ab !important; }

.mdl-color--indigo-600 {
  background-color: #3949ab !important; }

.mdl-color-text--indigo-700 {
  color: #303f9f !important; }

.mdl-color--indigo-700 {
  background-color: #303f9f !important; }

.mdl-color-text--indigo-800 {
  color: #283593 !important; }

.mdl-color--indigo-800 {
  background-color: #283593 !important; }

.mdl-color-text--indigo-900 {
  color: #1a237e !important; }

.mdl-color--indigo-900 {
  background-color: #1a237e !important; }

.mdl-color-text--indigo-A100 {
  color: #8c9eff !important; }

.mdl-color--indigo-A100 {
  background-color: #8c9eff !important; }

.mdl-color-text--indigo-A200 {
  color: #536dfe !important; }

.mdl-color--indigo-A200 {
  background-color: #536dfe !important; }

.mdl-color-text--indigo-A400 {
  color: #3d5afe !important; }

.mdl-color--indigo-A400 {
  background-color: #3d5afe !important; }

.mdl-color-text--indigo-A700 {
  color: #304ffe !important; }

.mdl-color--indigo-A700 {
  background-color: #304ffe !important; }

.mdl-color-text--blue {
  color: #2196f3 !important; }

.mdl-color--blue {
  background-color: #2196f3 !important; }

.mdl-color-text--blue-50 {
  color: #e3f2fd !important; }

.mdl-color--blue-50 {
  background-color: #e3f2fd !important; }

.mdl-color-text--blue-100 {
  color: #bbdefb !important; }

.mdl-color--blue-100 {
  background-color: #bbdefb !important; }

.mdl-color-text--blue-200 {
  color: #90caf9 !important; }

.mdl-color--blue-200 {
  background-color: #90caf9 !important; }

.mdl-color-text--blue-300 {
  color: #64b5f6 !important; }

.mdl-color--blue-300 {
  background-color: #64b5f6 !important; }

.mdl-color-text--blue-400 {
  color: #42a5f5 !important; }

.mdl-color--blue-400 {
  background-color: #42a5f5 !important; }

.mdl-color-text--blue-500 {
  color: #2196f3 !important; }

.mdl-color--blue-500 {
  background-color: #2196f3 !important; }

.mdl-color-text--blue-600 {
  color: #1e88e5 !important; }

.mdl-color--blue-600 {
  background-color: #1e88e5 !important; }

.mdl-color-text--blue-700 {
  color: #1976d2 !important; }

.mdl-color--blue-700 {
  background-color: #1976d2 !important; }

.mdl-color-text--blue-800 {
  color: #1565c0 !important; }

.mdl-color--blue-800 {
  background-color: #1565c0 !important; }

.mdl-color-text--blue-900 {
  color: #0d47a1 !important; }

.mdl-color--blue-900 {
  background-color: #0d47a1 !important; }

.mdl-color-text--blue-A100 {
  color: #82b1ff !important; }

.mdl-color--blue-A100 {
  background-color: #82b1ff !important; }

.mdl-color-text--blue-A200 {
  color: #448aff !important; }

.mdl-color--blue-A200 {
  background-color: #448aff !important; }

.mdl-color-text--blue-A400 {
  color: #2979ff !important; }

.mdl-color--blue-A400 {
  background-color: #2979ff !important; }

.mdl-color-text--blue-A700 {
  color: #2962ff !important; }

.mdl-color--blue-A700 {
  background-color: #2962ff !important; }

.mdl-color-text--light-blue {
  color: #03a9f4 !important; }

.mdl-color--light-blue {
  background-color: #03a9f4 !important; }

.mdl-color-text--light-blue-50 {
  color: #e1f5fe !important; }

.mdl-color--light-blue-50 {
  background-color: #e1f5fe !important; }

.mdl-color-text--light-blue-100 {
  color: #b3e5fc !important; }

.mdl-color--light-blue-100 {
  background-color: #b3e5fc !important; }

.mdl-color-text--light-blue-200 {
  color: #81d4fa !important; }

.mdl-color--light-blue-200 {
  background-color: #81d4fa !important; }

.mdl-color-text--light-blue-300 {
  color: #4fc3f7 !important; }

.mdl-color--light-blue-300 {
  background-color: #4fc3f7 !important; }

.mdl-color-text--light-blue-400 {
  color: #29b6f6 !important; }

.mdl-color--light-blue-400 {
  background-color: #29b6f6 !important; }

.mdl-color-text--light-blue-500 {
  color: #03a9f4 !important; }

.mdl-color--light-blue-500 {
  background-color: #03a9f4 !important; }

.mdl-color-text--light-blue-600 {
  color: #039be5 !important; }

.mdl-color--light-blue-600 {
  background-color: #039be5 !important; }

.mdl-color-text--light-blue-700 {
  color: #0288d1 !important; }

.mdl-color--light-blue-700 {
  background-color: #0288d1 !important; }

.mdl-color-text--light-blue-800 {
  color: #0277bd !important; }

.mdl-color--light-blue-800 {
  background-color: #0277bd !important; }

.mdl-color-text--light-blue-900 {
  color: #01579b !important; }

.mdl-color--light-blue-900 {
  background-color: #01579b !important; }

.mdl-color-text--light-blue-A100 {
  color: #80d8ff !important; }

.mdl-color--light-blue-A100 {
  background-color: #80d8ff !important; }

.mdl-color-text--light-blue-A200 {
  color: #40c4ff !important; }

.mdl-color--light-blue-A200 {
  background-color: #40c4ff !important; }

.mdl-color-text--light-blue-A400 {
  color: #00b0ff !important; }

.mdl-color--light-blue-A400 {
  background-color: #00b0ff !important; }

.mdl-color-text--light-blue-A700 {
  color: #0091ea !important; }

.mdl-color--light-blue-A700 {
  background-color: #0091ea !important; }

.mdl-color-text--cyan {
  color: #00bcd4 !important; }

.mdl-color--cyan {
  background-color: #00bcd4 !important; }

.mdl-color-text--cyan-50 {
  color: #e0f7fa !important; }

.mdl-color--cyan-50 {
  background-color: #e0f7fa !important; }

.mdl-color-text--cyan-100 {
  color: #b2ebf2 !important; }

.mdl-color--cyan-100 {
  background-color: #b2ebf2 !important; }

.mdl-color-text--cyan-200 {
  color: #80deea !important; }

.mdl-color--cyan-200 {
  background-color: #80deea !important; }

.mdl-color-text--cyan-300 {
  color: #4dd0e1 !important; }

.mdl-color--cyan-300 {
  background-color: #4dd0e1 !important; }

.mdl-color-text--cyan-400 {
  color: #26c6da !important; }

.mdl-color--cyan-400 {
  background-color: #26c6da !important; }

.mdl-color-text--cyan-500 {
  color: #00bcd4 !important; }

.mdl-color--cyan-500 {
  background-color: #00bcd4 !important; }

.mdl-color-text--cyan-600 {
  color: #00acc1 !important; }

.mdl-color--cyan-600 {
  background-color: #00acc1 !important; }

.mdl-color-text--cyan-700 {
  color: #0097a7 !important; }

.mdl-color--cyan-700 {
  background-color: #0097a7 !important; }

.mdl-color-text--cyan-800 {
  color: #00838f !important; }

.mdl-color--cyan-800 {
  background-color: #00838f !important; }

.mdl-color-text--cyan-900 {
  color: #006064 !important; }

.mdl-color--cyan-900 {
  background-color: #006064 !important; }

.mdl-color-text--cyan-A100 {
  color: #84ffff !important; }

.mdl-color--cyan-A100 {
  background-color: #84ffff !important; }

.mdl-color-text--cyan-A200 {
  color: #18ffff !important; }

.mdl-color--cyan-A200 {
  background-color: #18ffff !important; }

.mdl-color-text--cyan-A400 {
  color: #00e5ff !important; }

.mdl-color--cyan-A400 {
  background-color: #00e5ff !important; }

.mdl-color-text--cyan-A700 {
  color: #00b8d4 !important; }

.mdl-color--cyan-A700 {
  background-color: #00b8d4 !important; }

.mdl-color-text--teal {
  color: #009688 !important; }

.mdl-color--teal {
  background-color: #009688 !important; }

.mdl-color-text--teal-50 {
  color: #e0f2f1 !important; }

.mdl-color--teal-50 {
  background-color: #e0f2f1 !important; }

.mdl-color-text--teal-100 {
  color: #b2dfdb !important; }

.mdl-color--teal-100 {
  background-color: #b2dfdb !important; }

.mdl-color-text--teal-200 {
  color: #80cbc4 !important; }

.mdl-color--teal-200 {
  background-color: #80cbc4 !important; }

.mdl-color-text--teal-300 {
  color: #4db6ac !important; }

.mdl-color--teal-300 {
  background-color: #4db6ac !important; }

.mdl-color-text--teal-400 {
  color: #26a69a !important; }

.mdl-color--teal-400 {
  background-color: #26a69a !important; }

.mdl-color-text--teal-500 {
  color: #009688 !important; }

.mdl-color--teal-500 {
  background-color: #009688 !important; }

.mdl-color-text--teal-600 {
  color: #00897b !important; }

.mdl-color--teal-600 {
  background-color: #00897b !important; }

.mdl-color-text--teal-700 {
  color: #00796b !important; }

.mdl-color--teal-700 {
  background-color: #00796b !important; }

.mdl-color-text--teal-800 {
  color: #00695c !important; }

.mdl-color--teal-800 {
  background-color: #00695c !important; }

.mdl-color-text--teal-900 {
  color: #004d40 !important; }

.mdl-color--teal-900 {
  background-color: #004d40 !important; }

.mdl-color-text--teal-A100 {
  color: #a7ffeb !important; }

.mdl-color--teal-A100 {
  background-color: #a7ffeb !important; }

.mdl-color-text--teal-A200 {
  color: #64ffda !important; }

.mdl-color--teal-A200 {
  background-color: #64ffda !important; }

.mdl-color-text--teal-A400 {
  color: #1de9b6 !important; }

.mdl-color--teal-A400 {
  background-color: #1de9b6 !important; }

.mdl-color-text--teal-A700 {
  color: #00bfa5 !important; }

.mdl-color--teal-A700 {
  background-color: #00bfa5 !important; }

.mdl-color-text--green {
  color: #4caf50 !important; }

.mdl-color--green {
  background-color: #4caf50 !important; }

.mdl-color-text--green-50 {
  color: #e8f5e9 !important; }

.mdl-color--green-50 {
  background-color: #e8f5e9 !important; }

.mdl-color-text--green-100 {
  color: #c8e6c9 !important; }

.mdl-color--green-100 {
  background-color: #c8e6c9 !important; }

.mdl-color-text--green-200 {
  color: #a5d6a7 !important; }

.mdl-color--green-200 {
  background-color: #a5d6a7 !important; }

.mdl-color-text--green-300 {
  color: #81c784 !important; }

.mdl-color--green-300 {
  background-color: #81c784 !important; }

.mdl-color-text--green-400 {
  color: #66bb6a !important; }

.mdl-color--green-400 {
  background-color: #66bb6a !important; }

.mdl-color-text--green-500 {
  color: #4caf50 !important; }

.mdl-color--green-500 {
  background-color: #4caf50 !important; }

.mdl-color-text--green-600 {
  color: #43a047 !important; }

.mdl-color--green-600 {
  background-color: #43a047 !important; }

.mdl-color-text--green-700 {
  color: #388e3c !important; }

.mdl-color--green-700 {
  background-color: #388e3c !important; }

.mdl-color-text--green-800 {
  color: #2e7d32 !important; }

.mdl-color--green-800 {
  background-color: #2e7d32 !important; }

.mdl-color-text--green-900 {
  color: #1b5e20 !important; }

.mdl-color--green-900 {
  background-color: #1b5e20 !important; }

.mdl-color-text--green-A100 {
  color: #b9f6ca !important; }

.mdl-color--green-A100 {
  background-color: #b9f6ca !important; }

.mdl-color-text--green-A200 {
  color: #69f0ae !important; }

.mdl-color--green-A200 {
  background-color: #69f0ae !important; }

.mdl-color-text--green-A400 {
  color: #00e676 !important; }

.mdl-color--green-A400 {
  background-color: #00e676 !important; }

.mdl-color-text--green-A700 {
  color: #00c853 !important; }

.mdl-color--green-A700 {
  background-color: #00c853 !important; }

.mdl-color-text--light-green {
  color: #8bc34a !important; }

.mdl-color--light-green {
  background-color: #8bc34a !important; }

.mdl-color-text--light-green-50 {
  color: #f1f8e9 !important; }

.mdl-color--light-green-50 {
  background-color: #f1f8e9 !important; }

.mdl-color-text--light-green-100 {
  color: #dcedc8 !important; }

.mdl-color--light-green-100 {
  background-color: #dcedc8 !important; }

.mdl-color-text--light-green-200 {
  color: #c5e1a5 !important; }

.mdl-color--light-green-200 {
  background-color: #c5e1a5 !important; }

.mdl-color-text--light-green-300 {
  color: #aed581 !important; }

.mdl-color--light-green-300 {
  background-color: #aed581 !important; }

.mdl-color-text--light-green-400 {
  color: #9ccc65 !important; }

.mdl-color--light-green-400 {
  background-color: #9ccc65 !important; }

.mdl-color-text--light-green-500 {
  color: #8bc34a !important; }

.mdl-color--light-green-500 {
  background-color: #8bc34a !important; }

.mdl-color-text--light-green-600 {
  color: #7cb342 !important; }

.mdl-color--light-green-600 {
  background-color: #7cb342 !important; }

.mdl-color-text--light-green-700 {
  color: #689f38 !important; }

.mdl-color--light-green-700 {
  background-color: #689f38 !important; }

.mdl-color-text--light-green-800 {
  color: #558b2f !important; }

.mdl-color--light-green-800 {
  background-color: #558b2f !important; }

.mdl-color-text--light-green-900 {
  color: #33691e !important; }

.mdl-color--light-green-900 {
  background-color: #33691e !important; }

.mdl-color-text--light-green-A100 {
  color: #ccff90 !important; }

.mdl-color--light-green-A100 {
  background-color: #ccff90 !important; }

.mdl-color-text--light-green-A200 {
  color: #b2ff59 !important; }

.mdl-color--light-green-A200 {
  background-color: #b2ff59 !important; }

.mdl-color-text--light-green-A400 {
  color: #76ff03 !important; }

.mdl-color--light-green-A400 {
  background-color: #76ff03 !important; }

.mdl-color-text--light-green-A700 {
  color: #64dd17 !important; }

.mdl-color--light-green-A700 {
  background-color: #64dd17 !important; }

.mdl-color-text--lime {
  color: #cddc39 !important; }

.mdl-color--lime {
  background-color: #cddc39 !important; }

.mdl-color-text--lime-50 {
  color: #f9fbe7 !important; }

.mdl-color--lime-50 {
  background-color: #f9fbe7 !important; }

.mdl-color-text--lime-100 {
  color: #f0f4c3 !important; }

.mdl-color--lime-100 {
  background-color: #f0f4c3 !important; }

.mdl-color-text--lime-200 {
  color: #e6ee9c !important; }

.mdl-color--lime-200 {
  background-color: #e6ee9c !important; }

.mdl-color-text--lime-300 {
  color: #dce775 !important; }

.mdl-color--lime-300 {
  background-color: #dce775 !important; }

.mdl-color-text--lime-400 {
  color: #d4e157 !important; }

.mdl-color--lime-400 {
  background-color: #d4e157 !important; }

.mdl-color-text--lime-500 {
  color: #cddc39 !important; }

.mdl-color--lime-500 {
  background-color: #cddc39 !important; }

.mdl-color-text--lime-600 {
  color: #c0ca33 !important; }

.mdl-color--lime-600 {
  background-color: #c0ca33 !important; }

.mdl-color-text--lime-700 {
  color: #afb42b !important; }

.mdl-color--lime-700 {
  background-color: #afb42b !important; }

.mdl-color-text--lime-800 {
  color: #9e9d24 !important; }

.mdl-color--lime-800 {
  background-color: #9e9d24 !important; }

.mdl-color-text--lime-900 {
  color: #827717 !important; }

.mdl-color--lime-900 {
  background-color: #827717 !important; }

.mdl-color-text--lime-A100 {
  color: #f4ff81 !important; }

.mdl-color--lime-A100 {
  background-color: #f4ff81 !important; }

.mdl-color-text--lime-A200 {
  color: #eeff41 !important; }

.mdl-color--lime-A200 {
  background-color: #eeff41 !important; }

.mdl-color-text--lime-A400 {
  color: #c6ff00 !important; }

.mdl-color--lime-A400 {
  background-color: #c6ff00 !important; }

.mdl-color-text--lime-A700 {
  color: #aeea00 !important; }

.mdl-color--lime-A700 {
  background-color: #aeea00 !important; }

.mdl-color-text--yellow {
  color: #ffeb3b !important; }

.mdl-color--yellow {
  background-color: #ffeb3b !important; }

.mdl-color-text--yellow-50 {
  color: #fffde7 !important; }

.mdl-color--yellow-50 {
  background-color: #fffde7 !important; }

.mdl-color-text--yellow-100 {
  color: #fff9c4 !important; }

.mdl-color--yellow-100 {
  background-color: #fff9c4 !important; }

.mdl-color-text--yellow-200 {
  color: #fff59d !important; }

.mdl-color--yellow-200 {
  background-color: #fff59d !important; }

.mdl-color-text--yellow-300 {
  color: #fff176 !important; }

.mdl-color--yellow-300 {
  background-color: #fff176 !important; }

.mdl-color-text--yellow-400 {
  color: #ffee58 !important; }

.mdl-color--yellow-400 {
  background-color: #ffee58 !important; }

.mdl-color-text--yellow-500 {
  color: #ffeb3b !important; }

.mdl-color--yellow-500 {
  background-color: #ffeb3b !important; }

.mdl-color-text--yellow-600 {
  color: #fdd835 !important; }

.mdl-color--yellow-600 {
  background-color: #fdd835 !important; }

.mdl-color-text--yellow-700 {
  color: #fbc02d !important; }

.mdl-color--yellow-700 {
  background-color: #fbc02d !important; }

.mdl-color-text--yellow-800 {
  color: #f9a825 !important; }

.mdl-color--yellow-800 {
  background-color: #f9a825 !important; }

.mdl-color-text--yellow-900 {
  color: #f57f17 !important; }

.mdl-color--yellow-900 {
  background-color: #f57f17 !important; }

.mdl-color-text--yellow-A100 {
  color: #ffff8d !important; }

.mdl-color--yellow-A100 {
  background-color: #ffff8d !important; }

.mdl-color-text--yellow-A200 {
  color: #ff0 !important; }

.mdl-color--yellow-A200 {
  background-color: #ff0 !important; }

.mdl-color-text--yellow-A400 {
  color: #ffea00 !important; }

.mdl-color--yellow-A400 {
  background-color: #ffea00 !important; }

.mdl-color-text--yellow-A700 {
  color: #ffd600 !important; }

.mdl-color--yellow-A700 {
  background-color: #ffd600 !important; }

.mdl-color-text--amber {
  color: #ffc107 !important; }

.mdl-color--amber {
  background-color: #ffc107 !important; }

.mdl-color-text--amber-50 {
  color: #fff8e1 !important; }

.mdl-color--amber-50 {
  background-color: #fff8e1 !important; }

.mdl-color-text--amber-100 {
  color: #ffecb3 !important; }

.mdl-color--amber-100 {
  background-color: #ffecb3 !important; }

.mdl-color-text--amber-200 {
  color: #ffe082 !important; }

.mdl-color--amber-200 {
  background-color: #ffe082 !important; }

.mdl-color-text--amber-300 {
  color: #ffd54f !important; }

.mdl-color--amber-300 {
  background-color: #ffd54f !important; }

.mdl-color-text--amber-400 {
  color: #ffca28 !important; }

.mdl-color--amber-400 {
  background-color: #ffca28 !important; }

.mdl-color-text--amber-500 {
  color: #ffc107 !important; }

.mdl-color--amber-500 {
  background-color: #ffc107 !important; }

.mdl-color-text--amber-600 {
  color: #ffb300 !important; }

.mdl-color--amber-600 {
  background-color: #ffb300 !important; }

.mdl-color-text--amber-700 {
  color: #ffa000 !important; }

.mdl-color--amber-700 {
  background-color: #ffa000 !important; }

.mdl-color-text--amber-800 {
  color: #ff8f00 !important; }

.mdl-color--amber-800 {
  background-color: #ff8f00 !important; }

.mdl-color-text--amber-900 {
  color: #ff6f00 !important; }

.mdl-color--amber-900 {
  background-color: #ff6f00 !important; }

.mdl-color-text--amber-A100 {
  color: #ffe57f !important; }

.mdl-color--amber-A100 {
  background-color: #ffe57f !important; }

.mdl-color-text--amber-A200 {
  color: #ffd740 !important; }

.mdl-color--amber-A200 {
  background-color: #ffd740 !important; }

.mdl-color-text--amber-A400 {
  color: #ffc400 !important; }

.mdl-color--amber-A400 {
  background-color: #ffc400 !important; }

.mdl-color-text--amber-A700 {
  color: #ffab00 !important; }

.mdl-color--amber-A700 {
  background-color: #ffab00 !important; }

.mdl-color-text--orange {
  color: #ff9800 !important; }

.mdl-color--orange {
  background-color: #ff9800 !important; }

.mdl-color-text--orange-50 {
  color: #fff3e0 !important; }

.mdl-color--orange-50 {
  background-color: #fff3e0 !important; }

.mdl-color-text--orange-100 {
  color: #ffe0b2 !important; }

.mdl-color--orange-100 {
  background-color: #ffe0b2 !important; }

.mdl-color-text--orange-200 {
  color: #ffcc80 !important; }

.mdl-color--orange-200 {
  background-color: #ffcc80 !important; }

.mdl-color-text--orange-300 {
  color: #ffb74d !important; }

.mdl-color--orange-300 {
  background-color: #ffb74d !important; }

.mdl-color-text--orange-400 {
  color: #ffa726 !important; }

.mdl-color--orange-400 {
  background-color: #ffa726 !important; }

.mdl-color-text--orange-500 {
  color: #ff9800 !important; }

.mdl-color--orange-500 {
  background-color: #ff9800 !important; }

.mdl-color-text--orange-600 {
  color: #fb8c00 !important; }

.mdl-color--orange-600 {
  background-color: #fb8c00 !important; }

.mdl-color-text--orange-700 {
  color: #f57c00 !important; }

.mdl-color--orange-700 {
  background-color: #f57c00 !important; }

.mdl-color-text--orange-800 {
  color: #ef6c00 !important; }

.mdl-color--orange-800 {
  background-color: #ef6c00 !important; }

.mdl-color-text--orange-900 {
  color: #e65100 !important; }

.mdl-color--orange-900 {
  background-color: #e65100 !important; }

.mdl-color-text--orange-A100 {
  color: #ffd180 !important; }

.mdl-color--orange-A100 {
  background-color: #ffd180 !important; }

.mdl-color-text--orange-A200 {
  color: #ffab40 !important; }

.mdl-color--orange-A200 {
  background-color: #ffab40 !important; }

.mdl-color-text--orange-A400 {
  color: #ff9100 !important; }

.mdl-color--orange-A400 {
  background-color: #ff9100 !important; }

.mdl-color-text--orange-A700 {
  color: #ff6d00 !important; }

.mdl-color--orange-A700 {
  background-color: #ff6d00 !important; }

.mdl-color-text--deep-orange {
  color: #ff5722 !important; }

.mdl-color--deep-orange {
  background-color: #ff5722 !important; }

.mdl-color-text--deep-orange-50 {
  color: #fbe9e7 !important; }

.mdl-color--deep-orange-50 {
  background-color: #fbe9e7 !important; }

.mdl-color-text--deep-orange-100 {
  color: #ffccbc !important; }

.mdl-color--deep-orange-100 {
  background-color: #ffccbc !important; }

.mdl-color-text--deep-orange-200 {
  color: #ffab91 !important; }

.mdl-color--deep-orange-200 {
  background-color: #ffab91 !important; }

.mdl-color-text--deep-orange-300 {
  color: #ff8a65 !important; }

.mdl-color--deep-orange-300 {
  background-color: #ff8a65 !important; }

.mdl-color-text--deep-orange-400 {
  color: #ff7043 !important; }

.mdl-color--deep-orange-400 {
  background-color: #ff7043 !important; }

.mdl-color-text--deep-orange-500 {
  color: #ff5722 !important; }

.mdl-color--deep-orange-500 {
  background-color: #ff5722 !important; }

.mdl-color-text--deep-orange-600 {
  color: #f4511e !important; }

.mdl-color--deep-orange-600 {
  background-color: #f4511e !important; }

.mdl-color-text--deep-orange-700 {
  color: #e64a19 !important; }

.mdl-color--deep-orange-700 {
  background-color: #e64a19 !important; }

.mdl-color-text--deep-orange-800 {
  color: #d84315 !important; }

.mdl-color--deep-orange-800 {
  background-color: #d84315 !important; }

.mdl-color-text--deep-orange-900 {
  color: #bf360c !important; }

.mdl-color--deep-orange-900 {
  background-color: #bf360c !important; }

.mdl-color-text--deep-orange-A100 {
  color: #ff9e80 !important; }

.mdl-color--deep-orange-A100 {
  background-color: #ff9e80 !important; }

.mdl-color-text--deep-orange-A200 {
  color: #ff6e40 !important; }

.mdl-color--deep-orange-A200 {
  background-color: #ff6e40 !important; }

.mdl-color-text--deep-orange-A400 {
  color: #ff3d00 !important; }

.mdl-color--deep-orange-A400 {
  background-color: #ff3d00 !important; }

.mdl-color-text--deep-orange-A700 {
  color: #dd2c00 !important; }

.mdl-color--deep-orange-A700 {
  background-color: #dd2c00 !important; }

.mdl-color-text--brown {
  color: #795548 !important; }

.mdl-color--brown {
  background-color: #795548 !important; }

.mdl-color-text--brown-50 {
  color: #efebe9 !important; }

.mdl-color--brown-50 {
  background-color: #efebe9 !important; }

.mdl-color-text--brown-100 {
  color: #d7ccc8 !important; }

.mdl-color--brown-100 {
  background-color: #d7ccc8 !important; }

.mdl-color-text--brown-200 {
  color: #bcaaa4 !important; }

.mdl-color--brown-200 {
  background-color: #bcaaa4 !important; }

.mdl-color-text--brown-300 {
  color: #a1887f !important; }

.mdl-color--brown-300 {
  background-color: #a1887f !important; }

.mdl-color-text--brown-400 {
  color: #8d6e63 !important; }

.mdl-color--brown-400 {
  background-color: #8d6e63 !important; }

.mdl-color-text--brown-500 {
  color: #795548 !important; }

.mdl-color--brown-500 {
  background-color: #795548 !important; }

.mdl-color-text--brown-600 {
  color: #6d4c41 !important; }

.mdl-color--brown-600 {
  background-color: #6d4c41 !important; }

.mdl-color-text--brown-700 {
  color: #5d4037 !important; }

.mdl-color--brown-700 {
  background-color: #5d4037 !important; }

.mdl-color-text--brown-800 {
  color: #4e342e !important; }

.mdl-color--brown-800 {
  background-color: #4e342e !important; }

.mdl-color-text--brown-900 {
  color: #3e2723 !important; }

.mdl-color--brown-900 {
  background-color: #3e2723 !important; }

.mdl-color-text--grey {
  color: #9e9e9e !important; }

.mdl-color--grey {
  background-color: #9e9e9e !important; }

.mdl-color-text--grey-50 {
  color: #fafafa !important; }

.mdl-color--grey-50 {
  background-color: #fafafa !important; }

.mdl-color-text--grey-100 {
  color: #f5f5f5 !important; }

.mdl-color--grey-100 {
  background-color: #f5f5f5 !important; }

.mdl-color-text--grey-200 {
  color: #eee !important; }

.mdl-color--grey-200 {
  background-color: #eee !important; }

.mdl-color-text--grey-300 {
  color: #e0e0e0 !important; }

.mdl-color--grey-300 {
  background-color: #e0e0e0 !important; }

.mdl-color-text--grey-400 {
  color: #bdbdbd !important; }

.mdl-color--grey-400 {
  background-color: #bdbdbd !important; }

.mdl-color-text--grey-500 {
  color: #9e9e9e !important; }

.mdl-color--grey-500 {
  background-color: #9e9e9e !important; }

.mdl-color-text--grey-600 {
  color: #757575 !important; }

.mdl-color--grey-600 {
  background-color: #757575 !important; }

.mdl-color-text--grey-700 {
  color: #616161 !important; }

.mdl-color--grey-700 {
  background-color: #616161 !important; }

.mdl-color-text--grey-800 {
  color: #424242 !important; }

.mdl-color--grey-800 {
  background-color: #424242 !important; }

.mdl-color-text--grey-900 {
  color: #212121 !important; }

.mdl-color--grey-900 {
  background-color: #212121 !important; }

.mdl-color-text--blue-grey {
  color: #607d8b !important; }

.mdl-color--blue-grey {
  background-color: #607d8b !important; }

.mdl-color-text--blue-grey-50 {
  color: #eceff1 !important; }

.mdl-color--blue-grey-50 {
  background-color: #eceff1 !important; }

.mdl-color-text--blue-grey-100 {
  color: #cfd8dc !important; }

.mdl-color--blue-grey-100 {
  background-color: #cfd8dc !important; }

.mdl-color-text--blue-grey-200 {
  color: #b0bec5 !important; }

.mdl-color--blue-grey-200 {
  background-color: #b0bec5 !important; }

.mdl-color-text--blue-grey-300 {
  color: #90a4ae !important; }

.mdl-color--blue-grey-300 {
  background-color: #90a4ae !important; }

.mdl-color-text--blue-grey-400 {
  color: #78909c !important; }

.mdl-color--blue-grey-400 {
  background-color: #78909c !important; }

.mdl-color-text--blue-grey-500 {
  color: #607d8b !important; }

.mdl-color--blue-grey-500 {
  background-color: #607d8b !important; }

.mdl-color-text--blue-grey-600 {
  color: #546e7a !important; }

.mdl-color--blue-grey-600 {
  background-color: #546e7a !important; }

.mdl-color-text--blue-grey-700 {
  color: #455a64 !important; }

.mdl-color--blue-grey-700 {
  background-color: #455a64 !important; }

.mdl-color-text--blue-grey-800 {
  color: #37474f !important; }

.mdl-color--blue-grey-800 {
  background-color: #37474f !important; }

.mdl-color-text--blue-grey-900 {
  color: #263238 !important; }

.mdl-color--blue-grey-900 {
  background-color: #263238 !important; }

.mdl-color--black {
  background-color: #000 !important; }

.mdl-color-text--black {
  color: #000 !important; }

.mdl-color--white {
  background-color: #fff !important; }

.mdl-color-text--white {
  color: #fff !important; }

.mdl-color--primary {
  background-color: #ffeb3b !important; }

.mdl-color--primary-contrast {
  background-color: #424242 !important; }

.mdl-color--primary-dark {
  background-color: #fbc02d !important; }

.mdl-color--accent {
  background-color: #448aff !important; }

.mdl-color--accent-contrast {
  background-color: white !important; }

.mdl-color-text--primary {
  color: #ffeb3b !important; }

.mdl-color-text--primary-contrast {
  color: #424242 !important; }

.mdl-color-text--primary-dark {
  color: #fbc02d !important; }

.mdl-color-text--accent {
  color: #448aff !important; }

.mdl-color-text--accent-contrast {
  color: white !important; }

.mdl-ripple {
  background: #000;
  border-radius: 50%;
  height: 50px;
  left: 0;
  opacity: 0.001;
  pointer-events: none;
  position: absolute;
  top: 0;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 50px;
  overflow: hidden; }

.mdl-ripple.is-animating {
  transition: transform 0.3s cubic-bezier(0, 0, 0.2, 1), width 0.3s cubic-bezier(0, 0, 0.2, 1), height 0.3s cubic-bezier(0, 0, 0.2, 1), opacity 0.6s cubic-bezier(0, 0, 0.2, 1);
  transition: transform 0.3s cubic-bezier(0, 0, 0.2, 1), width 0.3s cubic-bezier(0, 0, 0.2, 1), height 0.3s cubic-bezier(0, 0, 0.2, 1), opacity 0.6s cubic-bezier(0, 0, 0.2, 1), -webkit-transform 0.3s cubic-bezier(0, 0, 0.2, 1); }

.mdl-ripple.is-visible {
  opacity: .3; }

.mdl-animation--default,
.mdl-animation--fast-out-slow-in {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1); }

.mdl-animation--linear-out-slow-in {
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1); }

.mdl-animation--fast-out-linear-in {
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1); }

.mdl-badge {
  position: relative;
  white-space: nowrap;
  margin-right: 24px; }

.mdl-badge:not([data-badge]) {
  margin-right: auto; }

.mdl-badge[data-badge]:after {
  content: attr(data-badge);
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  top: -11px;
  right: -24px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 600;
  font-size: 12px;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: #448aff;
  color: white; }
  .fontx--default .mdl-badge[data-badge]:after {
    font-size: 12px; }
  .fontx--x2 .mdl-badge[data-badge]:after {
    font-size: 14px; }

.mdl-button .mdl-badge[data-badge]:after {
  top: -10px;
  right: -5px; }

.mdl-badge.mdl-badge--no-background[data-badge]:after {
  color: #448aff;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 0 1px gray; }

.mdl-badge.mdl-badge--overlap {
  margin-right: 10px; }

.mdl-badge.mdl-badge--overlap:after {
  right: -10px; }

.mdl-button {
  background: 0 0;
  border: none;
  border-radius: 2px;
  color: #000;
  position: relative;
  height: 36px;
  margin: 0;
  min-width: 64px;
  padding: 0 16px;
  display: inline-block;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0;
  overflow: hidden;
  will-change: box-shadow;
  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1), color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  outline: none;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  line-height: 36px;
  vertical-align: middle; }
  .fontx--default .mdl-button {
    font-size: 14px; }
  .fontx--x2 .mdl-button {
    font-size: 16px; }

.mdl-button::-moz-focus-inner {
  border: 0; }

.mdl-button:hover {
  background-color: rgba(158, 158, 158, 0.2); }

.mdl-button:focus:not(:active) {
  background-color: rgba(0, 0, 0, 0.12); }

.mdl-button:active {
  background-color: rgba(158, 158, 158, 0.4); }

.mdl-button.mdl-button--colored {
  color: #ffeb3b; }

.mdl-button.mdl-button--colored:focus:not(:active) {
  background-color: rgba(0, 0, 0, 0.12); }

input.mdl-button[type="submit"] {
  -webkit-appearance: none; }

.mdl-button--raised {
  background: rgba(158, 158, 158, 0.2);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12); }

.mdl-button--raised:active {
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
  background-color: rgba(158, 158, 158, 0.4); }

.mdl-button--raised:focus:not(:active) {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.18), 0 8px 16px rgba(0, 0, 0, 0.36);
  background-color: rgba(158, 158, 158, 0.4); }

.mdl-button--raised.mdl-button--colored {
  background: #ffeb3b;
  color: #424242; }

.mdl-button--raised.mdl-button--colored:hover {
  background-color: #ffeb3b; }

.mdl-button--raised.mdl-button--colored:active {
  background-color: #ffeb3b; }

.mdl-button--raised.mdl-button--colored:focus:not(:active) {
  background-color: #ffeb3b; }

.mdl-button--raised.mdl-button--colored .mdl-ripple {
  background: #424242; }

.mdl-button--fab {
  border-radius: 50%;
  font-size: 24px;
  height: 56px;
  margin: auto;
  min-width: 56px;
  width: 56px;
  padding: 0;
  overflow: hidden;
  background: rgba(158, 158, 158, 0.2);
  box-shadow: 0 1px 1.5px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.24);
  position: relative;
  line-height: normal; }
  .fontx--default .mdl-button--fab {
    font-size: 24px; }
  .fontx--x2 .mdl-button--fab {
    font-size: 26px; }

.mdl-button--fab .material-icons {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-12px, -12px);
  transform: translate(-12px, -12px);
  line-height: 24px;
  width: 24px; }

.mdl-button--fab.mdl-button--mini-fab {
  height: 40px;
  min-width: 40px;
  width: 40px; }

.mdl-button--fab .mdl-button__ripple-container {
  border-radius: 50%;
  -webkit-mask-image: -webkit-radial-gradient(circle, #fff, #000); }

.mdl-button--fab:active {
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
  background-color: rgba(158, 158, 158, 0.4); }

.mdl-button--fab:focus:not(:active) {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.18), 0 8px 16px rgba(0, 0, 0, 0.36);
  background-color: rgba(158, 158, 158, 0.4); }

.mdl-button--fab.mdl-button--colored {
  background: #448aff;
  color: white; }

.mdl-button--fab.mdl-button--colored:hover {
  background-color: #448aff; }

.mdl-button--fab.mdl-button--colored:focus:not(:active) {
  background-color: #448aff; }

.mdl-button--fab.mdl-button--colored:active {
  background-color: #448aff; }

.mdl-button--fab.mdl-button--colored .mdl-ripple {
  background: white; }

.mdl-button--icon {
  border-radius: 50%;
  font-size: 24px;
  height: 32px;
  margin-left: 0;
  margin-right: 0;
  min-width: 32px;
  width: 32px;
  padding: 0;
  overflow: hidden;
  color: inherit;
  line-height: normal; }
  .fontx--default .mdl-button--icon {
    font-size: 24px; }
  .fontx--x2 .mdl-button--icon {
    font-size: 26px; }

.mdl-button--icon .material-icons {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-12px, -12px);
  transform: translate(-12px, -12px);
  line-height: 24px;
  width: 24px; }

.mdl-button--icon.mdl-button--mini-icon {
  height: 24px;
  min-width: 24px;
  width: 24px; }

.mdl-button--icon.mdl-button--mini-icon .material-icons {
  top: 0;
  left: 0; }

.mdl-button--icon .mdl-button__ripple-container {
  border-radius: 50%;
  -webkit-mask-image: -webkit-radial-gradient(circle, #fff, #000); }

.mdl-button__ripple-container {
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
  overflow: hidden; }

.mdl-button[disabled] .mdl-button__ripple-container .mdl-ripple,
.mdl-button.mdl-button--disabled .mdl-button__ripple-container .mdl-ripple {
  background-color: transparent; }

.mdl-button--primary.mdl-button--primary {
  color: #ffeb3b; }

.mdl-button--primary.mdl-button--primary .mdl-ripple {
  background: #424242; }

.mdl-button--primary.mdl-button--primary.mdl-button--raised,
.mdl-button--primary.mdl-button--primary.mdl-button--fab {
  color: #424242;
  background-color: #ffeb3b; }

.mdl-button--accent.mdl-button--accent {
  color: #448aff; }

.mdl-button--accent.mdl-button--accent .mdl-ripple {
  background: white; }

.mdl-button--accent.mdl-button--accent.mdl-button--raised,
.mdl-button--accent.mdl-button--accent.mdl-button--fab {
  color: white;
  background-color: #448aff; }

.mdl-button[disabled][disabled],
.mdl-button.mdl-button--disabled.mdl-button--disabled {
  color: rgba(0, 0, 0, 0.26);
  cursor: default;
  background-color: transparent; }

.mdl-button--fab[disabled][disabled],
.mdl-button--fab.mdl-button--disabled.mdl-button--disabled {
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.26); }

.mdl-button--raised[disabled][disabled],
.mdl-button--raised.mdl-button--disabled.mdl-button--disabled {
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.26);
  box-shadow: none; }

.mdl-button--colored[disabled][disabled],
.mdl-button--colored.mdl-button--disabled.mdl-button--disabled {
  color: rgba(0, 0, 0, 0.26); }

.mdl-button .material-icons {
  vertical-align: middle; }

.mdl-card {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  font-size: 16px;
  font-weight: 400;
  min-height: 200px;
  overflow: hidden;
  width: 330px;
  z-index: 1;
  position: relative;
  background: #fff;
  border-radius: 2px;
  box-sizing: border-box; }
  .fontx--default .mdl-card {
    font-size: 16px; }
  .fontx--x2 .mdl-card {
    font-size: 18px; }

.mdl-card__media {
  background-color: #448aff;
  background-repeat: repeat;
  background-position: 50% 50%;
  background-size: cover;
  background-origin: padding-box;
  background-attachment: scroll;
  box-sizing: border-box; }

.mdl-card__title {
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #000;
  display: block;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: stretch;
  -ms-flex-pack: stretch;
  justify-content: stretch;
  line-height: normal;
  padding: 16px;
  -webkit-perspective-origin: 165px 56px;
  perspective-origin: 165px 56px;
  -webkit-transform-origin: 165px 56px;
  transform-origin: 165px 56px;
  box-sizing: border-box; }

.mdl-card__title.mdl-card--border {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); }

.mdl-card__title-text {
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
  color: inherit;
  display: block;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  font-size: 24px;
  font-weight: 300;
  line-height: normal;
  overflow: hidden;
  -webkit-transform-origin: 149px 48px;
  transform-origin: 149px 48px;
  margin: 0; }
  .fontx--default .mdl-card__title-text {
    font-size: 24px; }
  .fontx--x2 .mdl-card__title-text {
    font-size: 26px; }

.mdl-card__subtitle-text {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.54);
  margin: 0; }
  .fontx--default .mdl-card__subtitle-text {
    font-size: 14px; }
  .fontx--x2 .mdl-card__subtitle-text {
    font-size: 16px; }

.mdl-card__supporting-text {
  color: rgba(0, 0, 0, 0.54);
  font-size: 1rem;
  line-height: 18px;
  overflow: hidden;
  padding: 16px;
  width: 90%; }

.mdl-card__supporting-text.mdl-card--border {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); }

.mdl-card__actions {
  font-size: 16px;
  line-height: normal;
  width: 100%;
  background-color: transparent;
  padding: 8px;
  box-sizing: border-box; }
  .fontx--default .mdl-card__actions {
    font-size: 16px; }
  .fontx--x2 .mdl-card__actions {
    font-size: 18px; }

.mdl-card__actions.mdl-card--border {
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

.mdl-card--expand {
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1; }

.mdl-card__menu {
  position: absolute;
  right: 16px;
  top: 16px; }

.mdl-checkbox {
  position: relative;
  z-index: 1;
  vertical-align: middle;
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
  height: 24px;
  margin: 0;
  padding: 0; }

.mdl-checkbox.is-upgraded {
  padding-left: 24px; }

.mdl-checkbox__input {
  line-height: 24px; }

.mdl-checkbox.is-upgraded .mdl-checkbox__input {
  position: absolute;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
  opacity: 0;
  -ms-appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: none; }

.mdl-checkbox__box-outline {
  position: absolute;
  top: 3px;
  left: 0;
  display: inline-block;
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  margin: 0;
  cursor: pointer;
  overflow: hidden;
  border: 2px solid rgba(0, 0, 0, 0.54);
  border-radius: 2px;
  z-index: 2; }

.mdl-checkbox.is-checked .mdl-checkbox__box-outline {
  border: 2px solid #ffeb3b; }

fieldset[disabled] .mdl-checkbox .mdl-checkbox__box-outline,
.mdl-checkbox.is-disabled .mdl-checkbox__box-outline {
  border: 2px solid rgba(0, 0, 0, 0.26);
  cursor: auto; }

.mdl-checkbox__focus-helper {
  position: absolute;
  top: 3px;
  left: 0;
  display: inline-block;
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: transparent; }

.mdl-checkbox.is-focused .mdl-checkbox__focus-helper {
  box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.1);
  background-color: rgba(0, 0, 0, 0.1); }

.mdl-checkbox.is-focused.is-checked .mdl-checkbox__focus-helper {
  box-shadow: 0 0 0 8px rgba(255, 235, 59, 0.26);
  background-color: rgba(255, 235, 59, 0.26); }

.mdl-checkbox__tick-outline {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  -webkit-mask: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjxzdmcKICAgeG1sbnM6ZGM9Imh0dHA6Ly9wdXJsLm9yZy9kYy9lbGVtZW50cy8xLjEvIgogICB4bWxuczpjYz0iaHR0cDovL2NyZWF0aXZlY29tbW9ucy5vcmcvbnMjIgogICB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiCiAgIHhtbG5zOnN2Zz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciCiAgIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKICAgdmVyc2lvbj0iMS4xIgogICB2aWV3Qm94PSIwIDAgMSAxIgogICBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWluWU1pbiBtZWV0Ij4KICA8ZGVmcz4KICAgIDxjbGlwUGF0aCBpZD0iY2xpcCI+CiAgICAgIDxwYXRoCiAgICAgICAgIGQ9Ik0gMCwwIDAsMSAxLDEgMSwwIDAsMCB6IE0gMC44NTM0Mzc1LDAuMTY3MTg3NSAwLjk1OTY4NzUsMC4yNzMxMjUgMC40MjkzNzUsMC44MDM0Mzc1IDAuMzIzMTI1LDAuOTA5Njg3NSAwLjIxNzE4NzUsMC44MDM0Mzc1IDAuMDQwMzEyNSwwLjYyNjg3NSAwLjE0NjU2MjUsMC41MjA2MjUgMC4zMjMxMjUsMC42OTc1IDAuODUzNDM3NSwwLjE2NzE4NzUgeiIKICAgICAgICAgc3R5bGU9ImZpbGw6I2ZmZmZmZjtmaWxsLW9wYWNpdHk6MTtzdHJva2U6bm9uZSIgLz4KICAgIDwvY2xpcFBhdGg+CiAgICA8bWFzayBpZD0ibWFzayIgbWFza1VuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgbWFza0NvbnRlbnRVbml0cz0ib2JqZWN0Qm91bmRpbmdCb3giPgogICAgICA8cGF0aAogICAgICAgICBkPSJNIDAsMCAwLDEgMSwxIDEsMCAwLDAgeiBNIDAuODUzNDM3NSwwLjE2NzE4NzUgMC45NTk2ODc1LDAuMjczMTI1IDAuNDI5Mzc1LDAuODAzNDM3NSAwLjMyMzEyNSwwLjkwOTY4NzUgMC4yMTcxODc1LDAuODAzNDM3NSAwLjA0MDMxMjUsMC42MjY4NzUgMC4xNDY1NjI1LDAuNTIwNjI1IDAuMzIzMTI1LDAuNjk3NSAwLjg1MzQzNzUsMC4xNjcxODc1IHoiCiAgICAgICAgIHN0eWxlPSJmaWxsOiNmZmZmZmY7ZmlsbC1vcGFjaXR5OjE7c3Ryb2tlOm5vbmUiIC8+CiAgICA8L21hc2s+CiAgPC9kZWZzPgogIDxyZWN0CiAgICAgd2lkdGg9IjEiCiAgICAgaGVpZ2h0PSIxIgogICAgIHg9IjAiCiAgICAgeT0iMCIKICAgICBjbGlwLXBhdGg9InVybCgjY2xpcCkiCiAgICAgc3R5bGU9ImZpbGw6IzAwMDAwMDtmaWxsLW9wYWNpdHk6MTtzdHJva2U6bm9uZSIgLz4KPC9zdmc+Cg==");
  mask: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjxzdmcKICAgeG1sbnM6ZGM9Imh0dHA6Ly9wdXJsLm9yZy9kYy9lbGVtZW50cy8xLjEvIgogICB4bWxuczpjYz0iaHR0cDovL2NyZWF0aXZlY29tbW9ucy5vcmcvbnMjIgogICB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiCiAgIHhtbG5zOnN2Zz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciCiAgIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKICAgdmVyc2lvbj0iMS4xIgogICB2aWV3Qm94PSIwIDAgMSAxIgogICBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWluWU1pbiBtZWV0Ij4KICA8ZGVmcz4KICAgIDxjbGlwUGF0aCBpZD0iY2xpcCI+CiAgICAgIDxwYXRoCiAgICAgICAgIGQ9Ik0gMCwwIDAsMSAxLDEgMSwwIDAsMCB6IE0gMC44NTM0Mzc1LDAuMTY3MTg3NSAwLjk1OTY4NzUsMC4yNzMxMjUgMC40MjkzNzUsMC44MDM0Mzc1IDAuMzIzMTI1LDAuOTA5Njg3NSAwLjIxNzE4NzUsMC44MDM0Mzc1IDAuMDQwMzEyNSwwLjYyNjg3NSAwLjE0NjU2MjUsMC41MjA2MjUgMC4zMjMxMjUsMC42OTc1IDAuODUzNDM3NSwwLjE2NzE4NzUgeiIKICAgICAgICAgc3R5bGU9ImZpbGw6I2ZmZmZmZjtmaWxsLW9wYWNpdHk6MTtzdHJva2U6bm9uZSIgLz4KICAgIDwvY2xpcFBhdGg+CiAgICA8bWFzayBpZD0ibWFzayIgbWFza1VuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgbWFza0NvbnRlbnRVbml0cz0ib2JqZWN0Qm91bmRpbmdCb3giPgogICAgICA8cGF0aAogICAgICAgICBkPSJNIDAsMCAwLDEgMSwxIDEsMCAwLDAgeiBNIDAuODUzNDM3NSwwLjE2NzE4NzUgMC45NTk2ODc1LDAuMjczMTI1IDAuNDI5Mzc1LDAuODAzNDM3NSAwLjMyMzEyNSwwLjkwOTY4NzUgMC4yMTcxODc1LDAuODAzNDM3NSAwLjA0MDMxMjUsMC42MjY4NzUgMC4xNDY1NjI1LDAuNTIwNjI1IDAuMzIzMTI1LDAuNjk3NSAwLjg1MzQzNzUsMC4xNjcxODc1IHoiCiAgICAgICAgIHN0eWxlPSJmaWxsOiNmZmZmZmY7ZmlsbC1vcGFjaXR5OjE7c3Ryb2tlOm5vbmUiIC8+CiAgICA8L21hc2s+CiAgPC9kZWZzPgogIDxyZWN0CiAgICAgd2lkdGg9IjEiCiAgICAgaGVpZ2h0PSIxIgogICAgIHg9IjAiCiAgICAgeT0iMCIKICAgICBjbGlwLXBhdGg9InVybCgjY2xpcCkiCiAgICAgc3R5bGU9ImZpbGw6IzAwMDAwMDtmaWxsLW9wYWNpdHk6MTtzdHJva2U6bm9uZSIgLz4KPC9zdmc+Cg==");
  background: 0 0;
  transition-duration: .28s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: background; }

.mdl-checkbox.is-checked .mdl-checkbox__tick-outline {
  background: #ffeb3b url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjxzdmcKICAgeG1sbnM6ZGM9Imh0dHA6Ly9wdXJsLm9yZy9kYy9lbGVtZW50cy8xLjEvIgogICB4bWxuczpjYz0iaHR0cDovL2NyZWF0aXZlY29tbW9ucy5vcmcvbnMjIgogICB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiCiAgIHhtbG5zOnN2Zz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciCiAgIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKICAgdmVyc2lvbj0iMS4xIgogICB2aWV3Qm94PSIwIDAgMSAxIgogICBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWluWU1pbiBtZWV0Ij4KICA8cGF0aAogICAgIGQ9Ik0gMC4wNDAzODA1OSwwLjYyNjc3NjcgMC4xNDY0NDY2MSwwLjUyMDcxMDY4IDAuNDI5Mjg5MzIsMC44MDM1NTMzOSAwLjMyMzIyMzMsMC45MDk2MTk0MSB6IE0gMC4yMTcxNTcyOSwwLjgwMzU1MzM5IDAuODUzNTUzMzksMC4xNjcxNTcyOSAwLjk1OTYxOTQxLDAuMjczMjIzMyAwLjMyMzIyMzMsMC45MDk2MTk0MSB6IgogICAgIGlkPSJyZWN0Mzc4MCIKICAgICBzdHlsZT0iZmlsbDojZmZmZmZmO2ZpbGwtb3BhY2l0eToxO3N0cm9rZTpub25lIiAvPgo8L3N2Zz4K"); }

fieldset[disabled] .mdl-checkbox.is-checked .mdl-checkbox__tick-outline,
.mdl-checkbox.is-checked.is-disabled .mdl-checkbox__tick-outline {
  background: rgba(0, 0, 0, 0.26) url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjxzdmcKICAgeG1sbnM6ZGM9Imh0dHA6Ly9wdXJsLm9yZy9kYy9lbGVtZW50cy8xLjEvIgogICB4bWxuczpjYz0iaHR0cDovL2NyZWF0aXZlY29tbW9ucy5vcmcvbnMjIgogICB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiCiAgIHhtbG5zOnN2Zz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciCiAgIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKICAgdmVyc2lvbj0iMS4xIgogICB2aWV3Qm94PSIwIDAgMSAxIgogICBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWluWU1pbiBtZWV0Ij4KICA8cGF0aAogICAgIGQ9Ik0gMC4wNDAzODA1OSwwLjYyNjc3NjcgMC4xNDY0NDY2MSwwLjUyMDcxMDY4IDAuNDI5Mjg5MzIsMC44MDM1NTMzOSAwLjMyMzIyMzMsMC45MDk2MTk0MSB6IE0gMC4yMTcxNTcyOSwwLjgwMzU1MzM5IDAuODUzNTUzMzksMC4xNjcxNTcyOSAwLjk1OTYxOTQxLDAuMjczMjIzMyAwLjMyMzIyMzMsMC45MDk2MTk0MSB6IgogICAgIGlkPSJyZWN0Mzc4MCIKICAgICBzdHlsZT0iZmlsbDojZmZmZmZmO2ZpbGwtb3BhY2l0eToxO3N0cm9rZTpub25lIiAvPgo8L3N2Zz4K"); }

.mdl-checkbox__label {
  position: relative;
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;
  margin: 0; }
  .fontx--default .mdl-checkbox__label {
    font-size: 16px; }
  .fontx--x2 .mdl-checkbox__label {
    font-size: 18px; }

fieldset[disabled] .mdl-checkbox .mdl-checkbox__label,
.mdl-checkbox.is-disabled .mdl-checkbox__label {
  color: rgba(0, 0, 0, 0.26);
  cursor: auto; }

.mdl-checkbox__ripple-container {
  position: absolute;
  z-index: 2;
  top: -6px;
  left: -10px;
  box-sizing: border-box;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  cursor: pointer;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(circle, #fff, #000); }

.mdl-checkbox__ripple-container .mdl-ripple {
  background: #ffeb3b; }

fieldset[disabled] .mdl-checkbox .mdl-checkbox__ripple-container,
.mdl-checkbox.is-disabled .mdl-checkbox__ripple-container {
  cursor: auto; }

fieldset[disabled] .mdl-checkbox .mdl-checkbox__ripple-container .mdl-ripple,
.mdl-checkbox.is-disabled .mdl-checkbox__ripple-container .mdl-ripple {
  background: 0 0; }

.mdl-chip {
  height: 32px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 32px;
  padding: 0 12px;
  border: 0;
  border-radius: 16px;
  background-color: #dedede;
  display: inline-block;
  color: rgba(0, 0, 0, 0.87);
  margin: 2px 0;
  font-size: 0;
  white-space: nowrap; }

.mdl-chip__text {
  font-size: 13px;
  vertical-align: middle;
  display: inline-block; }

.mdl-chip__action {
  height: 24px;
  width: 24px;
  background: 0 0;
  opacity: .54;
  cursor: pointer;
  padding: 0;
  margin: 0 0 0 4px;
  font-size: 13px;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
  border: none;
  outline: none; }

.mdl-chip__action,
.mdl-chip__contact {
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  text-align: center; }

.mdl-chip__contact {
  height: 32px;
  width: 32px;
  border-radius: 16px;
  margin-right: 8px;
  font-size: 18px;
  line-height: 32px; }

.mdl-chip:focus {
  outline: 0;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12); }

.mdl-chip:active {
  background-color: #d6d6d6; }

.mdl-chip--deletable {
  padding-right: 4px; }

.mdl-chip--contact {
  padding-left: 0; }

.mdl-data-table {
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-collapse: collapse;
  white-space: nowrap;
  font-size: 13px;
  background-color: #fff; }

.mdl-data-table thead {
  padding-bottom: 3px; }

.mdl-data-table thead .mdl-data-table__select {
  margin-top: 0; }

.mdl-data-table tbody tr {
  position: relative;
  height: 48px;
  transition-duration: .28s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: background-color; }

.mdl-data-table tbody tr.is-selected {
  background-color: #e0e0e0; }

.mdl-data-table tbody tr:hover {
  background-color: #eee; }

.mdl-data-table td {
  text-align: right; }

.mdl-data-table th {
  padding: 0 18px 12px 18px;
  text-align: right; }

.mdl-data-table td:first-of-type,
.mdl-data-table th:first-of-type {
  padding-left: 24px; }

.mdl-data-table td:last-of-type,
.mdl-data-table th:last-of-type {
  padding-right: 24px; }

.mdl-data-table td {
  position: relative;
  height: 48px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding: 12px 18px;
  box-sizing: border-box; }

.mdl-data-table td,
.mdl-data-table td .mdl-data-table__select {
  vertical-align: middle; }

.mdl-data-table th {
  position: relative;
  vertical-align: bottom;
  text-overflow: ellipsis;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0;
  height: 48px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.54);
  padding-bottom: 8px;
  box-sizing: border-box; }
  .fontx--default .mdl-data-table th {
    font-size: 12px; }
  .fontx--x2 .mdl-data-table th {
    font-size: 14px; }

.mdl-data-table th.mdl-data-table__header--sorted-ascending,
.mdl-data-table th.mdl-data-table__header--sorted-descending {
  color: rgba(0, 0, 0, 0.87); }

.mdl-data-table th.mdl-data-table__header--sorted-ascending:before,
.mdl-data-table th.mdl-data-table__header--sorted-descending:before {
  font-family: 'Material Icons';
  font-weight: 400;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  word-wrap: normal;
  -moz-font-feature-settings: 'liga';
  font-feature-settings: 'liga';
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  content: "\e5d8";
  margin-right: 5px;
  vertical-align: sub; }
  .fontx--default .mdl-data-table th.mdl-data-table__header--sorted-ascending:before, .fontx--default
  .mdl-data-table th.mdl-data-table__header--sorted-descending:before {
    font-size: 16px; }
  .fontx--x2 .mdl-data-table th.mdl-data-table__header--sorted-ascending:before, .fontx--x2
  .mdl-data-table th.mdl-data-table__header--sorted-descending:before {
    font-size: 18px; }

.mdl-data-table th.mdl-data-table__header--sorted-ascending:hover,
.mdl-data-table th.mdl-data-table__header--sorted-descending:hover {
  cursor: pointer; }

.mdl-data-table th.mdl-data-table__header--sorted-ascending:hover:before,
.mdl-data-table th.mdl-data-table__header--sorted-descending:hover:before {
  color: rgba(0, 0, 0, 0.26); }

.mdl-data-table th.mdl-data-table__header--sorted-descending:before {
  content: "\e5db"; }

.mdl-data-table__select {
  width: 16px; }

.mdl-data-table__cell--non-numeric.mdl-data-table__cell--non-numeric {
  text-align: left; }

.mdl-dialog {
  border: none;
  box-shadow: 0 9px 46px 8px rgba(0, 0, 0, 0.14), 0 11px 15px -7px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.2);
  width: 280px; }

.mdl-dialog__title {
  padding: 24px 24px 0;
  margin: 0;
  font-size: 2.5rem; }

.mdl-dialog__actions {
  padding: 8px 8px 8px 24px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

.mdl-dialog__actions > * {
  margin-right: 8px;
  height: 36px; }

.mdl-dialog__actions > *:first-child {
  margin-right: 0; }

.mdl-dialog__actions--full-width {
  padding: 0 0 8px; }

.mdl-dialog__actions--full-width > * {
  height: 48px;
  -webkit-flex: 0 0 100%;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  padding-right: 16px;
  margin-right: 0;
  text-align: right; }

.mdl-dialog__content {
  padding: 20px 24px 24px;
  color: rgba(0, 0, 0, 0.54); }

.mdl-mega-footer {
  padding: 16px 40px;
  color: #9e9e9e;
  background-color: #424242; }

.mdl-mega-footer--top-section:after,
.mdl-mega-footer--middle-section:after,
.mdl-mega-footer--bottom-section:after,
.mdl-mega-footer__top-section:after,
.mdl-mega-footer__middle-section:after,
.mdl-mega-footer__bottom-section:after {
  content: '';
  display: block;
  clear: both; }

.mdl-mega-footer--left-section,
.mdl-mega-footer__left-section,
.mdl-mega-footer--right-section,
.mdl-mega-footer__right-section {
  margin-bottom: 16px; }

.mdl-mega-footer--right-section a,
.mdl-mega-footer__right-section a {
  display: block;
  margin-bottom: 16px;
  color: inherit;
  text-decoration: none; }

@media screen and (min-width: 760px) {
  .mdl-mega-footer--left-section,
  .mdl-mega-footer__left-section {
    float: left; }
  .mdl-mega-footer--right-section,
  .mdl-mega-footer__right-section {
    float: right; }
  .mdl-mega-footer--right-section a,
  .mdl-mega-footer__right-section a {
    display: inline-block;
    margin-left: 16px;
    line-height: 36px;
    vertical-align: middle; } }

.mdl-mega-footer--social-btn,
.mdl-mega-footer__social-btn {
  width: 36px;
  height: 36px;
  padding: 0;
  margin: 0;
  background-color: #9e9e9e;
  border: none; }

.mdl-mega-footer--drop-down-section,
.mdl-mega-footer__drop-down-section {
  display: block;
  position: relative; }

@media screen and (min-width: 760px) {
  .mdl-mega-footer--drop-down-section,
  .mdl-mega-footer__drop-down-section {
    width: 33%; }
  .mdl-mega-footer--drop-down-section:nth-child(1),
  .mdl-mega-footer--drop-down-section:nth-child(2),
  .mdl-mega-footer__drop-down-section:nth-child(1),
  .mdl-mega-footer__drop-down-section:nth-child(2) {
    float: left; }
  .mdl-mega-footer--drop-down-section:nth-child(3),
  .mdl-mega-footer__drop-down-section:nth-child(3) {
    float: right; }
  .mdl-mega-footer--drop-down-section:nth-child(3):after,
  .mdl-mega-footer__drop-down-section:nth-child(3):after {
    clear: right; }
  .mdl-mega-footer--drop-down-section:nth-child(4),
  .mdl-mega-footer__drop-down-section:nth-child(4) {
    clear: right;
    float: right; }
  .mdl-mega-footer--middle-section:after,
  .mdl-mega-footer__middle-section:after {
    content: '';
    display: block;
    clear: both; }
  .mdl-mega-footer--bottom-section,
  .mdl-mega-footer__bottom-section {
    padding-top: 0; } }

@media screen and (min-width: 1024px) {
  .mdl-mega-footer--drop-down-section,
  .mdl-mega-footer--drop-down-section:nth-child(3),
  .mdl-mega-footer--drop-down-section:nth-child(4),
  .mdl-mega-footer__drop-down-section,
  .mdl-mega-footer__drop-down-section:nth-child(3),
  .mdl-mega-footer__drop-down-section:nth-child(4) {
    width: 24%;
    float: left; } }

.mdl-mega-footer--heading-checkbox,
.mdl-mega-footer__heading-checkbox {
  position: absolute;
  width: 100%;
  height: 55.8px;
  padding: 32px;
  margin: -16px 0 0;
  cursor: pointer;
  z-index: 1;
  opacity: 0; }

.mdl-mega-footer--heading-checkbox + .mdl-mega-footer--heading:after,
.mdl-mega-footer--heading-checkbox + .mdl-mega-footer__heading:after,
.mdl-mega-footer__heading-checkbox + .mdl-mega-footer--heading:after,
.mdl-mega-footer__heading-checkbox + .mdl-mega-footer__heading:after {
  font-family: 'Material Icons';
  content: '\E5CE'; }

.mdl-mega-footer--heading-checkbox:checked ~ .mdl-mega-footer--link-list,
.mdl-mega-footer--heading-checkbox:checked ~ .mdl-mega-footer__link-list,
.mdl-mega-footer--heading-checkbox:checked + .mdl-mega-footer--heading + .mdl-mega-footer--link-list,
.mdl-mega-footer--heading-checkbox:checked + .mdl-mega-footer__heading + .mdl-mega-footer__link-list,
.mdl-mega-footer__heading-checkbox:checked ~ .mdl-mega-footer--link-list,
.mdl-mega-footer__heading-checkbox:checked ~ .mdl-mega-footer__link-list,
.mdl-mega-footer__heading-checkbox:checked + .mdl-mega-footer--heading + .mdl-mega-footer--link-list,
.mdl-mega-footer__heading-checkbox:checked + .mdl-mega-footer__heading + .mdl-mega-footer__link-list {
  display: none; }

.mdl-mega-footer--heading-checkbox:checked + .mdl-mega-footer--heading:after,
.mdl-mega-footer--heading-checkbox:checked + .mdl-mega-footer__heading:after,
.mdl-mega-footer__heading-checkbox:checked + .mdl-mega-footer--heading:after,
.mdl-mega-footer__heading-checkbox:checked + .mdl-mega-footer__heading:after {
  font-family: 'Material Icons';
  content: '\E5CF'; }

.mdl-mega-footer--heading,
.mdl-mega-footer__heading {
  position: relative;
  width: 100%;
  padding-right: 39.8px;
  margin-bottom: 16px;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 23.8px;
  font-weight: 500;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #e0e0e0; }
  .fontx--default .mdl-mega-footer--heading, .fontx--default
  .mdl-mega-footer__heading {
    font-size: 14px; }
  .fontx--x2 .mdl-mega-footer--heading, .fontx--x2
  .mdl-mega-footer__heading {
    font-size: 16px; }

.mdl-mega-footer--heading:after,
.mdl-mega-footer__heading:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 23.8px;
  height: 23.8px;
  background-size: cover; }

.mdl-mega-footer--link-list,
.mdl-mega-footer__link-list {
  list-style: none;
  padding: 0;
  margin: 0 0 32px; }

.mdl-mega-footer--link-list:after,
.mdl-mega-footer__link-list:after {
  clear: both;
  display: block;
  content: ''; }

.mdl-mega-footer--link-list li,
.mdl-mega-footer__link-list li {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px; }
  .fontx--default .mdl-mega-footer--link-list li, .fontx--default
  .mdl-mega-footer__link-list li {
    font-size: 14px; }
  .fontx--x2 .mdl-mega-footer--link-list li, .fontx--x2
  .mdl-mega-footer__link-list li {
    font-size: 16px; }

.mdl-mega-footer--link-list a,
.mdl-mega-footer__link-list a {
  color: inherit;
  text-decoration: none;
  white-space: nowrap; }

@media screen and (min-width: 760px) {
  .mdl-mega-footer--heading-checkbox,
  .mdl-mega-footer__heading-checkbox {
    display: none; }
  .mdl-mega-footer--heading-checkbox + .mdl-mega-footer--heading:after,
  .mdl-mega-footer--heading-checkbox + .mdl-mega-footer__heading:after,
  .mdl-mega-footer__heading-checkbox + .mdl-mega-footer--heading:after,
  .mdl-mega-footer__heading-checkbox + .mdl-mega-footer__heading:after {
    content: ''; }
  .mdl-mega-footer--heading-checkbox:checked ~ .mdl-mega-footer--link-list,
  .mdl-mega-footer--heading-checkbox:checked ~ .mdl-mega-footer__link-list,
  .mdl-mega-footer--heading-checkbox:checked + .mdl-mega-footer__heading + .mdl-mega-footer__link-list,
  .mdl-mega-footer--heading-checkbox:checked + .mdl-mega-footer--heading + .mdl-mega-footer--link-list,
  .mdl-mega-footer__heading-checkbox:checked ~ .mdl-mega-footer--link-list,
  .mdl-mega-footer__heading-checkbox:checked ~ .mdl-mega-footer__link-list,
  .mdl-mega-footer__heading-checkbox:checked + .mdl-mega-footer__heading + .mdl-mega-footer__link-list,
  .mdl-mega-footer__heading-checkbox:checked + .mdl-mega-footer--heading + .mdl-mega-footer--link-list {
    display: block; }
  .mdl-mega-footer--heading-checkbox:checked + .mdl-mega-footer--heading:after,
  .mdl-mega-footer--heading-checkbox:checked + .mdl-mega-footer__heading:after,
  .mdl-mega-footer__heading-checkbox:checked + .mdl-mega-footer--heading:after,
  .mdl-mega-footer__heading-checkbox:checked + .mdl-mega-footer__heading:after {
    content: ''; } }

.mdl-mega-footer--bottom-section,
.mdl-mega-footer__bottom-section {
  padding-top: 16px;
  margin-bottom: 16px; }

.mdl-logo {
  margin-bottom: 16px;
  color: #fff; }

.mdl-mega-footer--bottom-section .mdl-mega-footer--link-list li,
.mdl-mega-footer__bottom-section .mdl-mega-footer__link-list li {
  float: left;
  margin-bottom: 0;
  margin-right: 16px; }

@media screen and (min-width: 760px) {
  .mdl-logo {
    float: left;
    margin-bottom: 0;
    margin-right: 16px; } }

.mdl-mini-footer {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 32px 16px;
  color: #9e9e9e;
  background-color: #424242; }

.mdl-mini-footer:after {
  content: '';
  display: block; }

.mdl-mini-footer .mdl-logo {
  line-height: 36px; }

.mdl-mini-footer--link-list,
.mdl-mini-footer__link-list {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  list-style: none;
  margin: 0;
  padding: 0; }

.mdl-mini-footer--link-list li,
.mdl-mini-footer__link-list li {
  margin-bottom: 0;
  margin-right: 16px; }

@media screen and (min-width: 760px) {
  .mdl-mini-footer--link-list li,
  .mdl-mini-footer__link-list li {
    line-height: 36px; } }

.mdl-mini-footer--link-list a,
.mdl-mini-footer__link-list a {
  color: inherit;
  text-decoration: none;
  white-space: nowrap; }

.mdl-mini-footer--left-section,
.mdl-mini-footer__left-section {
  display: inline-block;
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0; }

.mdl-mini-footer--right-section,
.mdl-mini-footer__right-section {
  display: inline-block;
  -webkit-order: 1;
  -ms-flex-order: 1;
  order: 1; }

.mdl-mini-footer--social-btn,
.mdl-mini-footer__social-btn {
  width: 36px;
  height: 36px;
  padding: 0;
  margin: 0;
  background-color: #9e9e9e;
  border: none; }

.mdl-icon-toggle {
  position: relative;
  z-index: 1;
  vertical-align: middle;
  display: inline-block;
  height: 32px;
  margin: 0;
  padding: 0; }

.mdl-icon-toggle__input {
  line-height: 32px; }

.mdl-icon-toggle.is-upgraded .mdl-icon-toggle__input {
  position: absolute;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
  opacity: 0;
  -ms-appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: none; }

.mdl-icon-toggle__label {
  display: inline-block;
  position: relative;
  cursor: pointer;
  height: 32px;
  width: 32px;
  min-width: 32px;
  color: #616161;
  border-radius: 50%;
  padding: 0;
  margin-left: 0;
  margin-right: 0;
  text-align: center;
  background-color: transparent;
  will-change: background-color;
  transition: background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1), color 0.2s cubic-bezier(0.4, 0, 0.2, 1); }

.mdl-icon-toggle__label.material-icons {
  line-height: 32px;
  font-size: 24px; }

.mdl-icon-toggle.is-checked .mdl-icon-toggle__label {
  color: #ffeb3b; }

.mdl-icon-toggle.is-disabled .mdl-icon-toggle__label {
  color: rgba(0, 0, 0, 0.26);
  cursor: auto;
  transition: none; }

.mdl-icon-toggle.is-focused .mdl-icon-toggle__label {
  background-color: rgba(0, 0, 0, 0.12); }

.mdl-icon-toggle.is-focused.is-checked .mdl-icon-toggle__label {
  background-color: rgba(255, 235, 59, 0.26); }

.mdl-icon-toggle__ripple-container {
  position: absolute;
  z-index: 2;
  top: -2px;
  left: -2px;
  box-sizing: border-box;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  cursor: pointer;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(circle, #fff, #000); }

.mdl-icon-toggle__ripple-container .mdl-ripple {
  background: #616161; }

.mdl-icon-toggle.is-disabled .mdl-icon-toggle__ripple-container {
  cursor: auto; }

.mdl-icon-toggle.is-disabled .mdl-icon-toggle__ripple-container .mdl-ripple {
  background: 0 0; }

.mdl-list {
  display: block;
  padding: 8px 0;
  list-style: none; }

.mdl-list__item {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: .04em;
  line-height: 1;
  min-height: 48px;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  padding: 16px;
  cursor: default;
  color: rgba(0, 0, 0, 0.87);
  overflow: hidden; }
  .fontx--default .mdl-list__item {
    font-size: 16px; }
  .fontx--x2 .mdl-list__item {
    font-size: 18px; }

.mdl-list__item,
.mdl-list__item .mdl-list__item-primary-content {
  box-sizing: border-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; }

.mdl-list__item .mdl-list__item-primary-content {
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-flex-grow: 2;
  -ms-flex-positive: 2;
  flex-grow: 2;
  text-decoration: none; }

.mdl-list__item .mdl-list__item-primary-content .mdl-list__item-icon {
  margin-right: 32px; }

.mdl-list__item .mdl-list__item-primary-content .mdl-list__item-avatar {
  margin-right: 16px; }

.mdl-list__item .mdl-list__item-secondary-content {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: column;
  -ms-flex-flow: column;
  flex-flow: column;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  margin-left: 16px; }

.mdl-list__item .mdl-list__item-secondary-content .mdl-list__item-secondary-action label {
  display: inline; }

.mdl-list__item .mdl-list__item-secondary-content .mdl-list__item-secondary-info {
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0;
  color: rgba(0, 0, 0, 0.54); }
  .fontx--default .mdl-list__item .mdl-list__item-secondary-content .mdl-list__item-secondary-info {
    font-size: 12px; }
  .fontx--x2 .mdl-list__item .mdl-list__item-secondary-content .mdl-list__item-secondary-info {
    font-size: 14px; }

.mdl-list__item .mdl-list__item-secondary-content .mdl-list__item-sub-header {
  padding: 0 0 0 16px; }

.mdl-list__item-icon,
.mdl-list__item-icon.material-icons {
  height: 24px;
  width: 24px;
  font-size: 24px;
  box-sizing: border-box;
  color: #757575; }
  .fontx--default .mdl-list__item-icon, .fontx--default
  .mdl-list__item-icon.material-icons {
    font-size: 24px; }
  .fontx--x2 .mdl-list__item-icon, .fontx--x2
  .mdl-list__item-icon.material-icons {
    font-size: 26px; }

.mdl-list__item-avatar,
.mdl-list__item-avatar.material-icons {
  height: 40px;
  width: 40px;
  box-sizing: border-box;
  border-radius: 50%;
  background-color: #757575;
  font-size: 40px;
  color: #fff; }

.mdl-list__item--two-line {
  height: 72px; }

.mdl-list__item--two-line .mdl-list__item-primary-content {
  height: 36px;
  line-height: 20px;
  display: block; }

.mdl-list__item--two-line .mdl-list__item-primary-content .mdl-list__item-avatar {
  float: left; }

.mdl-list__item--two-line .mdl-list__item-primary-content .mdl-list__item-icon {
  float: left;
  margin-top: 6px; }

.mdl-list__item--two-line .mdl-list__item-primary-content .mdl-list__item-secondary-content {
  height: 36px; }

.mdl-list__item--two-line .mdl-list__item-primary-content .mdl-list__item-sub-title {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.54);
  display: block;
  padding: 0; }
  .fontx--default .mdl-list__item--two-line .mdl-list__item-primary-content .mdl-list__item-sub-title {
    font-size: 14px; }
  .fontx--x2 .mdl-list__item--two-line .mdl-list__item-primary-content .mdl-list__item-sub-title {
    font-size: 16px; }

.mdl-list__item--three-line {
  height: 88px; }

.mdl-list__item--three-line .mdl-list__item-primary-content {
  height: 52px;
  line-height: 20px;
  display: block; }

.mdl-list__item--three-line .mdl-list__item-primary-content .mdl-list__item-avatar,
.mdl-list__item--three-line .mdl-list__item-primary-content .mdl-list__item-icon {
  float: left; }

.mdl-list__item--three-line .mdl-list__item-secondary-content {
  height: 52px; }

.mdl-list__item--three-line .mdl-list__item-text-body {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18px;
  height: 52px;
  color: rgba(0, 0, 0, 0.54);
  display: block;
  padding: 0; }
  .fontx--default .mdl-list__item--three-line .mdl-list__item-text-body {
    font-size: 14px; }
  .fontx--x2 .mdl-list__item--three-line .mdl-list__item-text-body {
    font-size: 16px; }

.mdl-menu__container {
  display: block;
  margin: 0;
  padding: 0;
  border: none;
  position: absolute;
  overflow: visible;
  height: 0;
  width: 0;
  visibility: hidden;
  z-index: -1; }

.mdl-menu__container.is-visible,
.mdl-menu__container.is-animating {
  z-index: 999;
  visibility: visible; }

.mdl-menu__outline {
  display: block;
  background: #fff;
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 2px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  will-change: transform;
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  z-index: -1; }

.mdl-menu__container.is-visible .mdl-menu__outline {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
  z-index: 999; }

.mdl-menu__outline.mdl-menu--bottom-right {
  -webkit-transform-origin: 100% 0;
  transform-origin: 100% 0; }

.mdl-menu__outline.mdl-menu--top-left {
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%; }

.mdl-menu__outline.mdl-menu--top-right {
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%; }

.mdl-menu {
  position: absolute;
  list-style: none;
  top: 0;
  left: 0;
  height: auto;
  width: auto;
  min-width: 124px;
  padding: 8px 0;
  margin: 0;
  opacity: 0;
  clip: rect(0 0 0 0);
  z-index: -1; }

.mdl-menu__container.is-visible .mdl-menu {
  opacity: 1;
  z-index: 999; }

.mdl-menu.is-animating {
  transition: opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1), clip 0.3s cubic-bezier(0.4, 0, 0.2, 1); }

.mdl-menu.mdl-menu--bottom-right {
  left: auto;
  right: 0; }

.mdl-menu.mdl-menu--top-left {
  top: auto;
  bottom: 0; }

.mdl-menu.mdl-menu--top-right {
  top: auto;
  left: auto;
  bottom: 0;
  right: 0; }

.mdl-menu.mdl-menu--unaligned {
  top: auto;
  left: auto; }

.mdl-menu__item {
  display: block;
  border: none;
  color: rgba(0, 0, 0, 0.87);
  background-color: transparent;
  text-align: left;
  margin: 0;
  padding: 0 16px;
  outline-color: #bdbdbd;
  position: relative;
  overflow: hidden;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  text-decoration: none;
  cursor: pointer;
  height: 48px;
  line-height: 48px;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }
  .fontx--default .mdl-menu__item {
    font-size: 14px; }
  .fontx--x2 .mdl-menu__item {
    font-size: 16px; }

.mdl-menu__container.is-visible .mdl-menu__item {
  opacity: 1; }

.mdl-menu__item::-moz-focus-inner {
  border: 0; }

.mdl-menu__item--full-bleed-divider {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12); }

.mdl-menu__item[disabled],
.mdl-menu__item[data-mdl-disabled] {
  color: #bdbdbd;
  background-color: transparent;
  cursor: auto; }

.mdl-menu__item[disabled]:hover,
.mdl-menu__item[data-mdl-disabled]:hover {
  background-color: transparent; }

.mdl-menu__item[disabled]:focus,
.mdl-menu__item[data-mdl-disabled]:focus {
  background-color: transparent; }

.mdl-menu__item[disabled] .mdl-ripple,
.mdl-menu__item[data-mdl-disabled] .mdl-ripple {
  background: 0 0; }

.mdl-menu__item:hover {
  background-color: #eee; }

.mdl-menu__item:focus {
  outline: none;
  background-color: #eee; }

.mdl-menu__item:active {
  background-color: #e0e0e0; }

.mdl-menu__item--ripple-container {
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
  overflow: hidden; }

.mdl-progress {
  display: block;
  position: relative;
  height: 4px;
  width: 500px;
  max-width: 100%; }

.mdl-progress > .bar {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 0%;
  transition: width 0.2s cubic-bezier(0.4, 0, 0.2, 1); }

.mdl-progress > .progressbar {
  background-color: #ffeb3b;
  z-index: 1;
  left: 0; }

.mdl-progress > .bufferbar {
  background-image: linear-gradient(to right, rgba(66, 66, 66, 0.7), rgba(66, 66, 66, 0.7)), linear-gradient(to right, #ffeb3b, #ffeb3b);
  z-index: 0;
  left: 0; }

.mdl-progress > .auxbar {
  right: 0; }

@supports (-webkit-appearance: none) {
  .mdl-progress:not(.mdl-progress--indeterminate):not(.mdl-progress--indeterminate) > .auxbar,
  .mdl-progress:not(.mdl-progress__indeterminate):not(.mdl-progress__indeterminate) > .auxbar {
    background-image: linear-gradient(to right, rgba(66, 66, 66, 0.7), rgba(66, 66, 66, 0.7)), linear-gradient(to right, #ffeb3b, #ffeb3b);
    -webkit-mask: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIj8+Cjxzdmcgd2lkdGg9IjEyIiBoZWlnaHQ9IjQiIHZpZXdQb3J0PSIwIDAgMTIgNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogIDxlbGxpcHNlIGN4PSIyIiBjeT0iMiIgcng9IjIiIHJ5PSIyIj4KICAgIDxhbmltYXRlIGF0dHJpYnV0ZU5hbWU9ImN4IiBmcm9tPSIyIiB0bz0iLTEwIiBkdXI9IjAuNnMiIHJlcGVhdENvdW50PSJpbmRlZmluaXRlIiAvPgogIDwvZWxsaXBzZT4KICA8ZWxsaXBzZSBjeD0iMTQiIGN5PSIyIiByeD0iMiIgcnk9IjIiIGNsYXNzPSJsb2FkZXIiPgogICAgPGFuaW1hdGUgYXR0cmlidXRlTmFtZT0iY3giIGZyb209IjE0IiB0bz0iMiIgZHVyPSIwLjZzIiByZXBlYXRDb3VudD0iaW5kZWZpbml0ZSIgLz4KICA8L2VsbGlwc2U+Cjwvc3ZnPgo=");
    mask: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIj8+Cjxzdmcgd2lkdGg9IjEyIiBoZWlnaHQ9IjQiIHZpZXdQb3J0PSIwIDAgMTIgNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogIDxlbGxpcHNlIGN4PSIyIiBjeT0iMiIgcng9IjIiIHJ5PSIyIj4KICAgIDxhbmltYXRlIGF0dHJpYnV0ZU5hbWU9ImN4IiBmcm9tPSIyIiB0bz0iLTEwIiBkdXI9IjAuNnMiIHJlcGVhdENvdW50PSJpbmRlZmluaXRlIiAvPgogIDwvZWxsaXBzZT4KICA8ZWxsaXBzZSBjeD0iMTQiIGN5PSIyIiByeD0iMiIgcnk9IjIiIGNsYXNzPSJsb2FkZXIiPgogICAgPGFuaW1hdGUgYXR0cmlidXRlTmFtZT0iY3giIGZyb209IjE0IiB0bz0iMiIgZHVyPSIwLjZzIiByZXBlYXRDb3VudD0iaW5kZWZpbml0ZSIgLz4KICA8L2VsbGlwc2U+Cjwvc3ZnPgo="); } }

.mdl-progress:not(.mdl-progress--indeterminate) > .auxbar,
.mdl-progress:not(.mdl-progress__indeterminate) > .auxbar {
  background-image: linear-gradient(to right, rgba(66, 66, 66, 0.9), rgba(66, 66, 66, 0.9)), linear-gradient(to right, #ffeb3b, #ffeb3b); }

.mdl-progress.mdl-progress--indeterminate > .bar1,
.mdl-progress.mdl-progress__indeterminate > .bar1 {
  -webkit-animation-name: indeterminate1;
  animation-name: indeterminate1; }

.mdl-progress.mdl-progress--indeterminate > .bar1,
.mdl-progress.mdl-progress__indeterminate > .bar1,
.mdl-progress.mdl-progress--indeterminate > .bar3,
.mdl-progress.mdl-progress__indeterminate > .bar3 {
  background-color: #ffeb3b;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear; }

.mdl-progress.mdl-progress--indeterminate > .bar3,
.mdl-progress.mdl-progress__indeterminate > .bar3 {
  background-image: none;
  -webkit-animation-name: indeterminate2;
  animation-name: indeterminate2; }

@-webkit-keyframes indeterminate1 {
  0% {
    left: 0%;
    width: 0%; }
  50% {
    left: 25%;
    width: 75%; }
  75% {
    left: 100%;
    width: 0%; } }

@keyframes indeterminate1 {
  0% {
    left: 0%;
    width: 0%; }
  50% {
    left: 25%;
    width: 75%; }
  75% {
    left: 100%;
    width: 0%; } }

@-webkit-keyframes indeterminate2 {
  0%,
  50% {
    left: 0%;
    width: 0%; }
  75% {
    left: 0%;
    width: 25%; }
  100% {
    left: 100%;
    width: 0%; } }

@keyframes indeterminate2 {
  0%,
  50% {
    left: 0%;
    width: 0%; }
  75% {
    left: 0%;
    width: 25%; }
  100% {
    left: 100%;
    width: 0%; } }

.mdl-navigation {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  box-sizing: border-box; }

.mdl-navigation__link {
  color: #424242;
  text-decoration: none;
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  opacity: .87; }
  .fontx--default .mdl-navigation__link {
    font-size: 14px; }
  .fontx--x2 .mdl-navigation__link {
    font-size: 16px; }

.mdl-navigation__link .material-icons {
  vertical-align: middle; }

.mdl-layout {
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  -webkit-overflow-scrolling: touch; }

.mdl-layout.is-small-screen .mdl-layout--large-screen-only {
  display: none; }

.mdl-layout:not(.is-small-screen) .mdl-layout--small-screen-only {
  display: none; }

.mdl-layout__container {
  position: absolute;
  width: 100%;
  height: 100%; }

.mdl-layout__title,
.mdl-layout-title {
  display: block;
  position: relative;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 20px;
  line-height: 1;
  letter-spacing: .02em;
  font-weight: 400;
  box-sizing: border-box; }
  .fontx--default .mdl-layout__title, .fontx--default
  .mdl-layout-title {
    font-size: 20px; }
  .fontx--x2 .mdl-layout__title, .fontx--x2
  .mdl-layout-title {
    font-size: 22px; }

.mdl-layout-spacer {
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1; }

.mdl-layout__drawer {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  width: 240px;
  height: 100%;
  max-height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  border-right: 1px solid #e0e0e0;
  background: #fafafa;
  -webkit-transform: translateX(-250px);
  transform: translateX(-250px);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  will-change: transform;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  color: #424242;
  overflow: visible;
  overflow-y: auto;
  z-index: 9999; }
  .mdl-layout__drawer.drop__list {
    background: #fff; }
    @media only screen and (max-width: 480px) {
      .mdl-layout__drawer.drop__list {
        width: 320px;
        transform: translateX(-320px); } }

.mdl-layout__drawer.is-visible {
  -webkit-transform: translateX(0);
  transform: translateX(0); }

.mdl-layout__drawer.is-visible ~ .mdl-layout__content.mdl-layout__content {
  overflow: hidden; }

.mdl-layout__drawer > * {
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0; }

.mdl-layout__drawer > .mdl-layout__title,
.mdl-layout__drawer > .mdl-layout-title {
  line-height: 64px;
  padding-left: 40px; }

@media screen and (max-width: 1024px) {
  .mdl-layout__drawer > .mdl-layout__title,
  .mdl-layout__drawer > .mdl-layout-title {
    line-height: 56px;
    padding-left: 16px; } }

.mdl-layout__drawer .mdl-navigation {
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  padding-top: 16px; }

.mdl-layout__drawer .mdl-navigation .mdl-navigation__link {
  display: block;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding: 16px 40px;
  margin: 0;
  color: #757575; }

@media screen and (max-width: 1024px) {
  .mdl-layout__drawer .mdl-navigation .mdl-navigation__link {
    padding: 16px; } }

.mdl-layout__drawer .mdl-navigation .mdl-navigation__link:hover {
  background-color: #e0e0e0; }

.mdl-layout__drawer .mdl-navigation .mdl-navigation__link--current {
  background-color: #e0e0e0;
  color: #000; }

@media screen and (min-width: 1025px) {
  .mdl-layout--fixed-drawer > .mdl-layout__drawer {
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

.mdl-layout__drawer-button {
  display: block;
  position: absolute;
  height: 48px;
  width: 48px;
  border: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  overflow: hidden;
  text-align: center;
  cursor: pointer;
  font-size: 26px;
  line-height: 56px;
  font-family: Helvetica, Arial, sans-serif;
  margin: 8px 12px;
  top: 0;
  left: 0;
  color: #424242;
  z-index: 4; }

.mdl-layout__header .mdl-layout__drawer-button {
  position: absolute;
  color: #424242;
  background-color: inherit; }

@media screen and (max-width: 1024px) {
  .mdl-layout__header .mdl-layout__drawer-button {
    margin: 4px; } }

@media screen and (max-width: 1024px) {
  .mdl-layout__drawer-button {
    margin: 4px;
    color: rgba(0, 0, 0, 0.5); } }

@media screen and (min-width: 1025px) {
  .mdl-layout__drawer-button {
    line-height: 54px; }
  .mdl-layout--no-desktop-drawer-button .mdl-layout__drawer-button,
  .mdl-layout--fixed-drawer > .mdl-layout__drawer-button,
  .mdl-layout--no-drawer-button .mdl-layout__drawer-button {
    display: none; } }

.mdl-layout__header {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  box-sizing: border-box;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  border: none;
  min-height: 64px;
  max-height: 1000px;
  z-index: 3;
  background-color: #ffeb3b;
  color: #424242;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: max-height, box-shadow; }

@media screen and (max-width: 1024px) {
  .mdl-layout__header {
    min-height: 56px; } }

.mdl-layout--fixed-drawer.is-upgraded:not(.is-small-screen) > .mdl-layout__header {
  margin-left: 240px;
  width: calc(100% - 240px); }

@media screen and (min-width: 1025px) {
  .mdl-layout--fixed-drawer > .mdl-layout__header .mdl-layout__header-row {
    padding-left: 40px; } }

.mdl-layout__header > .mdl-layout-icon {
  position: absolute;
  left: 40px;
  top: 16px;
  height: 32px;
  width: 32px;
  overflow: hidden;
  z-index: 3;
  display: block; }

@media screen and (max-width: 1024px) {
  .mdl-layout__header > .mdl-layout-icon {
    left: 16px;
    top: 12px; } }

.mdl-layout.has-drawer .mdl-layout__header > .mdl-layout-icon {
  display: none; }

.mdl-layout__header.is-compact {
  max-height: 64px; }

@media screen and (max-width: 1024px) {
  .mdl-layout__header.is-compact {
    max-height: 56px; } }

.mdl-layout__header.is-compact.has-tabs {
  height: 112px; }

@media screen and (max-width: 1024px) {
  .mdl-layout__header.is-compact.has-tabs {
    min-height: 104px; } }

@media screen and (max-width: 1024px) {
  .mdl-layout__header {
    display: none; }
  .mdl-layout--fixed-header > .mdl-layout__header {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex; } }

.mdl-layout__header--transparent.mdl-layout__header--transparent {
  background-color: transparent;
  box-shadow: none; }

.mdl-layout__header--seamed,
.mdl-layout__header--scroll {
  box-shadow: none; }

.mdl-layout__header--waterfall {
  box-shadow: none;
  overflow: hidden; }

.mdl-layout__header--waterfall.is-casting-shadow {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12); }

.mdl-layout__header--waterfall.mdl-layout__header--waterfall-hide-top {
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end; }

.mdl-layout__header-row {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  box-sizing: border-box;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 64px;
  margin: 0;
  padding: 0 40px 0 80px; }

.mdl-layout--no-drawer-button .mdl-layout__header-row {
  padding-left: 40px; }

@media screen and (min-width: 1025px) {
  .mdl-layout--no-desktop-drawer-button .mdl-layout__header-row {
    padding-left: 40px; } }

@media screen and (max-width: 1024px) {
  .mdl-layout__header-row {
    height: 56px;
    padding: 0 16px 0 72px; }
  .mdl-layout--no-drawer-button .mdl-layout__header-row {
    padding-left: 16px; } }

.mdl-layout__header-row > * {
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0; }

.mdl-layout__header--scroll .mdl-layout__header-row {
  width: 100%; }

.mdl-layout__header-row .mdl-navigation {
  margin: 0;
  padding: 0;
  height: 64px;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; }

.mdl-layout__header-row .mdl-navigation__link {
  display: block;
  color: #424242;
  line-height: 64px;
  padding: 0 24px; }

@media screen and (max-width: 1024px) {
  .mdl-layout__header-row .mdl-navigation__link {
    line-height: 56px;
    padding: 0 16px; } }

.mdl-custom__obfus,
.mdl-layout__obfuscator {
  background-color: transparent;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 100;
  visibility: hidden;
  transition-property: background-color;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1); }

.mdl-custom__obfus.is-visible,
.mdl-layout__obfuscator.is-visible {
  background-color: rgba(0, 0, 0, 0.5);
  visibility: visible; }

@supports (pointer-events: auto) {
  .mdl-custom__obfus,
  .mdl-layout__obfuscator {
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition-property: opacity;
    visibility: visible;
    pointer-events: none; }
  .mdl-custom__obfus.is-visible,
  .mdl-layout__obfuscator.is-visible {
    pointer-events: auto;
    opacity: 1; } }

.mdl-layout__content {
  -ms-flex: 0 1 auto;
  position: relative;
  display: inline-block;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  z-index: 1;
  -webkit-overflow-scrolling: touch; }

.mdl-layout--fixed-drawer > .mdl-layout__content {
  margin-left: 240px; }

.mdl-layout__container.has-scrolling-header .mdl-layout__content {
  overflow: visible; }

@media screen and (max-width: 1024px) {
  .mdl-layout--fixed-drawer > .mdl-layout__content {
    margin-left: 0; } }

@media only screen and (min-width: 480px) {
  .mdl-layout__container.has-scrolling-header .mdl-layout__content {
    overflow: visible !important; } }

.mdl-layout__tab-bar {
  height: 96px;
  margin: 0;
  width: calc(100% - 112px);
  padding: 0 0 0 56px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  background-color: #ffeb3b;
  overflow-y: hidden;
  overflow-x: scroll; }

.mdl-layout__tab-bar::-webkit-scrollbar {
  display: none; }

.mdl-layout--no-drawer-button .mdl-layout__tab-bar {
  padding-left: 16px;
  width: calc(100% - 32px); }

@media screen and (min-width: 1025px) {
  .mdl-layout--no-desktop-drawer-button .mdl-layout__tab-bar {
    padding-left: 16px;
    width: calc(100% - 32px); } }

@media screen and (max-width: 1024px) {
  .mdl-layout__tab-bar {
    width: calc(100% - 60px);
    padding: 0 0 0 60px; }
  .mdl-layout--no-drawer-button .mdl-layout__tab-bar {
    width: calc(100% - 8px);
    padding-left: 4px; } }

.mdl-layout--fixed-tabs .mdl-layout__tab-bar {
  padding: 0;
  overflow: hidden;
  width: 100%; }

.mdl-layout__tab-bar-container {
  position: relative;
  height: 48px;
  width: 100%;
  border: none;
  margin: 0;
  z-index: 2;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  overflow: hidden; }

.mdl-layout__container > .mdl-layout__tab-bar-container {
  position: absolute;
  top: 0;
  left: 0; }

.mdl-layout__tab-bar-button {
  display: inline-block;
  position: absolute;
  top: 0;
  height: 48px;
  width: 56px;
  z-index: 4;
  text-align: center;
  background-color: #ffeb3b;
  color: transparent;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.mdl-layout--no-desktop-drawer-button .mdl-layout__tab-bar-button,
.mdl-layout--no-drawer-button .mdl-layout__tab-bar-button {
  width: 16px; }

.mdl-layout--no-desktop-drawer-button .mdl-layout__tab-bar-button .material-icons,
.mdl-layout--no-drawer-button .mdl-layout__tab-bar-button .material-icons {
  position: relative;
  left: -4px; }

@media screen and (max-width: 1024px) {
  .mdl-layout__tab-bar-button {
    width: 60px; } }

.mdl-layout--fixed-tabs .mdl-layout__tab-bar-button {
  display: none; }

.mdl-layout__tab-bar-button .material-icons {
  line-height: 48px; }

.mdl-layout__tab-bar-button.is-active {
  color: #424242; }

.mdl-layout__tab-bar-left-button {
  left: 0; }

.mdl-layout__tab-bar-right-button {
  right: 0; }

.mdl-layout__tab {
  margin: 0;
  border: none;
  padding: 0 24px;
  float: left;
  position: relative;
  display: block;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  text-decoration: none;
  height: 48px;
  line-height: 48px;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  color: rgba(66, 66, 66, 0.6);
  overflow: hidden; }
  .fontx--default .mdl-layout__tab {
    font-size: 14px; }
  .fontx--x2 .mdl-layout__tab {
    font-size: 16px; }

@media screen and (max-width: 1024px) {
  .mdl-layout__tab {
    padding: 0 12px; } }

.mdl-layout--fixed-tabs .mdl-layout__tab {
  float: none;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding: 0; }

.mdl-layout.is-upgraded .mdl-layout__tab.is-active {
  color: #424242; }

.mdl-layout.is-upgraded .mdl-layout__tab.is-active::after {
  height: 2px;
  width: 100%;
  display: block;
  content: " ";
  bottom: 0;
  left: 0;
  position: absolute;
  background: #448aff;
  -webkit-animation: border-expand 0.2s cubic-bezier(0.4, 0, 0.4, 1) 0.01s alternate forwards;
  animation: border-expand 0.2s cubic-bezier(0.4, 0, 0.4, 1) 0.01s alternate forwards;
  transition: all 1s cubic-bezier(0.4, 0, 1, 1); }

.mdl-layout__tab .mdl-layout__tab-ripple-container {
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  overflow: hidden; }

.mdl-layout__tab .mdl-layout__tab-ripple-container .mdl-ripple {
  background-color: #424242; }

.mdl-layout__tab-panel {
  display: block; }

.mdl-layout.is-upgraded .mdl-layout__tab-panel {
  display: none; }

.mdl-layout.is-upgraded .mdl-layout__tab-panel.is-active {
  display: block; }

.mdl-radio {
  position: relative;
  font-size: 16px;
  line-height: 24px;
  display: inline-block;
  vertical-align: middle;
  box-sizing: border-box;
  height: 24px;
  margin: 0;
  padding-left: 0; }
  .fontx--default .mdl-radio {
    font-size: 16px; }
  .fontx--x2 .mdl-radio {
    font-size: 18px; }

.mdl-radio.is-upgraded {
  padding-left: 24px; }

.mdl-radio__button {
  line-height: 24px; }

.mdl-radio.is-upgraded .mdl-radio__button {
  position: absolute;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
  opacity: 0;
  -ms-appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: none; }

.mdl-radio__outer-circle {
  position: absolute;
  top: 4px;
  left: 0;
  display: inline-block;
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  margin: 0;
  cursor: pointer;
  border: 2px solid rgba(0, 0, 0, 0.54);
  border-radius: 50%;
  z-index: 2; }

.mdl-radio.secondary .mdl-radio__inner-circle {
  background: #8bc34a; }

.mdl-radio.secondary.is-checked .mdl-radio__outer-circle {
  border: 2px solid #8bc34a; }

.mdl-radio.secondary .mdl-radio__ripple-container .mdl-ripple {
  background: #8bc34a; }

.mdl-radio.is-checked .mdl-radio__outer-circle {
  border: 2px solid #ffeb3b; }

.mdl-radio__outer-circle fieldset[disabled] .mdl-radio,
.mdl-radio.is-disabled .mdl-radio__outer-circle {
  border: 2px solid rgba(0, 0, 0, 0.26);
  cursor: auto; }

.mdl-radio__inner-circle {
  position: absolute;
  z-index: 1;
  margin: 0;
  top: 8px;
  left: 4px;
  box-sizing: border-box;
  width: 8px;
  height: 8px;
  cursor: pointer;
  transition-duration: .28s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transform: scale(0, 0);
  transform: scale(0, 0);
  border-radius: 50%;
  background: #ffeb3b; }

.mdl-radio.is-checked .mdl-radio__inner-circle {
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1); }

fieldset[disabled] .mdl-radio .mdl-radio__inner-circle,
.mdl-radio.is-disabled .mdl-radio__inner-circle {
  background: rgba(0, 0, 0, 0.26);
  cursor: auto; }

.mdl-radio.is-focused .mdl-radio__inner-circle {
  box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1); }

.mdl-radio__label {
  cursor: pointer; }

fieldset[disabled] .mdl-radio .mdl-radio__label,
.mdl-radio.is-disabled .mdl-radio__label {
  color: rgba(0, 0, 0, 0.26);
  cursor: auto; }

.mdl-radio__ripple-container {
  position: absolute;
  z-index: 2;
  top: -9px;
  left: -13px;
  box-sizing: border-box;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  cursor: pointer;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(circle, #fff, #000); }

.mdl-radio__ripple-container .mdl-ripple {
  background: #ffeb3b; }

fieldset[disabled] .mdl-radio .mdl-radio__ripple-container,
.mdl-radio.is-disabled .mdl-radio__ripple-container {
  cursor: auto; }

fieldset[disabled] .mdl-radio .mdl-radio__ripple-container .mdl-ripple,
.mdl-radio.is-disabled .mdl-radio__ripple-container .mdl-ripple {
  background: 0 0; }

_:-ms-input-placeholder,
:root .mdl-slider.mdl-slider.is-upgraded {
  -ms-appearance: none;
  height: 32px;
  margin: 0; }

.mdl-slider {
  width: calc(100% - 40px);
  margin: 0 20px; }

.mdl-slider.is-upgraded {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: 2px;
  background: 0 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: 0;
  padding: 0;
  color: #03a9f4;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  z-index: 1;
  cursor: pointer; }

.mdl-slider.is-upgraded::-moz-focus-outer {
  border: 0; }

.mdl-slider.is-upgraded::-ms-tooltip {
  display: none; }

.mdl-slider.is-upgraded::-webkit-slider-runnable-track {
  background: 0 0; }

.mdl-slider.is-upgraded::-moz-range-track {
  background: 0 0;
  border: none; }

.mdl-slider.is-upgraded::-ms-track {
  background: 0 0;
  color: transparent;
  height: 2px;
  width: 100%;
  border: none; }

.mdl-slider.is-upgraded::-ms-fill-lower {
  padding: 0;
  background: linear-gradient(to right, transparent, transparent 16px, #ffeb3b 16px, #ffeb3b 0); }

.mdl-slider.is-upgraded::-ms-fill-upper {
  padding: 0;
  background: linear-gradient(to left, transparent, transparent 16px, rgba(0, 0, 0, 0.26) 16px, rgba(0, 0, 0, 0.26) 0); }

.mdl-slider.is-upgraded::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 12px;
  height: 12px;
  box-sizing: border-box;
  border-radius: 50%;
  background: #03a9f4;
  border: none;
  transition: transform 0.18s cubic-bezier(0.4, 0, 0.2, 1), border 0.18s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.18s cubic-bezier(0.4, 0, 0.2, 1), background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 0.18s cubic-bezier(0.4, 0, 0.2, 1), border 0.18s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.18s cubic-bezier(0.4, 0, 0.2, 1), background 0.28s cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 0.18s cubic-bezier(0.4, 0, 0.2, 1); }

.mdl-slider.is-upgraded::-moz-range-thumb {
  -moz-appearance: none;
  width: 12px;
  height: 12px;
  box-sizing: border-box;
  border-radius: 50%;
  background-image: none;
  background: #03a9f4;
  border: none; }

.mdl-slider.is-upgraded:focus:not(:active)::-webkit-slider-thumb {
  box-shadow: 0 0 0 10px #03a9f4; }

.mdl-slider.is-upgraded:focus:not(:active)::-moz-range-thumb {
  box-shadow: 0 0 0 10px #03a9f4; }

.mdl-slider.is-upgraded:active::-webkit-slider-thumb {
  background-image: none;
  background: #03a9f4;
  -webkit-transform: scale(1.5);
  transform: scale(1.5); }

.mdl-slider.is-upgraded:active::-moz-range-thumb {
  background-image: none;
  background: #03a9f4;
  transform: scale(1.5); }

.mdl-slider.is-upgraded::-ms-thumb {
  width: 32px;
  height: 32px;
  border: none;
  border-radius: 50%;
  background: #03a9f4;
  transform: scale(0.375);
  transition: transform 0.18s cubic-bezier(0.4, 0, 0.2, 1), background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 0.18s cubic-bezier(0.4, 0, 0.2, 1), background 0.28s cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 0.18s cubic-bezier(0.4, 0, 0.2, 1); }

.mdl-slider.is-upgraded:focus:not(:active)::-ms-thumb {
  background: radial-gradient(circle closest-side, #ffeb3b 0%, #ffeb3b 37.5%, rgba(255, 235, 59, 0.26) 37.5%, rgba(255, 235, 59, 0.26) 100%);
  transform: scale(1); }

.mdl-slider.is-upgraded:active::-ms-thumb {
  background: #03a9f4;
  transform: scale(0.5625); }

.mdl-slider.is-upgraded.is-lowest-value::-webkit-slider-thumb {
  border: 2px solid rgba(0, 0, 0, 0.26);
  background: 0 0; }

.mdl-slider.is-upgraded.is-lowest-value::-moz-range-thumb {
  border: 2px solid rgba(0, 0, 0, 0.26);
  background: 0 0; }

.mdl-slider.is-upgraded.is-lowest-value + .mdl-slider__background-flex > .mdl-slider__background-upper {
  left: 6px; }

.mdl-slider.is-upgraded.is-lowest-value:focus:not(:active)::-webkit-slider-thumb {
  box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.12);
  background: rgba(0, 0, 0, 0.12); }

.mdl-slider.is-upgraded.is-lowest-value:focus:not(:active)::-moz-range-thumb {
  box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.12);
  background: rgba(0, 0, 0, 0.12); }

.mdl-slider.is-upgraded.is-lowest-value:active::-webkit-slider-thumb {
  border: 1.6px solid rgba(0, 0, 0, 0.26);
  -webkit-transform: scale(1.5);
  transform: scale(1.5); }

.mdl-slider.is-upgraded.is-lowest-value:active + .mdl-slider__background-flex > .mdl-slider__background-upper {
  left: 9px; }

.mdl-slider.is-upgraded.is-lowest-value:active::-moz-range-thumb {
  border: 1.5px solid rgba(0, 0, 0, 0.26);
  transform: scale(1.5); }

.mdl-slider.is-upgraded.is-lowest-value::-ms-thumb {
  background: radial-gradient(circle closest-side, transparent 0%, transparent 66.67%, rgba(0, 0, 0, 0.26) 66.67%, rgba(0, 0, 0, 0.26) 100%); }

.mdl-slider.is-upgraded.is-lowest-value:focus:not(:active)::-ms-thumb {
  background: radial-gradient(circle closest-side, rgba(0, 0, 0, 0.12) 0%, rgba(0, 0, 0, 0.12) 25%, rgba(0, 0, 0, 0.26) 25%, rgba(0, 0, 0, 0.26) 37.5%, rgba(0, 0, 0, 0.12) 37.5%, rgba(0, 0, 0, 0.12) 100%);
  transform: scale(1); }

.mdl-slider.is-upgraded.is-lowest-value:active::-ms-thumb {
  transform: scale(0.5625);
  background: radial-gradient(circle closest-side, transparent 0%, transparent 77.78%, rgba(0, 0, 0, 0.26) 77.78%, rgba(0, 0, 0, 0.26) 100%); }

.mdl-slider.is-upgraded.is-lowest-value::-ms-fill-lower {
  background: 0 0; }

.mdl-slider.is-upgraded.is-lowest-value::-ms-fill-upper {
  margin-left: 6px; }

.mdl-slider.is-upgraded.is-lowest-value:active::-ms-fill-upper {
  margin-left: 9px; }

.mdl-slider.is-upgraded:disabled:focus::-webkit-slider-thumb,
.mdl-slider.is-upgraded:disabled:active::-webkit-slider-thumb,
.mdl-slider.is-upgraded:disabled::-webkit-slider-thumb {
  -webkit-transform: scale(0.667);
  transform: scale(0.667);
  background: rgba(0, 0, 0, 0.26); }

.mdl-slider.is-upgraded:disabled:focus::-moz-range-thumb,
.mdl-slider.is-upgraded:disabled:active::-moz-range-thumb,
.mdl-slider.is-upgraded:disabled::-moz-range-thumb {
  transform: scale(0.667);
  background: rgba(0, 0, 0, 0.26); }

.mdl-slider.is-upgraded:disabled + .mdl-slider__background-flex > .mdl-slider__background-lower {
  background-color: rgba(0, 0, 0, 0.26);
  left: -6px; }

.mdl-slider.is-upgraded:disabled + .mdl-slider__background-flex > .mdl-slider__background-upper {
  left: 6px; }

.mdl-slider.is-upgraded.is-lowest-value:disabled:focus::-webkit-slider-thumb,
.mdl-slider.is-upgraded.is-lowest-value:disabled:active::-webkit-slider-thumb,
.mdl-slider.is-upgraded.is-lowest-value:disabled::-webkit-slider-thumb {
  border: 3px solid rgba(0, 0, 0, 0.26);
  background: 0 0;
  -webkit-transform: scale(0.667);
  transform: scale(0.667); }

.mdl-slider.is-upgraded.is-lowest-value:disabled:focus::-moz-range-thumb,
.mdl-slider.is-upgraded.is-lowest-value:disabled:active::-moz-range-thumb,
.mdl-slider.is-upgraded.is-lowest-value:disabled::-moz-range-thumb {
  border: 3px solid rgba(0, 0, 0, 0.26);
  background: 0 0;
  transform: scale(0.667); }

.mdl-slider.is-upgraded.is-lowest-value:disabled:active + .mdl-slider__background-flex > .mdl-slider__background-upper {
  left: 6px; }

.mdl-slider.is-upgraded:disabled:focus::-ms-thumb,
.mdl-slider.is-upgraded:disabled:active::-ms-thumb,
.mdl-slider.is-upgraded:disabled::-ms-thumb {
  transform: scale(0.25);
  background: rgba(0, 0, 0, 0.26); }

.mdl-slider.is-upgraded.is-lowest-value:disabled:focus::-ms-thumb,
.mdl-slider.is-upgraded.is-lowest-value:disabled:active::-ms-thumb,
.mdl-slider.is-upgraded.is-lowest-value:disabled::-ms-thumb {
  transform: scale(0.25);
  background: radial-gradient(circle closest-side, transparent 0%, transparent 50%, rgba(0, 0, 0, 0.26) 50%, rgba(0, 0, 0, 0.26) 100%); }

.mdl-slider.is-upgraded:disabled::-ms-fill-lower {
  margin-right: 6px;
  background: linear-gradient(to right, transparent, transparent 25px, rgba(0, 0, 0, 0.26) 25px, rgba(0, 0, 0, 0.26) 0); }

.mdl-slider.is-upgraded:disabled::-ms-fill-upper {
  margin-left: 6px; }

.mdl-slider.is-upgraded.is-lowest-value:disabled:active::-ms-fill-upper {
  margin-left: 6px; }

.mdl-slider__ie-container {
  height: 18px;
  overflow: visible;
  border: none;
  margin: none;
  padding: none; }

.mdl-slider__container {
  height: 18px;
  position: relative;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row; }

.mdl-slider__container,
.mdl-slider__background-flex {
  background: 0 0;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex; }

.mdl-slider__background-flex {
  position: absolute;
  height: 2px;
  width: calc(100% - 52px);
  top: 50%;
  left: 0;
  margin: 0 26px;
  overflow: hidden;
  border: 0;
  padding: 0;
  -webkit-transform: translate(0, -1px);
  transform: translate(0, -1px); }

.mdl-slider__background-lower {
  background: #03a9f4; }

.mdl-slider__background-lower,
.mdl-slider__background-upper {
  -webkit-flex: 0;
  -ms-flex: 0;
  flex: 0;
  position: relative;
  border: 0;
  padding: 0; }

.mdl-slider__background-upper {
  background: rgba(0, 0, 0, 0.26);
  transition: left 0.18s cubic-bezier(0.4, 0, 0.2, 1); }

.mdl-snackbar {
  position: fixed;
  bottom: 0;
  left: 50%;
  cursor: default;
  background-color: #323232;
  z-index: 3;
  display: block;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  will-change: transform;
  -webkit-transform: translate(0, 80px);
  transform: translate(0, 80px);
  transition: transform 0.25s cubic-bezier(0.4, 0, 1, 1);
  transition: transform 0.25s cubic-bezier(0.4, 0, 1, 1), -webkit-transform 0.25s cubic-bezier(0.4, 0, 1, 1);
  pointer-events: none; }

@media (max-width: 479px) {
  .mdl-snackbar {
    width: 100%;
    left: 0;
    min-height: 48px;
    max-height: 80px; } }

@media (min-width: 480px) {
  .mdl-snackbar {
    min-width: 288px;
    max-width: 568px;
    border-radius: 2px;
    -webkit-transform: translate(-50%, 80px);
    transform: translate(-50%, 80px); } }

.mdl-snackbar--active {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  pointer-events: auto;
  transition: transform 0.25s cubic-bezier(0, 0, 0.2, 1);
  transition: transform 0.25s cubic-bezier(0, 0, 0.2, 1), -webkit-transform 0.25s cubic-bezier(0, 0, 0.2, 1); }

@media (min-width: 480px) {
  .mdl-snackbar--active {
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0); } }

.mdl-snackbar__text {
  padding: 14px 12px 14px 24px;
  vertical-align: middle;
  color: #fff;
  float: left; }

.mdl-snackbar__action {
  background: 0 0;
  border: none;
  color: #448aff;
  float: right;
  padding: 14px 24px 14px 12px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1;
  letter-spacing: 0;
  overflow: hidden;
  outline: none;
  opacity: 0;
  pointer-events: none;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center; }
  .fontx--default .mdl-snackbar__action {
    font-size: 14px; }
  .fontx--x2 .mdl-snackbar__action {
    font-size: 16px; }

.mdl-snackbar__action::-moz-focus-inner {
  border: 0; }

.mdl-snackbar__action:not([aria-hidden]) {
  opacity: 1;
  pointer-events: auto; }

.mdl-spinner {
  display: inline-block;
  position: relative;
  width: 28px;
  height: 28px; }

.mdl-spinner:not(.is-upgraded).is-active:after {
  content: "Loading..."; }

.mdl-spinner.is-upgraded.is-active {
  -webkit-animation: mdl-spinner__container-rotate 1568.23529412ms linear infinite;
  animation: mdl-spinner__container-rotate 1568.23529412ms linear infinite; }

@-webkit-keyframes mdl-spinner__container-rotate {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes mdl-spinner__container-rotate {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

.mdl-spinner__layer {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0; }

.mdl-spinner__layer-1 {
  border-color: #42a5f5; }

.mdl-spinner--single-color .mdl-spinner__layer-1 {
  border-color: #ffeb3b; }

.mdl-spinner.is-active .mdl-spinner__layer-1 {
  -webkit-animation: mdl-spinner__fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, mdl-spinner__layer-1-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
  animation: mdl-spinner__fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, mdl-spinner__layer-1-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both; }

.mdl-spinner__layer-2 {
  border-color: #f44336; }

.mdl-spinner--single-color .mdl-spinner__layer-2 {
  border-color: #ffeb3b; }

.mdl-spinner.is-active .mdl-spinner__layer-2 {
  -webkit-animation: mdl-spinner__fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, mdl-spinner__layer-2-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
  animation: mdl-spinner__fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, mdl-spinner__layer-2-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both; }

.mdl-spinner__layer-3 {
  border-color: #fdd835; }

.mdl-spinner--single-color .mdl-spinner__layer-3 {
  border-color: #ffeb3b; }

.mdl-spinner.is-active .mdl-spinner__layer-3 {
  -webkit-animation: mdl-spinner__fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, mdl-spinner__layer-3-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
  animation: mdl-spinner__fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, mdl-spinner__layer-3-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both; }

.mdl-spinner__layer-4 {
  border-color: #4caf50; }

.mdl-spinner--single-color .mdl-spinner__layer-4 {
  border-color: #ffeb3b; }

.mdl-spinner.is-active .mdl-spinner__layer-4 {
  -webkit-animation: mdl-spinner__fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, mdl-spinner__layer-4-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
  animation: mdl-spinner__fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, mdl-spinner__layer-4-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both; }

@-webkit-keyframes mdl-spinner__fill-unfill-rotate {
  12.5% {
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg); }
  25% {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg); }
  37.5% {
    -webkit-transform: rotate(405deg);
    transform: rotate(405deg); }
  50% {
    -webkit-transform: rotate(540deg);
    transform: rotate(540deg); }
  62.5% {
    -webkit-transform: rotate(675deg);
    transform: rotate(675deg); }
  75% {
    -webkit-transform: rotate(810deg);
    transform: rotate(810deg); }
  87.5% {
    -webkit-transform: rotate(945deg);
    transform: rotate(945deg); }
  to {
    -webkit-transform: rotate(1080deg);
    transform: rotate(1080deg); } }

@keyframes mdl-spinner__fill-unfill-rotate {
  12.5% {
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg); }
  25% {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg); }
  37.5% {
    -webkit-transform: rotate(405deg);
    transform: rotate(405deg); }
  50% {
    -webkit-transform: rotate(540deg);
    transform: rotate(540deg); }
  62.5% {
    -webkit-transform: rotate(675deg);
    transform: rotate(675deg); }
  75% {
    -webkit-transform: rotate(810deg);
    transform: rotate(810deg); }
  87.5% {
    -webkit-transform: rotate(945deg);
    transform: rotate(945deg); }
  to {
    -webkit-transform: rotate(1080deg);
    transform: rotate(1080deg); } }

@-webkit-keyframes mdl-spinner__layer-1-fade-in-out {
  from,
  25% {
    opacity: .99; }
  26%,
  89% {
    opacity: 0; }
  90%,
  100% {
    opacity: .99; } }

@keyframes mdl-spinner__layer-1-fade-in-out {
  from,
  25% {
    opacity: .99; }
  26%,
  89% {
    opacity: 0; }
  90%,
  100% {
    opacity: .99; } }

@-webkit-keyframes mdl-spinner__layer-2-fade-in-out {
  from,
  15% {
    opacity: 0; }
  25%,
  50% {
    opacity: .99; }
  51% {
    opacity: 0; } }

@keyframes mdl-spinner__layer-2-fade-in-out {
  from,
  15% {
    opacity: 0; }
  25%,
  50% {
    opacity: .99; }
  51% {
    opacity: 0; } }

@-webkit-keyframes mdl-spinner__layer-3-fade-in-out {
  from,
  40% {
    opacity: 0; }
  50%,
  75% {
    opacity: .99; }
  76% {
    opacity: 0; } }

@keyframes mdl-spinner__layer-3-fade-in-out {
  from,
  40% {
    opacity: 0; }
  50%,
  75% {
    opacity: .99; }
  76% {
    opacity: 0; } }

@-webkit-keyframes mdl-spinner__layer-4-fade-in-out {
  from,
  65% {
    opacity: 0; }
  75%,
  90% {
    opacity: .99; }
  100% {
    opacity: 0; } }

@keyframes mdl-spinner__layer-4-fade-in-out {
  from,
  65% {
    opacity: 0; }
  75%,
  90% {
    opacity: .99; }
  100% {
    opacity: 0; } }

.mdl-spinner__gap-patch {
  position: absolute;
  box-sizing: border-box;
  top: 0;
  left: 45%;
  width: 10%;
  height: 100%;
  overflow: hidden;
  border-color: inherit; }

.mdl-spinner__gap-patch .mdl-spinner__circle {
  width: 1000%;
  left: -450%; }

.mdl-spinner__circle-clipper {
  display: inline-block;
  position: relative;
  width: 50%;
  height: 100%;
  overflow: hidden;
  border-color: inherit; }

.mdl-spinner__circle-clipper.mdl-spinner__left {
  float: left; }

.mdl-spinner__circle-clipper.mdl-spinner__right {
  float: right; }

.mdl-spinner__circle-clipper .mdl-spinner__circle {
  width: 200%; }

.mdl-spinner__circle {
  box-sizing: border-box;
  height: 100%;
  border-width: 3px;
  border-style: solid;
  border-color: inherit;
  border-bottom-color: transparent !important;
  border-radius: 50%;
  -webkit-animation: none;
  animation: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

.mdl-spinner__left .mdl-spinner__circle {
  border-right-color: transparent !important;
  -webkit-transform: rotate(129deg);
  transform: rotate(129deg); }

.mdl-spinner.is-active .mdl-spinner__left .mdl-spinner__circle {
  -webkit-animation: mdl-spinner__left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
  animation: mdl-spinner__left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both; }

.mdl-spinner__right .mdl-spinner__circle {
  left: -100%;
  border-left-color: transparent !important;
  -webkit-transform: rotate(-129deg);
  transform: rotate(-129deg); }

.mdl-spinner.is-active .mdl-spinner__right .mdl-spinner__circle {
  -webkit-animation: mdl-spinner__right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
  animation: mdl-spinner__right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both; }

@-webkit-keyframes mdl-spinner__left-spin {
  from {
    -webkit-transform: rotate(130deg);
    transform: rotate(130deg); }
  50% {
    -webkit-transform: rotate(-5deg);
    transform: rotate(-5deg); }
  to {
    -webkit-transform: rotate(130deg);
    transform: rotate(130deg); } }

@keyframes mdl-spinner__left-spin {
  from {
    -webkit-transform: rotate(130deg);
    transform: rotate(130deg); }
  50% {
    -webkit-transform: rotate(-5deg);
    transform: rotate(-5deg); }
  to {
    -webkit-transform: rotate(130deg);
    transform: rotate(130deg); } }

@-webkit-keyframes mdl-spinner__right-spin {
  from {
    -webkit-transform: rotate(-130deg);
    transform: rotate(-130deg); }
  50% {
    -webkit-transform: rotate(5deg);
    transform: rotate(5deg); }
  to {
    -webkit-transform: rotate(-130deg);
    transform: rotate(-130deg); } }

@keyframes mdl-spinner__right-spin {
  from {
    -webkit-transform: rotate(-130deg);
    transform: rotate(-130deg); }
  50% {
    -webkit-transform: rotate(5deg);
    transform: rotate(5deg); }
  to {
    -webkit-transform: rotate(-130deg);
    transform: rotate(-130deg); } }

.mdl-switch {
  position: relative;
  z-index: 1;
  vertical-align: middle;
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
  height: 24px;
  margin: 0;
  padding: 0;
  overflow: visible;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.mdl-switch.is-upgraded {
  padding-left: 28px; }

.mdl-switch__input {
  line-height: 24px; }

.mdl-switch.is-upgraded .mdl-switch__input {
  position: absolute;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
  opacity: 0;
  -ms-appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: none; }

.mdl-switch__track {
  background: rgba(0, 0, 0, 0.26);
  position: absolute;
  left: 0;
  top: 5px;
  height: 14px;
  width: 36px;
  border-radius: 14px;
  cursor: pointer; }

.mdl-switch.is-checked .mdl-switch__track {
  background: rgba(255, 235, 59, 0.5); }

.mdl-switch__track fieldset[disabled] .mdl-switch,
.mdl-switch.is-disabled .mdl-switch__track {
  background: rgba(0, 0, 0, 0.12);
  cursor: auto; }

.mdl-switch__thumb {
  background: #fafafa;
  position: absolute;
  left: 0;
  top: 2px;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  transition-duration: .28s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: left; }

.mdl-switch.is-checked .mdl-switch__thumb {
  background: #ffeb3b;
  left: 16px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 1px 8px 0 rgba(0, 0, 0, 0.12); }

.mdl-switch__thumb fieldset[disabled] .mdl-switch,
.mdl-switch.is-disabled .mdl-switch__thumb {
  background: #bdbdbd;
  cursor: auto; }

.mdl-switch__focus-helper {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-4px, -4px);
  transform: translate(-4px, -4px);
  display: inline-block;
  box-sizing: border-box;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: transparent; }

.mdl-switch.is-focused .mdl-switch__focus-helper {
  box-shadow: 0 0 0 20px rgba(0, 0, 0, 0.1);
  background-color: rgba(0, 0, 0, 0.1); }

.mdl-switch.is-focused.is-checked .mdl-switch__focus-helper {
  box-shadow: 0 0 0 20px rgba(255, 235, 59, 0.26);
  background-color: rgba(255, 235, 59, 0.26); }

.mdl-switch__label {
  position: relative;
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  left: 24px; }
  .fontx--default .mdl-switch__label {
    font-size: 16px; }
  .fontx--x2 .mdl-switch__label {
    font-size: 18px; }

.mdl-switch__label fieldset[disabled] .mdl-switch,
.mdl-switch.is-disabled .mdl-switch__label {
  color: #bdbdbd;
  cursor: auto; }

.mdl-switch__ripple-container {
  position: absolute;
  z-index: 2;
  top: -12px;
  left: -14px;
  box-sizing: border-box;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  cursor: pointer;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(circle, #fff, #000);
  transition-duration: .4s;
  transition-timing-function: step-end;
  transition-property: left; }

.mdl-switch__ripple-container .mdl-ripple {
  background: #ffeb3b; }

.mdl-switch__ripple-container fieldset[disabled] .mdl-switch,
.mdl-switch.is-disabled .mdl-switch__ripple-container {
  cursor: auto; }

fieldset[disabled] .mdl-switch .mdl-switch__ripple-container .mdl-ripple,
.mdl-switch.is-disabled .mdl-switch__ripple-container .mdl-ripple {
  background: 0 0; }

.mdl-switch.is-checked .mdl-switch__ripple-container {
  left: 2px; }

.mdl-tabs {
  display: block;
  width: 100%; }

.mdl-tabs__tab-bar {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: space-between;
  -ms-flex-line-pack: justify;
  align-content: space-between;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  height: 48px;
  padding: 0;
  margin: 0;
  border-bottom: 1px solid #e0e0e0; }

.mdl-tabs__tab {
  margin: 0;
  border: none;
  padding: 0 24px;
  float: left;
  position: relative;
  display: block;
  text-decoration: none;
  height: 48px;
  line-height: 48px;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.54);
  overflow: hidden; }
  .fontx--default .mdl-tabs__tab {
    font-size: 14px; }
  .fontx--x2 .mdl-tabs__tab {
    font-size: 16px; }

.mdl-tabs.is-upgraded .mdl-tabs__tab.is-active {
  color: rgba(0, 0, 0, 0.87); }

.mdl-tabs.is-upgraded .mdl-tabs__tab.is-active:after {
  height: 2px;
  width: 100%;
  display: block;
  content: " ";
  bottom: 0;
  left: 0;
  position: absolute;
  background: #ffc107;
  -webkit-animation: border-expand 0.2s cubic-bezier(0.4, 0, 0.4, 1) 0.01s alternate forwards;
  animation: border-expand 0.2s cubic-bezier(0.4, 0, 0.4, 1) 0.01s alternate forwards;
  transition: all 1s cubic-bezier(0.4, 0, 1, 1); }

.mdl-tabs__tab .mdl-tabs__ripple-container {
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  overflow: hidden; }

.mdl-tabs__tab .mdl-tabs__ripple-container .mdl-ripple {
  background: #ffeb3b; }

.mdl-tabs__panel {
  display: block; }

.mdl-tabs.is-upgraded .mdl-tabs__panel {
  display: none; }

.mdl-tabs.is-upgraded .mdl-tabs__panel.is-active {
  display: block; }

@-webkit-keyframes border-expand {
  0% {
    opacity: 0;
    width: 0; }
  100% {
    opacity: 1;
    width: 100%; } }

@keyframes border-expand {
  0% {
    opacity: 0;
    width: 0; }
  100% {
    opacity: 1;
    width: 100%; } }

.mdl-textfield {
  position: relative;
  font-size: 16px;
  display: inline-block;
  box-sizing: border-box;
  width: 300px;
  max-width: 100%;
  margin: 0;
  padding: 20px 0; }
  .fontx--default .mdl-textfield {
    font-size: 16px; }
  .fontx--x2 .mdl-textfield {
    font-size: 18px; }

.mdl-textfield .mdl-button {
  position: absolute;
  bottom: 20px; }

.mdl-textfield--align-right {
  text-align: right; }

.mdl-textfield--full-width {
  width: 100%; }

.mdl-textfield--expandable {
  min-width: 32px;
  width: auto;
  min-height: 32px; }

.mdl-textfield--expandable .mdl-button--icon {
  top: 16px; }

.mdl-textfield__input {
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  display: block;
  font-size: 16px;
  font-family: "Helvetica", "Arial", sans-serif;
  margin: 0;
  padding: 4px 0;
  width: 100%;
  background: 0 0;
  text-align: left;
  color: inherit; }
  .fontx--default .mdl-textfield__input {
    font-size: 16px; }
  .fontx--x2 .mdl-textfield__input {
    font-size: 18px; }

.mdl-textfield__input[type="number"] {
  -moz-appearance: textfield; }

.mdl-textfield__input[type="number"]::-webkit-inner-spin-button,
.mdl-textfield__input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

.mdl-textfield.is-focused .mdl-textfield__input {
  outline: none; }

.mdl-textfield.is-invalid .mdl-textfield__input {
  border-color: #d50000;
  box-shadow: none; }

fieldset[disabled] .mdl-textfield .mdl-textfield__input,
.mdl-textfield.is-disabled .mdl-textfield__input {
  background-color: transparent;
  border-bottom: 1px dotted rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.26); }

.mdl-textfield textarea.mdl-textfield__input {
  display: block; }

.mdl-textfield__label {
  bottom: 0;
  color: rgba(0, 0, 0, 0.26);
  font-size: 16px;
  left: 0;
  right: 0;
  pointer-events: none;
  position: absolute;
  display: block;
  top: 24px;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-align: left; }
  .fontx--default .mdl-textfield__label {
    font-size: 16px; }
  .fontx--x2 .mdl-textfield__label {
    font-size: 18px; }

.mdl-textfield.is-dirty .mdl-textfield__label,
.mdl-textfield.has-placeholder .mdl-textfield__label {
  visibility: hidden; }

.mdl-textfield--floating-label .mdl-textfield__label {
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1); }

.mdl-textfield--floating-label.has-placeholder .mdl-textfield__label {
  transition: none; }

fieldset[disabled] .mdl-textfield .mdl-textfield__label,
.mdl-textfield.is-disabled.is-disabled .mdl-textfield__label {
  color: rgba(0, 0, 0, 0.26); }

.mdl-textfield--floating-label.is-focused .mdl-textfield__label,
.mdl-textfield--floating-label.is-dirty .mdl-textfield__label,
.mdl-textfield--floating-label.has-placeholder .mdl-textfield__label {
  color: #8bc34a;
  font-size: 12px;
  top: 4px;
  visibility: visible; }
  .fontx--default .mdl-textfield--floating-label.is-focused .mdl-textfield__label, .fontx--default
  .mdl-textfield--floating-label.is-dirty .mdl-textfield__label, .fontx--default
  .mdl-textfield--floating-label.has-placeholder .mdl-textfield__label {
    font-size: 12px; }
  .fontx--x2 .mdl-textfield--floating-label.is-focused .mdl-textfield__label, .fontx--x2
  .mdl-textfield--floating-label.is-dirty .mdl-textfield__label, .fontx--x2
  .mdl-textfield--floating-label.has-placeholder .mdl-textfield__label {
    font-size: 14px; }

.mdl-textfield--floating-label.is-focused .mdl-textfield__expandable-holder .mdl-textfield__label,
.mdl-textfield--floating-label.is-dirty .mdl-textfield__expandable-holder .mdl-textfield__label,
.mdl-textfield--floating-label.has-placeholder .mdl-textfield__expandable-holder .mdl-textfield__label {
  top: -16px; }

.mdl-textfield--floating-label.is-invalid .mdl-textfield__label {
  color: #d50000;
  font-size: 12px; }

.mdl-textfield__label:after {
  background-color: #8bc34a;
  bottom: 20px;
  content: '';
  height: 2px;
  left: 45%;
  position: absolute;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  visibility: hidden;
  width: 10px; }

.mdl-textfield.is-focused .mdl-textfield__label:after {
  left: 0;
  visibility: visible;
  width: 100%; }

.mdl-textfield.is-invalid .mdl-textfield__label:after {
  background-color: #d50000; }

.mdl-textfield__error {
  color: #d50000;
  position: absolute;
  font-size: 12px;
  margin-top: 3px;
  visibility: hidden;
  display: block; }
  .fontx--default .mdl-textfield__error {
    font-size: 12px; }
  .fontx--x2 .mdl-textfield__error {
    font-size: 14px; }

.mdl-textfield.is-invalid .mdl-textfield__error {
  visibility: visible; }

.mdl-textfield__expandable-holder {
  display: inline-block;
  position: relative;
  margin-left: 32px;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  display: inline-block;
  max-width: .1px; }

.mdl-textfield.is-focused .mdl-textfield__expandable-holder,
.mdl-textfield.is-dirty .mdl-textfield__expandable-holder {
  max-width: 600px; }

.mdl-textfield__expandable-holder .mdl-textfield__label:after {
  bottom: 0; }

.mdl-tooltip {
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: top center;
  transform-origin: top center;
  z-index: 999;
  background: rgba(97, 97, 97, 0.9);
  border-radius: 2px;
  color: #fff;
  display: inline-block;
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  max-width: 170px;
  position: fixed;
  top: -500px;
  left: -500px;
  padding: 8px;
  text-align: center; }

.mdl-tooltip.is-active {
  -webkit-animation: pulse 200ms cubic-bezier(0, 0, 0.2, 1) forwards;
  animation: pulse 200ms cubic-bezier(0, 0, 0.2, 1) forwards; }

.mdl-tooltip--large {
  line-height: 14px;
  font-size: 14px;
  padding: 16px; }
  .fontx--default .mdl-tooltip--large {
    font-size: 14px; }
  .fontx--x2 .mdl-tooltip--large {
    font-size: 16px; }

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0; }
  50% {
    -webkit-transform: scale(0.99);
    transform: scale(0.99); }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
    visibility: visible; } }

@keyframes pulse {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0; }
  50% {
    -webkit-transform: scale(0.99);
    transform: scale(0.99); }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
    visibility: visible; } }

.mdl-shadow--2dp {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12); }

.mdl-shadow--3dp {
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 1px 8px 0 rgba(0, 0, 0, 0.12); }

.mdl-shadow--4dp {
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2); }

.mdl-shadow--6dp {
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2); }

.mdl-shadow--8dp {
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2); }

.mdl-shadow--16dp {
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2); }

.mdl-shadow--24dp {
  box-shadow: 0 9px 46px 8px rgba(0, 0, 0, 0.14), 0 11px 15px -7px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.2); }

.mdl-grid {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  margin: 0 auto;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch; }

.mdl-grid.mdl-grid--no-spacing {
  padding: 0; }

.mdl-cell {
  box-sizing: border-box; }

.mdl-cell--top {
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start; }

.mdl-cell--middle {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center; }

.mdl-cell--bottom {
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end; }

.mdl-cell--stretch {
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch; }

.mdl-grid.mdl-grid--no-spacing > .mdl-cell {
  margin: 0; }

.mdl-cell--order-1 {
  -webkit-order: 1;
  -ms-flex-order: 1;
  order: 1; }

.mdl-cell--order-2 {
  -webkit-order: 2;
  -ms-flex-order: 2;
  order: 2; }

.mdl-cell--order-3 {
  -webkit-order: 3;
  -ms-flex-order: 3;
  order: 3; }

.mdl-cell--order-4 {
  -webkit-order: 4;
  -ms-flex-order: 4;
  order: 4; }

.mdl-cell--order-5 {
  -webkit-order: 5;
  -ms-flex-order: 5;
  order: 5; }

.mdl-cell--order-6 {
  -webkit-order: 6;
  -ms-flex-order: 6;
  order: 6; }

.mdl-cell--order-7 {
  -webkit-order: 7;
  -ms-flex-order: 7;
  order: 7; }

.mdl-cell--order-8 {
  -webkit-order: 8;
  -ms-flex-order: 8;
  order: 8; }

.mdl-cell--order-9 {
  -webkit-order: 9;
  -ms-flex-order: 9;
  order: 9; }

.mdl-cell--order-10 {
  -webkit-order: 10;
  -ms-flex-order: 10;
  order: 10; }

.mdl-cell--order-11 {
  -webkit-order: 11;
  -ms-flex-order: 11;
  order: 11; }

.mdl-cell--order-12 {
  -webkit-order: 12;
  -ms-flex-order: 12;
  order: 12; }

@media (max-width: 479px) {
  .mdl-grid {
    padding: 8px; }
  .mdl-cell {
    margin: 8px;
    width: calc(100% - 16px); }
  .mdl-grid--no-spacing > .mdl-cell {
    width: 100%; }
  .mdl-cell--hide-phone {
    display: none !important; }
  .mdl-cell--order-1-phone.mdl-cell--order-1-phone {
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1; }
  .mdl-cell--order-2-phone.mdl-cell--order-2-phone {
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2; }
  .mdl-cell--order-3-phone.mdl-cell--order-3-phone {
    -webkit-order: 3;
    -ms-flex-order: 3;
    order: 3; }
  .mdl-cell--order-4-phone.mdl-cell--order-4-phone {
    -webkit-order: 4;
    -ms-flex-order: 4;
    order: 4; }
  .mdl-cell--order-5-phone.mdl-cell--order-5-phone {
    -webkit-order: 5;
    -ms-flex-order: 5;
    order: 5; }
  .mdl-cell--order-6-phone.mdl-cell--order-6-phone {
    -webkit-order: 6;
    -ms-flex-order: 6;
    order: 6; }
  .mdl-cell--order-7-phone.mdl-cell--order-7-phone {
    -webkit-order: 7;
    -ms-flex-order: 7;
    order: 7; }
  .mdl-cell--order-8-phone.mdl-cell--order-8-phone {
    -webkit-order: 8;
    -ms-flex-order: 8;
    order: 8; }
  .mdl-cell--order-9-phone.mdl-cell--order-9-phone {
    -webkit-order: 9;
    -ms-flex-order: 9;
    order: 9; }
  .mdl-cell--order-10-phone.mdl-cell--order-10-phone {
    -webkit-order: 10;
    -ms-flex-order: 10;
    order: 10; }
  .mdl-cell--order-11-phone.mdl-cell--order-11-phone {
    -webkit-order: 11;
    -ms-flex-order: 11;
    order: 11; }
  .mdl-cell--order-12-phone.mdl-cell--order-12-phone {
    -webkit-order: 12;
    -ms-flex-order: 12;
    order: 12; }
  .mdl-cell--1-col,
  .mdl-cell--1-col-phone.mdl-cell--1-col-phone {
    width: calc(25% - 16px); }
  .mdl-grid--no-spacing > .mdl-cell--1-col,
  .mdl-grid--no-spacing > .mdl-cell--1-col-phone.mdl-cell--1-col-phone {
    width: 25%; }
  .mdl-cell--2-col,
  .mdl-cell--2-col-phone.mdl-cell--2-col-phone {
    width: calc(50% - 16px); }
  .mdl-grid--no-spacing > .mdl-cell--2-col,
  .mdl-grid--no-spacing > .mdl-cell--2-col-phone.mdl-cell--2-col-phone {
    width: 50%; }
  .mdl-cell--3-col,
  .mdl-cell--3-col-phone.mdl-cell--3-col-phone {
    width: calc(75% - 16px); }
  .mdl-grid--no-spacing > .mdl-cell--3-col,
  .mdl-grid--no-spacing > .mdl-cell--3-col-phone.mdl-cell--3-col-phone {
    width: 75%; }
  .mdl-cell--4-col,
  .mdl-cell--4-col-phone.mdl-cell--4-col-phone {
    width: calc(100% - 16px); }
  .mdl-grid--no-spacing > .mdl-cell--4-col,
  .mdl-grid--no-spacing > .mdl-cell--4-col-phone.mdl-cell--4-col-phone {
    width: 100%; }
  .mdl-cell--5-col,
  .mdl-cell--5-col-phone.mdl-cell--5-col-phone {
    width: calc(100% - 16px); }
  .mdl-grid--no-spacing > .mdl-cell--5-col,
  .mdl-grid--no-spacing > .mdl-cell--5-col-phone.mdl-cell--5-col-phone {
    width: 100%; }
  .mdl-cell--6-col,
  .mdl-cell--6-col-phone.mdl-cell--6-col-phone {
    width: calc(100% - 16px); }
  .mdl-grid--no-spacing > .mdl-cell--6-col,
  .mdl-grid--no-spacing > .mdl-cell--6-col-phone.mdl-cell--6-col-phone {
    width: 100%; }
  .mdl-cell--7-col,
  .mdl-cell--7-col-phone.mdl-cell--7-col-phone {
    width: calc(100% - 16px); }
  .mdl-grid--no-spacing > .mdl-cell--7-col,
  .mdl-grid--no-spacing > .mdl-cell--7-col-phone.mdl-cell--7-col-phone {
    width: 100%; }
  .mdl-cell--8-col,
  .mdl-cell--8-col-phone.mdl-cell--8-col-phone {
    width: calc(100% - 16px); }
  .mdl-grid--no-spacing > .mdl-cell--8-col,
  .mdl-grid--no-spacing > .mdl-cell--8-col-phone.mdl-cell--8-col-phone {
    width: 100%; }
  .mdl-cell--9-col,
  .mdl-cell--9-col-phone.mdl-cell--9-col-phone {
    width: calc(100% - 16px); }
  .mdl-grid--no-spacing > .mdl-cell--9-col,
  .mdl-grid--no-spacing > .mdl-cell--9-col-phone.mdl-cell--9-col-phone {
    width: 100%; }
  .mdl-cell--10-col,
  .mdl-cell--10-col-phone.mdl-cell--10-col-phone {
    width: calc(100% - 16px); }
  .mdl-grid--no-spacing > .mdl-cell--10-col,
  .mdl-grid--no-spacing > .mdl-cell--10-col-phone.mdl-cell--10-col-phone {
    width: 100%; }
  .mdl-cell--11-col,
  .mdl-cell--11-col-phone.mdl-cell--11-col-phone {
    width: calc(100% - 16px); }
  .mdl-grid--no-spacing > .mdl-cell--11-col,
  .mdl-grid--no-spacing > .mdl-cell--11-col-phone.mdl-cell--11-col-phone {
    width: 100%; }
  .mdl-cell--12-col,
  .mdl-cell--12-col-phone.mdl-cell--12-col-phone {
    width: calc(100% - 16px); }
  .mdl-grid--no-spacing > .mdl-cell--12-col,
  .mdl-grid--no-spacing > .mdl-cell--12-col-phone.mdl-cell--12-col-phone {
    width: 100%; }
  .mdl-cell--1-offset,
  .mdl-cell--1-offset-phone.mdl-cell--1-offset-phone {
    margin-left: calc(25% + 8px); }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--1-offset,
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--1-offset-phone.mdl-cell--1-offset-phone {
    margin-left: 25%; }
  .mdl-cell--2-offset,
  .mdl-cell--2-offset-phone.mdl-cell--2-offset-phone {
    margin-left: calc(50% + 8px); }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--2-offset,
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--2-offset-phone.mdl-cell--2-offset-phone {
    margin-left: 50%; }
  .mdl-cell--3-offset,
  .mdl-cell--3-offset-phone.mdl-cell--3-offset-phone {
    margin-left: calc(75% + 8px); }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--3-offset,
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--3-offset-phone.mdl-cell--3-offset-phone {
    margin-left: 75%; } }

@media (min-width: 480px) and (max-width: 839px) {
  .mdl-grid {
    padding: 8px; }
  .mdl-cell {
    margin: 8px;
    width: calc(50% - 16px); }
  .mdl-grid--no-spacing > .mdl-cell {
    width: 50%; }
  .mdl-cell--hide-tablet {
    display: none !important; }
  .mdl-cell--order-1-tablet.mdl-cell--order-1-tablet {
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1; }
  .mdl-cell--order-2-tablet.mdl-cell--order-2-tablet {
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2; }
  .mdl-cell--order-3-tablet.mdl-cell--order-3-tablet {
    -webkit-order: 3;
    -ms-flex-order: 3;
    order: 3; }
  .mdl-cell--order-4-tablet.mdl-cell--order-4-tablet {
    -webkit-order: 4;
    -ms-flex-order: 4;
    order: 4; }
  .mdl-cell--order-5-tablet.mdl-cell--order-5-tablet {
    -webkit-order: 5;
    -ms-flex-order: 5;
    order: 5; }
  .mdl-cell--order-6-tablet.mdl-cell--order-6-tablet {
    -webkit-order: 6;
    -ms-flex-order: 6;
    order: 6; }
  .mdl-cell--order-7-tablet.mdl-cell--order-7-tablet {
    -webkit-order: 7;
    -ms-flex-order: 7;
    order: 7; }
  .mdl-cell--order-8-tablet.mdl-cell--order-8-tablet {
    -webkit-order: 8;
    -ms-flex-order: 8;
    order: 8; }
  .mdl-cell--order-9-tablet.mdl-cell--order-9-tablet {
    -webkit-order: 9;
    -ms-flex-order: 9;
    order: 9; }
  .mdl-cell--order-10-tablet.mdl-cell--order-10-tablet {
    -webkit-order: 10;
    -ms-flex-order: 10;
    order: 10; }
  .mdl-cell--order-11-tablet.mdl-cell--order-11-tablet {
    -webkit-order: 11;
    -ms-flex-order: 11;
    order: 11; }
  .mdl-cell--order-12-tablet.mdl-cell--order-12-tablet {
    -webkit-order: 12;
    -ms-flex-order: 12;
    order: 12; }
  .mdl-cell--1-col,
  .mdl-cell--1-col-tablet.mdl-cell--1-col-tablet {
    width: calc(12.5% - 16px); }
  .mdl-grid--no-spacing > .mdl-cell--1-col,
  .mdl-grid--no-spacing > .mdl-cell--1-col-tablet.mdl-cell--1-col-tablet {
    width: 12.5%; }
  .mdl-cell--2-col,
  .mdl-cell--2-col-tablet.mdl-cell--2-col-tablet {
    width: calc(25% - 16px); }
  .mdl-grid--no-spacing > .mdl-cell--2-col,
  .mdl-grid--no-spacing > .mdl-cell--2-col-tablet.mdl-cell--2-col-tablet {
    width: 25%; }
  .mdl-cell--3-col,
  .mdl-cell--3-col-tablet.mdl-cell--3-col-tablet {
    width: calc(37.5% - 16px); }
  .mdl-grid--no-spacing > .mdl-cell--3-col,
  .mdl-grid--no-spacing > .mdl-cell--3-col-tablet.mdl-cell--3-col-tablet {
    width: 37.5%; }
  .mdl-cell--4-col,
  .mdl-cell--4-col-tablet.mdl-cell--4-col-tablet {
    width: calc(50% - 16px); }
  .mdl-grid--no-spacing > .mdl-cell--4-col,
  .mdl-grid--no-spacing > .mdl-cell--4-col-tablet.mdl-cell--4-col-tablet {
    width: 50%; }
  .mdl-cell--5-col,
  .mdl-cell--5-col-tablet.mdl-cell--5-col-tablet {
    width: calc(62.5% - 16px); }
  .mdl-grid--no-spacing > .mdl-cell--5-col,
  .mdl-grid--no-spacing > .mdl-cell--5-col-tablet.mdl-cell--5-col-tablet {
    width: 62.5%; }
  .mdl-cell--6-col,
  .mdl-cell--6-col-tablet.mdl-cell--6-col-tablet {
    width: calc(75% - 16px); }
  .mdl-grid--no-spacing > .mdl-cell--6-col,
  .mdl-grid--no-spacing > .mdl-cell--6-col-tablet.mdl-cell--6-col-tablet {
    width: 75%; }
  .mdl-cell--7-col,
  .mdl-cell--7-col-tablet.mdl-cell--7-col-tablet {
    width: calc(87.5% - 16px); }
  .mdl-grid--no-spacing > .mdl-cell--7-col,
  .mdl-grid--no-spacing > .mdl-cell--7-col-tablet.mdl-cell--7-col-tablet {
    width: 87.5%; }
  .mdl-cell--8-col,
  .mdl-cell--8-col-tablet.mdl-cell--8-col-tablet {
    width: calc(100% - 16px); }
  .mdl-grid--no-spacing > .mdl-cell--8-col,
  .mdl-grid--no-spacing > .mdl-cell--8-col-tablet.mdl-cell--8-col-tablet {
    width: 100%; }
  .mdl-cell--9-col,
  .mdl-cell--9-col-tablet.mdl-cell--9-col-tablet {
    width: calc(100% - 16px); }
  .mdl-grid--no-spacing > .mdl-cell--9-col,
  .mdl-grid--no-spacing > .mdl-cell--9-col-tablet.mdl-cell--9-col-tablet {
    width: 100%; }
  .mdl-cell--10-col,
  .mdl-cell--10-col-tablet.mdl-cell--10-col-tablet {
    width: calc(100% - 16px); }
  .mdl-grid--no-spacing > .mdl-cell--10-col,
  .mdl-grid--no-spacing > .mdl-cell--10-col-tablet.mdl-cell--10-col-tablet {
    width: 100%; }
  .mdl-cell--11-col,
  .mdl-cell--11-col-tablet.mdl-cell--11-col-tablet {
    width: calc(100% - 16px); }
  .mdl-grid--no-spacing > .mdl-cell--11-col,
  .mdl-grid--no-spacing > .mdl-cell--11-col-tablet.mdl-cell--11-col-tablet {
    width: 100%; }
  .mdl-cell--12-col,
  .mdl-cell--12-col-tablet.mdl-cell--12-col-tablet {
    width: calc(100% - 16px); }
  .mdl-grid--no-spacing > .mdl-cell--12-col,
  .mdl-grid--no-spacing > .mdl-cell--12-col-tablet.mdl-cell--12-col-tablet {
    width: 100%; }
  .mdl-cell--1-offset,
  .mdl-cell--1-offset-tablet.mdl-cell--1-offset-tablet {
    margin-left: calc(12.5% + 8px); }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--1-offset,
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--1-offset-tablet.mdl-cell--1-offset-tablet {
    margin-left: 12.5%; }
  .mdl-cell--2-offset,
  .mdl-cell--2-offset-tablet.mdl-cell--2-offset-tablet {
    margin-left: calc(25% + 8px); }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--2-offset,
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--2-offset-tablet.mdl-cell--2-offset-tablet {
    margin-left: 25%; }
  .mdl-cell--3-offset,
  .mdl-cell--3-offset-tablet.mdl-cell--3-offset-tablet {
    margin-left: calc(37.5% + 8px); }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--3-offset,
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--3-offset-tablet.mdl-cell--3-offset-tablet {
    margin-left: 37.5%; }
  .mdl-cell--4-offset,
  .mdl-cell--4-offset-tablet.mdl-cell--4-offset-tablet {
    margin-left: calc(50% + 8px); }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--4-offset,
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--4-offset-tablet.mdl-cell--4-offset-tablet {
    margin-left: 50%; }
  .mdl-cell--5-offset,
  .mdl-cell--5-offset-tablet.mdl-cell--5-offset-tablet {
    margin-left: calc(62.5% + 8px); }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--5-offset,
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--5-offset-tablet.mdl-cell--5-offset-tablet {
    margin-left: 62.5%; }
  .mdl-cell--6-offset,
  .mdl-cell--6-offset-tablet.mdl-cell--6-offset-tablet {
    margin-left: calc(75% + 8px); }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--6-offset,
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--6-offset-tablet.mdl-cell--6-offset-tablet {
    margin-left: 75%; }
  .mdl-cell--7-offset,
  .mdl-cell--7-offset-tablet.mdl-cell--7-offset-tablet {
    margin-left: calc(87.5% + 8px); }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--7-offset,
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--7-offset-tablet.mdl-cell--7-offset-tablet {
    margin-left: 87.5%; } }

@media (min-width: 840px) {
  .mdl-grid {
    padding: 8px; }
  .mdl-cell {
    margin: 8px;
    width: calc(33.3333333333% - 16px); }
  .mdl-grid--no-spacing > .mdl-cell {
    width: 33.3333333333%; }
  .mdl-cell--hide-desktop {
    display: none !important; }
  .mdl-cell--order-1-desktop.mdl-cell--order-1-desktop {
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1; }
  .mdl-cell--order-2-desktop.mdl-cell--order-2-desktop {
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2; }
  .mdl-cell--order-3-desktop.mdl-cell--order-3-desktop {
    -webkit-order: 3;
    -ms-flex-order: 3;
    order: 3; }
  .mdl-cell--order-4-desktop.mdl-cell--order-4-desktop {
    -webkit-order: 4;
    -ms-flex-order: 4;
    order: 4; }
  .mdl-cell--order-5-desktop.mdl-cell--order-5-desktop {
    -webkit-order: 5;
    -ms-flex-order: 5;
    order: 5; }
  .mdl-cell--order-6-desktop.mdl-cell--order-6-desktop {
    -webkit-order: 6;
    -ms-flex-order: 6;
    order: 6; }
  .mdl-cell--order-7-desktop.mdl-cell--order-7-desktop {
    -webkit-order: 7;
    -ms-flex-order: 7;
    order: 7; }
  .mdl-cell--order-8-desktop.mdl-cell--order-8-desktop {
    -webkit-order: 8;
    -ms-flex-order: 8;
    order: 8; }
  .mdl-cell--order-9-desktop.mdl-cell--order-9-desktop {
    -webkit-order: 9;
    -ms-flex-order: 9;
    order: 9; }
  .mdl-cell--order-10-desktop.mdl-cell--order-10-desktop {
    -webkit-order: 10;
    -ms-flex-order: 10;
    order: 10; }
  .mdl-cell--order-11-desktop.mdl-cell--order-11-desktop {
    -webkit-order: 11;
    -ms-flex-order: 11;
    order: 11; }
  .mdl-cell--order-12-desktop.mdl-cell--order-12-desktop {
    -webkit-order: 12;
    -ms-flex-order: 12;
    order: 12; }
  .mdl-cell--1-col,
  .mdl-cell--1-col-desktop.mdl-cell--1-col-desktop {
    width: calc(8.3333333333% - 16px); }
  .mdl-grid--no-spacing > .mdl-cell--1-col,
  .mdl-grid--no-spacing > .mdl-cell--1-col-desktop.mdl-cell--1-col-desktop {
    width: 8.3333333333%; }
  .mdl-cell--2-col,
  .mdl-cell--2-col-desktop.mdl-cell--2-col-desktop {
    width: calc(16.6666666667% - 16px); }
  .mdl-grid--no-spacing > .mdl-cell--2-col,
  .mdl-grid--no-spacing > .mdl-cell--2-col-desktop.mdl-cell--2-col-desktop {
    width: 16.6666666667%; }
  .mdl-cell--3-col,
  .mdl-cell--3-col-desktop.mdl-cell--3-col-desktop {
    width: calc(25% - 16px); }
  .mdl-grid--no-spacing > .mdl-cell--3-col,
  .mdl-grid--no-spacing > .mdl-cell--3-col-desktop.mdl-cell--3-col-desktop {
    width: 25%; }
  .mdl-cell--4-col,
  .mdl-cell--4-col-desktop.mdl-cell--4-col-desktop {
    width: calc(33.3333333333% - 16px); }
  .mdl-grid--no-spacing > .mdl-cell--4-col,
  .mdl-grid--no-spacing > .mdl-cell--4-col-desktop.mdl-cell--4-col-desktop {
    width: 33.3333333333%; }
  .mdl-cell--5-col,
  .mdl-cell--5-col-desktop.mdl-cell--5-col-desktop {
    width: calc(41.6666666667% - 16px); }
  .mdl-grid--no-spacing > .mdl-cell--5-col,
  .mdl-grid--no-spacing > .mdl-cell--5-col-desktop.mdl-cell--5-col-desktop {
    width: 41.6666666667%; }
  .mdl-cell--6-col,
  .mdl-cell--6-col-desktop.mdl-cell--6-col-desktop {
    width: calc(50% - 16px); }
  .mdl-grid--no-spacing > .mdl-cell--6-col,
  .mdl-grid--no-spacing > .mdl-cell--6-col-desktop.mdl-cell--6-col-desktop {
    width: 50%; }
  .mdl-cell--7-col,
  .mdl-cell--7-col-desktop.mdl-cell--7-col-desktop {
    width: calc(58.3333333333% - 16px); }
  .mdl-grid--no-spacing > .mdl-cell--7-col,
  .mdl-grid--no-spacing > .mdl-cell--7-col-desktop.mdl-cell--7-col-desktop {
    width: 58.3333333333%; }
  .mdl-cell--8-col,
  .mdl-cell--8-col-desktop.mdl-cell--8-col-desktop {
    width: calc(66.6666666667% - 16px); }
  .mdl-grid--no-spacing > .mdl-cell--8-col,
  .mdl-grid--no-spacing > .mdl-cell--8-col-desktop.mdl-cell--8-col-desktop {
    width: 66.6666666667%; }
  .mdl-cell--9-col,
  .mdl-cell--9-col-desktop.mdl-cell--9-col-desktop {
    width: calc(75% - 16px); }
  .mdl-grid--no-spacing > .mdl-cell--9-col,
  .mdl-grid--no-spacing > .mdl-cell--9-col-desktop.mdl-cell--9-col-desktop {
    width: 75%; }
  .mdl-cell--10-col,
  .mdl-cell--10-col-desktop.mdl-cell--10-col-desktop {
    width: calc(83.3333333333% - 16px); }
  .mdl-grid--no-spacing > .mdl-cell--10-col,
  .mdl-grid--no-spacing > .mdl-cell--10-col-desktop.mdl-cell--10-col-desktop {
    width: 83.3333333333%; }
  .mdl-cell--11-col,
  .mdl-cell--11-col-desktop.mdl-cell--11-col-desktop {
    width: calc(91.6666666667% - 16px); }
  .mdl-grid--no-spacing > .mdl-cell--11-col,
  .mdl-grid--no-spacing > .mdl-cell--11-col-desktop.mdl-cell--11-col-desktop {
    width: 91.6666666667%; }
  .mdl-cell--12-col,
  .mdl-cell--12-col-desktop.mdl-cell--12-col-desktop {
    width: calc(100% - 16px); }
  .mdl-grid--no-spacing > .mdl-cell--12-col,
  .mdl-grid--no-spacing > .mdl-cell--12-col-desktop.mdl-cell--12-col-desktop {
    width: 100%; }
  .mdl-cell--1-offset,
  .mdl-cell--1-offset-desktop.mdl-cell--1-offset-desktop {
    margin-left: calc(8.3333333333% + 8px); }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--1-offset,
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--1-offset-desktop.mdl-cell--1-offset-desktop {
    margin-left: 8.3333333333%; }
  .mdl-cell--2-offset,
  .mdl-cell--2-offset-desktop.mdl-cell--2-offset-desktop {
    margin-left: calc(16.6666666667% + 8px); }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--2-offset,
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--2-offset-desktop.mdl-cell--2-offset-desktop {
    margin-left: 16.6666666667%; }
  .mdl-cell--3-offset,
  .mdl-cell--3-offset-desktop.mdl-cell--3-offset-desktop {
    margin-left: calc(25% + 8px); }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--3-offset,
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--3-offset-desktop.mdl-cell--3-offset-desktop {
    margin-left: 25%; }
  .mdl-cell--4-offset,
  .mdl-cell--4-offset-desktop.mdl-cell--4-offset-desktop {
    margin-left: calc(33.3333333333% + 8px); }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--4-offset,
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--4-offset-desktop.mdl-cell--4-offset-desktop {
    margin-left: 33.3333333333%; }
  .mdl-cell--5-offset,
  .mdl-cell--5-offset-desktop.mdl-cell--5-offset-desktop {
    margin-left: calc(41.6666666667% + 8px); }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--5-offset,
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--5-offset-desktop.mdl-cell--5-offset-desktop {
    margin-left: 41.6666666667%; }
  .mdl-cell--6-offset,
  .mdl-cell--6-offset-desktop.mdl-cell--6-offset-desktop {
    margin-left: calc(50% + 8px); }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--6-offset,
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--6-offset-desktop.mdl-cell--6-offset-desktop {
    margin-left: 50%; }
  .mdl-cell--7-offset,
  .mdl-cell--7-offset-desktop.mdl-cell--7-offset-desktop {
    margin-left: calc(58.3333333333% + 8px); }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--7-offset,
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--7-offset-desktop.mdl-cell--7-offset-desktop {
    margin-left: 58.3333333333%; }
  .mdl-cell--8-offset,
  .mdl-cell--8-offset-desktop.mdl-cell--8-offset-desktop {
    margin-left: calc(66.6666666667% + 8px); }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--8-offset,
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--8-offset-desktop.mdl-cell--8-offset-desktop {
    margin-left: 66.6666666667%; }
  .mdl-cell--9-offset,
  .mdl-cell--9-offset-desktop.mdl-cell--9-offset-desktop {
    margin-left: calc(75% + 8px); }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--9-offset,
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--9-offset-desktop.mdl-cell--9-offset-desktop {
    margin-left: 75%; }
  .mdl-cell--10-offset,
  .mdl-cell--10-offset-desktop.mdl-cell--10-offset-desktop {
    margin-left: calc(83.3333333333% + 8px); }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--10-offset,
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--10-offset-desktop.mdl-cell--10-offset-desktop {
    margin-left: 83.3333333333%; }
  .mdl-cell--11-offset,
  .mdl-cell--11-offset-desktop.mdl-cell--11-offset-desktop {
    margin-left: calc(91.6666666667% + 8px); }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--11-offset,
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--11-offset-desktop.mdl-cell--11-offset-desktop {
    margin-left: 91.6666666667%; } }

body {
  margin: 0; }

.styleguide-demo h1 {
  margin: 48px 24px 0; }

.styleguide-demo h1:after {
  content: '';
  display: block;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  margin-top: 24px; }

.styleguide-demo {
  opacity: 0;
  transition: opacity .6s ease; }

.styleguide-masthead {
  height: 256px;
  background: #212121;
  padding: 115px 16px 0; }

.styleguide-container {
  position: relative;
  max-width: 960px;
  width: 100%; }

.styleguide-title {
  color: #fff;
  bottom: auto;
  position: relative;
  font-size: 56px;
  font-weight: 300;
  line-height: 1;
  letter-spacing: -.02em; }

.styleguide-title:after {
  border-bottom: 0; }

.styleguide-title span {
  font-weight: 300; }

.mdl-styleguide .mdl-layout__drawer .mdl-navigation__link {
  padding: 10px 24px; }

.demosLoaded .styleguide-demo {
  opacity: 1; }

iframe {
  display: block;
  width: 100%;
  border: none; }

iframe.heightSet {
  overflow: hidden; }

.demo-wrapper {
  margin: 24px; }

.demo-wrapper iframe {
  border: 1px solid rgba(0, 0, 0, 0.5); }

.hero_slider {
  background-color: #ddd;
  width: 100%;
  min-height: 220px;
  position: relative; }
  @media only screen and (min-width: 480px) {
    .hero_slider {
      min-height: 434px; } }
  @media only screen and (min-width: 1025px) {
    .hero_slider {
      min-height: 815px; } }
  .hero_slider ul {
    margin: 0;
    padding: 0;
    list-style: none; }
    .hero_slider ul li {
      position: relative;
      margin: 0;
      width: 100%;
      display: flex; }
      .hero_slider ul li .box_dark {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5); }
        @media only screen and (min-width: 480px) {
          .hero_slider ul li .box_dark {
            min-height: 434px; } }
        @media only screen and (min-width: 1025px) {
          .hero_slider ul li .box_dark {
            min-height: 815px; } }
  .hero_slider .box_slider {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 220px; }
    @media only screen and (min-width: 480px) {
      .hero_slider .box_slider {
        min-height: 434px; } }
    @media only screen and (min-width: 1025px) {
      .hero_slider .box_slider {
        min-height: 815px; } }
    .hero_slider .box_slider p {
      color: #fff;
      font-family: 'Roboto-Bold';
      font-size: 24px;
      line-height: 1.4;
      letter-spacing: -0.75px;
      padding: 20px; }
      @media only screen and (min-width: 480px) {
        .hero_slider .box_slider p {
          font-size: 48px; } }
      @media only screen and (min-width: 1025px) {
        .hero_slider .box_slider p {
          font-size: 96px; }
          .fontx--default .hero_slider .box_slider p {
            font-size: 98px; }
          .fontx--x2 .hero_slider .box_slider p {
            font-size: 98px; } }
    .hero_slider .box_slider a.mdl-button.box_link_hero {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      height: 40px;
      width: 40px;
      padding: 0;
      margin: 20px;
      min-width: initial;
      border: 1px solid #fff; }
      @media only screen and (min-width: 480px) {
        .hero_slider .box_slider a.mdl-button.box_link_hero {
          height: 60px;
          width: 60px; } }
      .hero_slider .box_slider a.mdl-button.box_link_hero span {
        color: #fff; }
  .hero_slider .slide__action {
    position: absolute;
    bottom: 40px;
    right: 40px;
    z-index: 100; }
    .hero_slider .slide__action a {
      font-family: 'Roboto Condensed Bold';
      text-decoration: none;
      font-size: 60px;
      color: transparent;
      -webkit-text-stroke-color: #fff;
      -webkit-text-stroke-width: 2px;
      margin-right: 10px; }
      .hero_slider .slide__action a:last-child {
        margin-right: 0; }
      .hero_slider .slide__action a.cycle-pager-active {
        -webkit-text-stroke-color: #fff;
        -webkit-text-stroke-width: 0;
        color: #fff;
        font-size: 60px; }

.menu_primary {
  display: none;
  padding: 0 20px;
  justify-content: space-between;
  min-height: 90px;
  background-color: #33691e; }
  @media only screen and (min-width: 1025px) {
    .menu_primary {
      overflow: hidden;
      display: flex; } }
  .menu_primary a.mdl-button {
    display: flex;
    align-items: center;
    color: #fff;
    border-bottom: 3px solid transparent;
    transition: border 80ms linear;
    font-family: "Roboto-Regular";
    letter-spacing: 1.25px;
    font-size: 14px;
    line-height: 1.4;
    height: 90px;
    max-width: initial;
    text-decoration: none;
    padding: 0 5px;
    overflow: initial;
    text-align: center; }
    .fontx--default .menu_primary a.mdl-button {
      font-size: 16px; }
    .fontx--x2 .menu_primary a.mdl-button {
      font-size: 16px; }
    .menu_primary a.mdl-button span {
      text-transform: uppercase; }
    .menu_primary a.mdl-button:hover {
      background-color: transparent;
      border-bottom: 3px solid #fff; }

section.news_section {
  padding: 50px calc(8% - 16px) 50px; }
  @media only screen and (min-width: 1025px) {
    section.news_section {
      padding: 108px calc(4% - 16px) 50px; } }
  .theme--default section.news_section {
    background-color: #fafafa; }
  .theme--dark section.news_section {
    background-color: #121212; }
  section.news_section .news_title {
    margin-bottom: 50px; }
    section.news_section .news_title p {
      position: relative;
      font-family: "Roboto Condensed Bold";
      font-size: 60px;
      color: #8bc34a;
      margin-bottom: 0;
      line-height: 1.2; }
      .fontx--default section.news_section .news_title p {
        font-size: 70px; }
      .fontx--x2 section.news_section .news_title p {
        font-size: 70px; }
      section.news_section .news_title p::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100px;
        height: 1px;
        border-bottom: 5px solid #64dd17; }
  section.news_section .box_comp {
    display: flex;
    flex-direction: column; }
    section.news_section .box_comp a {
      text-decoration: none; }
    section.news_section .box_comp .box_img {
      max-width: 100%;
      object-fit: cover;
      width: 100%;
      height: 352px; }
      @media only screen and (min-width: 1025px) {
        section.news_section .box_comp .box_img {
          width: 624px; } }
    section.news_section .box_comp .copy {
      padding: 28px 0; }
      section.news_section .box_comp .copy a {
        text-decoration: none; }
      section.news_section .box_comp .copy p.tag {
        font-family: "Roboto-Regular";
        font-size: 12px;
        color: #8bc34a;
        line-height: 16px;
        letter-spacing: 0.4px;
        display: flex;
        flex-direction: row;
        align-items: center; }
        .fontx--default section.news_section .box_comp .copy p.tag {
          font-size: 14px; }
        .fontx--x2 section.news_section .box_comp .copy p.tag {
          font-size: 14px; }
        section.news_section .box_comp .copy p.tag span {
          padding-right: 5px; }
      section.news_section .box_comp .copy p.title {
        font-family: "Roboto-Regular";
        font-size: 36px;
        line-height: 1;
        color: rgba(0, 0, 0, 0.87); }
        .fontx--default section.news_section .box_comp .copy p.title {
          font-size: 38px; }
        .fontx--x2 section.news_section .box_comp .copy p.title {
          font-size: 38px; }
        .theme--default section.news_section .box_comp .copy p.title {
          color: rgba(0, 0, 0, 0.87); }
        .theme--dark section.news_section .box_comp .copy p.title {
          color: #fff; }
      section.news_section .box_comp .copy p.date_custom {
        font-family: "Roboto Condensed Italic";
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 2px; }
        .fontx--default section.news_section .box_comp .copy p.date_custom {
          font-size: 14px; }
        .fontx--x2 section.news_section .box_comp .copy p.date_custom {
          font-size: 14px; }
        .theme--default section.news_section .box_comp .copy p.date_custom {
          color: rgba(0, 0, 0, 0.6); }
        .theme--dark section.news_section .box_comp .copy p.date_custom {
          color: rgba(255, 255, 255, 0.6); }
  section.news_section .box_new {
    display: flex;
    flex-direction: column;
    padding: 0 20px; }
    section.news_section .box_new a {
      text-decoration: none; }
    section.news_section .box_new .box_img {
      max-width: 100%;
      width: 100%;
      object-fit: cover;
      height: 120px; }
    section.news_section .box_new .copy {
      padding: 12px 0; }
      section.news_section .box_new .copy a {
        text-decoration: none; }
      section.news_section .box_new .copy p.tag {
        font-family: "Roboto-Regular";
        font-size: 12px;
        color: #8bc34a;
        line-height: 16px;
        letter-spacing: 0.4px;
        margin-bottom: 5px;
        display: flex;
        flex-direction: row;
        align-items: center; }
        .fontx--default section.news_section .box_new .copy p.tag {
          font-size: 14px; }
        .fontx--x2 section.news_section .box_new .copy p.tag {
          font-size: 14px; }
        section.news_section .box_new .copy p.tag span {
          padding-right: 5px; }
      section.news_section .box_new .copy p.title {
        font-family: "Roboto Condensed Bold";
        font-size: 1rem;
        line-height: 21px;
        letter-spacing: 0.15px;
        color: rgba(0, 0, 0, 0.6);
        margin-bottom: 5px; }
        .fontx--default section.news_section .box_new .copy p.title {
          font-size: 18px; }
        .fontx--x2 section.news_section .box_new .copy p.title {
          font-size: 18px; }
        .theme--default section.news_section .box_new .copy p.title {
          color: #fff; }
        .theme--dark section.news_section .box_new .copy p.title {
          color: #fff; }
      section.news_section .box_new .copy p.date_custom {
        font-family: "Roboto Condensed Italic";
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 2px; }
        .theme--default section.news_section .box_new .copy p.date_custom {
          color: rgba(0, 0, 0, 0.6); }
        .theme--dark section.news_section .box_new .copy p.date_custom {
          color: rgba(255, 255, 255, 0.6); }
  section.news_section .grid_news {
    display: flex;
    flex-direction: column; }
    @media only screen and (min-width: 1025px) {
      section.news_section .grid_news {
        flex-direction: row; } }
    section.news_section .grid_news .colm_news {
      flex-basis: 100%; }
      section.news_section .grid_news .colm_news.not_tablet_news {
        display: none; }
        @media only screen and (min-width: 1025px) {
          section.news_section .grid_news .colm_news.not_tablet_news {
            display: block; } }
    section.news_section .grid_news .row_news {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap; }
      section.news_section .grid_news .row_news .colm {
        flex-basis: 50%; }

section.cards_section {
  overflow: hidden; }
  @media only screen and (min-width: 1025px) {
    section.cards_section {
      overflow: initial; } }
  section.cards_section .row_cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }
    @media only screen and (min-width: 1025px) {
      section.cards_section .row_cards {
        flex-wrap: nowrap; } }
    section.cards_section .row_cards > .colm {
      flex-basis: 100%; }
      @media only screen and (min-width: 480px) {
        section.cards_section .row_cards > .colm {
          flex-basis: 50%; } }
      @media only screen and (min-width: 1025px) {
        section.cards_section .row_cards > .colm {
          flex-basis: 25%; } }

section.events_section {
  min-height: 320px;
  position: relative;
  display: flex;
  align-items: center;
  padding: 90px calc(8% - 16px); }
  @media only screen and (min-width: 480px) {
    section.events_section {
      min-height: 540px;
      padding: 108px calc(4% - 16px); } }
  .theme--default section.events_section {
    background-color: #fafafa; }
  .theme--dark section.events_section {
    background-color: #121212; }
  section.events_section::after {
    content: '';
    position: absolute;
    top: 10%;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 1;
    background: url("../images/pics/udea_bg_logo.png") center center no-repeat;
    width: auto;
    height: 630px;
    opacity: 0.04; }
    @media only screen and (min-width: 1025px) {
      section.events_section::after {
        width: 500px; } }
    .theme--default section.events_section::after {
      opacity: 0.3; }
    .theme--dark section.events_section::after {
      opacity: 0.3; }
  section.events_section .row_events {
    display: flex;
    flex-direction: column;
    flex-basis: 100%; }
    @media only screen and (min-width: 480px) {
      section.events_section .row_events {
        flex-direction: row;
        flex-wrap: nowrap; } }
    section.events_section .row_events .colm {
      flex-basis: 50%; }
  section.events_section .copy_widget {
    padding: 0 10px;
    position: relative;
    z-index: 2;
    margin-bottom: 10px; }
  section.events_section .copy_elem_mdl .box_rdio {
    padding: 10px;
    position: relative;
    z-index: 4;
    box-shadow: 0 8px 17px 2px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
    margin-bottom: 50px;
    background-color: #fff; }
    .theme--default section.events_section .copy_elem_mdl .box_rdio {
      background-color: #323232; }
    .theme--dark section.events_section .copy_elem_mdl .box_rdio {
      background-color: #323232; }
    section.events_section .copy_elem_mdl .box_rdio .head_rdio {
      display: flex;
      flex-direction: row;
      align-items: center; }
      section.events_section .copy_elem_mdl .box_rdio .head_rdio a.rdio_left {
        display: flex;
        margin-right: auto; }
      section.events_section .copy_elem_mdl .box_rdio .head_rdio a.rdio_right {
        display: flex;
        margin-left: auto; }
        .theme--default section.events_section .copy_elem_mdl .box_rdio .head_rdio a.rdio_right {
          color: rgba(255, 255, 255, 0.6); }
        .theme--dark section.events_section .copy_elem_mdl .box_rdio .head_rdio a.rdio_right {
          color: rgba(255, 255, 255, 0.6); }
      section.events_section .copy_elem_mdl .box_rdio .head_rdio .ms_tablet {
        display: block; }
        @media only screen and (min-width: 1025px) {
          section.events_section .copy_elem_mdl .box_rdio .head_rdio .ms_tablet {
            display: none; } }
        section.events_section .copy_elem_mdl .box_rdio .head_rdio .ms_tablet ul.playlist_custom {
          width: 300px; }
          section.events_section .copy_elem_mdl .box_rdio .head_rdio .ms_tablet ul.playlist_custom li.mdl-menu__item {
            display: flex;
            white-space: wrap;
            overflow: initial;
            height: initial;
            min-height: 48px; }
            section.events_section .copy_elem_mdl .box_rdio .head_rdio .ms_tablet ul.playlist_custom li.mdl-menu__item a {
              display: flex;
              flex-direction: row;
              text-decoration: none;
              width: 100%;
              font-size: 14px;
              align-items: center;
              color: rgba(0, 0, 0, 0.87); }
              .theme--default section.events_section .copy_elem_mdl .box_rdio .head_rdio .ms_tablet ul.playlist_custom li.mdl-menu__item a {
                color: rgba(0, 0, 0, 0.6); }
              .theme--dark section.events_section .copy_elem_mdl .box_rdio .head_rdio .ms_tablet ul.playlist_custom li.mdl-menu__item a {
                color: rgba(255, 255, 255, 0.6); }
              section.events_section .copy_elem_mdl .box_rdio .head_rdio .ms_tablet ul.playlist_custom li.mdl-menu__item a .col-play {
                flex-basis: 80%;
                display: flex;
                flex-direction: column;
                justify-content: left; }
                section.events_section .copy_elem_mdl .box_rdio .head_rdio .ms_tablet ul.playlist_custom li.mdl-menu__item a .col-play.-left {
                  flex-basis: 20%; }
              section.events_section .copy_elem_mdl .box_rdio .head_rdio .ms_tablet ul.playlist_custom li.mdl-menu__item a span {
                line-height: 1.4;
                text-align: left;
                display: block; }
                section.events_section .copy_elem_mdl .box_rdio .head_rdio .ms_tablet ul.playlist_custom li.mdl-menu__item a span.title_play {
                  font-family: 'Roboto Condensed Bold';
                  font-size: 1rem; }
                section.events_section .copy_elem_mdl .box_rdio .head_rdio .ms_tablet ul.playlist_custom li.mdl-menu__item a span.desc_play {
                  font-family: 'Roboto';
                  font-size: .9rem; }
                section.events_section .copy_elem_mdl .box_rdio .head_rdio .ms_tablet ul.playlist_custom li.mdl-menu__item a span.ico_left {
                  margin-left: auto; }
    section.events_section .copy_elem_mdl .box_rdio .body_rdio {
      padding: 50px 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
      section.events_section .copy_elem_mdl .box_rdio .body_rdio p.box_title {
        font-family: 'Roboto Condensed Bold';
        font-size: 24px;
        color: rgba(0, 0, 0, 0.87); }
        .theme--default section.events_section .copy_elem_mdl .box_rdio .body_rdio p.box_title {
          color: #fff; }
        .theme--dark section.events_section .copy_elem_mdl .box_rdio .body_rdio p.box_title {
          color: #fff; }
      section.events_section .copy_elem_mdl .box_rdio .body_rdio .box {
        z-index: 5;
        display: flex;
        width: 180px;
        height: 180px;
        position: relative;
        margin-bottom: 20px; }
        section.events_section .copy_elem_mdl .box_rdio .body_rdio .box img {
          max-width: 100%;
          width: 100%;
          object-fit: cover; }
        section.events_section .copy_elem_mdl .box_rdio .body_rdio .box .orange {
          position: absolute;
          height: 100%;
          width: 100%;
          background-color: rgba(255, 193, 7, 0.7); }
      section.events_section .copy_elem_mdl .box_rdio .body_rdio .btn_rdio a.mdl-button {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 60px;
        height: 60px;
        min-width: initial;
        padding: 5px;
        border-radius: 50%;
        border: 4px solid #fff8e1; }
        .theme--default section.events_section .copy_elem_mdl .box_rdio .body_rdio .btn_rdio a.mdl-button {
          background-color: #fff; }
        .theme--dark section.events_section .copy_elem_mdl .box_rdio .body_rdio .btn_rdio a.mdl-button {
          background-color: #fff; }
        section.events_section .copy_elem_mdl .box_rdio .body_rdio .btn_rdio a.mdl-button span {
          color: #ffc107; }
  section.events_section .copy_elem_mdl .list_sound {
    position: relative;
    z-index: 4;
    padding: 0 20px;
    display: none; }
    @media only screen and (min-width: 1025px) {
      section.events_section .copy_elem_mdl .list_sound {
        display: block; } }
    section.events_section .copy_elem_mdl .list_sound p.title_sound {
      font-family: 'Roboto Condensed Bold';
      font-size: 20px;
      color: rgba(0, 0, 0, 0.6);
      letter-spacing: 0.25px;
      margin-bottom: 20px; }
      .theme--default section.events_section .copy_elem_mdl .list_sound p.title_sound {
        color: #ffc107; }
      .theme--dark section.events_section .copy_elem_mdl .list_sound p.title_sound {
        color: #ffc107; }
    section.events_section .copy_elem_mdl .list_sound .list_item_sound {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 20px; }
      section.events_section .copy_elem_mdl .list_sound .list_item_sound .sound_img {
        flex-basis: 80px; }
        section.events_section .copy_elem_mdl .list_sound .list_item_sound .sound_img img {
          max-width: 100%;
          height: 60px;
          object-fit: cover; }
      section.events_section .copy_elem_mdl .list_sound .list_item_sound .sound_body {
        flex: 1 1 auto;
        padding-left: 10px; }
        section.events_section .copy_elem_mdl .list_sound .list_item_sound .sound_body p.title_body_sound {
          font-family: 'Roboto Condensed Bold';
          font-size: 1rem;
          letter-spacing: 0.15px;
          line-height: 1;
          margin-bottom: 0; }
          .theme--default section.events_section .copy_elem_mdl .list_sound .list_item_sound .sound_body p.title_body_sound {
            color: #fff; }
          .theme--dark section.events_section .copy_elem_mdl .list_sound .list_item_sound .sound_body p.title_body_sound {
            color: #fff; }
        section.events_section .copy_elem_mdl .list_sound .list_item_sound .sound_body p.desc_body_sound {
          font-family: 'Roboto-Regular';
          font-size: 12px;
          letter-spacing: 0.4px;
          margin-bottom: 0; }
          .theme--default section.events_section .copy_elem_mdl .list_sound .list_item_sound .sound_body p.desc_body_sound {
            color: rgba(255, 255, 255, 0.6); }
          .theme--dark section.events_section .copy_elem_mdl .list_sound .list_item_sound .sound_body p.desc_body_sound {
            color: rgba(255, 255, 255, 0.6); }
      section.events_section .copy_elem_mdl .list_sound .list_item_sound .sound_arrow {
        flex-basis: 60px;
        display: flex;
        justify-content: flex-end;
        margin-left: auto; }
        .theme--default section.events_section .copy_elem_mdl .list_sound .list_item_sound .sound_arrow a.mdl-button {
          color: #ffc107; }
        .theme--dark section.events_section .copy_elem_mdl .list_sound .list_item_sound .sound_arrow a.mdl-button {
          color: #ffc107; }
  section.events_section .copy_elem_mdl .elem_row {
    display: flex;
    flex-direction: row; }
    section.events_section .copy_elem_mdl .elem_row .elem_colm {
      flex-basis: 100%; }
      section.events_section .copy_elem_mdl .elem_row .elem_colm.hd_view {
        display: none; }
        @media only screen and (min-width: 1025px) {
          section.events_section .copy_elem_mdl .elem_row .elem_colm.hd_view {
            display: block; } }
      @media only screen and (min-width: 1025px) {
        section.events_section .copy_elem_mdl .elem_row .elem_colm {
          flex-basis: 50%; } }
  section.events_section .copy_events {
    padding: 0 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 2;
    margin: 0 auto;
    text-align: center; }
    section.events_section .copy_events .ctn_img {
      margin: 0 auto;
      text-align: center;
      padding: 15px;
      border: 1px solid #33691e;
      margin-bottom: 10px; }
      @media only screen and (min-width: 480px) {
        section.events_section .copy_events .ctn_img {
          margin-top: 100px; } }
      @media only screen and (min-width: 1025px) {
        section.events_section .copy_events .ctn_img {
          margin-top: 0; } }
      section.events_section .copy_events .ctn_img img {
        max-width: 100%;
        object-fit: cover;
        width: 240px;
        height: 336px; }
    section.events_section .copy_events a.mdl-button.custom {
      display: flex;
      padding: 0;
      height: 60px;
      width: 260px;
      align-items: center;
      text-transform: initial;
      flex-direction: row;
      font-family: 'Roboto-Regular';
      font-weight: bold;
      letter-spacing: -0.94px;
      color: #8bc34a;
      margin: 0 auto;
      text-align: center; }
      section.events_section .copy_events a.mdl-button.custom:hover {
        background-color: transparent; }
      section.events_section .copy_events a.mdl-button.custom p {
        font-size: 60px;
        margin: 0; }
      section.events_section .copy_events a.mdl-button.custom span {
        padding-left: 10px;
        color: #8bc34a; }

section.programs_section .stn_title {
  margin-bottom: 50px; }
  section.programs_section .stn_title p {
    position: relative;
    font-family: "Roboto Condensed Bold";
    color: #fff;
    font-size: 24px;
    letter-spacing: -0.2px;
    margin-bottom: 0; }
    @media only screen and (min-width: 480px) {
      section.programs_section .stn_title p {
        font-size: 48px; } }
    @media only screen and (min-width: 1025px) {
      section.programs_section .stn_title p {
        font-size: 60px;
        line-height: 1.2;
        letter-spacing: -0.5px; } }
    section.programs_section .stn_title p::after {
      content: "";
      position: absolute;
      bottom: -15px;
      width: 100px;
      left: 0;
      height: 1px;
      border-bottom: 5px solid #c5e1a5; }

section.programs_section .copy_stn {
  background-color: #33691e;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 66px calc(10% - 16px); }
  section.programs_section .copy_stn .box_list {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 100%; }
    @media only screen and (min-width: 1025px) {
      section.programs_section .copy_stn .box_list {
        width: 360px; } }
    section.programs_section .copy_stn .box_list button.mdl-button.list-button-mdl,
    section.programs_section .copy_stn .box_list a.mdl-button.list-button-mdl {
      display: flex;
      text-transform: initial;
      padding: 5px;
      height: auto;
      margin-bottom: 5px;
      position: relative;
      text-decoration: none; }
      section.programs_section .copy_stn .box_list button.mdl-button.list-button-mdl .list_item,
      section.programs_section .copy_stn .box_list a.mdl-button.list-button-mdl .list_item {
        flex-basis: 50%; }
        section.programs_section .copy_stn .box_list button.mdl-button.list-button-mdl .list_item .elem_copy,
        section.programs_section .copy_stn .box_list a.mdl-button.list-button-mdl .list_item .elem_copy {
          display: flex;
          flex-direction: column;
          text-align: left; }
          section.programs_section .copy_stn .box_list button.mdl-button.list-button-mdl .list_item .elem_copy span.title,
          section.programs_section .copy_stn .box_list a.mdl-button.list-button-mdl .list_item .elem_copy span.title {
            font-family: 'Roboto Condensed Bold';
            font-size: 1rem;
            color: #fff;
            line-height: 24px;
            letter-spacing: 0.15px; }
            .fontx--default section.programs_section .copy_stn .box_list button.mdl-button.list-button-mdl .list_item .elem_copy span.title, .fontx--default
            section.programs_section .copy_stn .box_list a.mdl-button.list-button-mdl .list_item .elem_copy span.title {
              font-size: 19px; }
            .fontx--x2 section.programs_section .copy_stn .box_list button.mdl-button.list-button-mdl .list_item .elem_copy span.title, .fontx--x2
            section.programs_section .copy_stn .box_list a.mdl-button.list-button-mdl .list_item .elem_copy span.title {
              font-size: 19px; }
          section.programs_section .copy_stn .box_list button.mdl-button.list-button-mdl .list_item .elem_copy span.desc,
          section.programs_section .copy_stn .box_list a.mdl-button.list-button-mdl .list_item .elem_copy span.desc {
            font-family: 'Roboto-Regular';
            font-size: 14px;
            color: rgba(255, 255, 255, 0.6);
            line-height: 20px;
            letter-spacing: 0.15px; }
            .fontx--default section.programs_section .copy_stn .box_list button.mdl-button.list-button-mdl .list_item .elem_copy span.desc, .fontx--default
            section.programs_section .copy_stn .box_list a.mdl-button.list-button-mdl .list_item .elem_copy span.desc {
              font-size: 17px; }
            .fontx--x2 section.programs_section .copy_stn .box_list button.mdl-button.list-button-mdl .list_item .elem_copy span.desc, .fontx--x2
            section.programs_section .copy_stn .box_list a.mdl-button.list-button-mdl .list_item .elem_copy span.desc {
              font-size: 17px; }
        section.programs_section .copy_stn .box_list button.mdl-button.list-button-mdl .list_item .elem_icon,
        section.programs_section .copy_stn .box_list a.mdl-button.list-button-mdl .list_item .elem_icon {
          display: flex;
          height: 100%;
          justify-content: flex-end;
          align-items: center; }
          section.programs_section .copy_stn .box_list button.mdl-button.list-button-mdl .list_item .elem_icon span,
          section.programs_section .copy_stn .box_list a.mdl-button.list-button-mdl .list_item .elem_icon span {
            color: rgba(255, 255, 255, 0.6); }
  section.programs_section .copy_stn .action_btn {
    margin-top: 50px;
    padding: 0 20px;
    display: flex;
    flex-direction: column; }
    @media only screen and (min-width: 480px) {
      section.programs_section .copy_stn .action_btn {
        flex-direction: row; } }
    section.programs_section .copy_stn .action_btn > .col_btn {
      flex-basis: 50%; }
      section.programs_section .copy_stn .action_btn > .col_btn .copy {
        display: flex;
        align-items: center;
        height: 100%; }
        section.programs_section .copy_stn .action_btn > .col_btn .copy.btn_right {
          justify-content: center;
          padding-top: 30px; }
          @media only screen and (min-width: 480px) {
            section.programs_section .copy_stn .action_btn > .col_btn .copy.btn_right {
              padding-top: 0;
              justify-content: flex-end; } }
        section.programs_section .copy_stn .action_btn > .col_btn .copy button.mdl-button {
          height: 48px;
          color: #fff;
          background-color: #ffc107; }
          section.programs_section .copy_stn .action_btn > .col_btn .copy button.mdl-button span {
            padding-right: 5px; }
        section.programs_section .copy_stn .action_btn > .col_btn .copy p {
          color: #fff;
          margin-bottom: 0;
          font-family: 'Roboto-Regular';
          font-size: 1rem;
          line-height: 28px;
          letter-spacing: .5px; }
          .fontx--default section.programs_section .copy_stn .action_btn > .col_btn .copy p {
            font-size: 18px; }
          .fontx--x2 section.programs_section .copy_stn .action_btn > .col_btn .copy p {
            font-size: 18px; }

section.programs_section .row_programs {
  display: flex;
  flex-direction: column; }
  @media only screen and (min-width: 1025px) {
    section.programs_section .row_programs {
      flex-direction: row; } }
  section.programs_section .row_programs > .colm {
    flex-basis: 50%;
    display: flex;
    flex-direction: column; }
    section.programs_section .row_programs > .colm.not_tablet {
      display: none; }
      @media only screen and (min-width: 1025px) {
        section.programs_section .row_programs > .colm.not_tablet {
          display: flex; } }
  section.programs_section .row_programs .box_img {
    display: flex;
    height: 100%; }
    section.programs_section .row_programs .box_img .tag_img {
      max-width: 100%;
      height: 100%;
      width: 100%;
      object-fit: cover; }

section.serv_section {
  padding: 25px calc(8% - 16px); }
  @media only screen and (min-width: 480px) {
    section.serv_section {
      padding: 108px calc(4% - 16px); } }
  .theme--default section.serv_section {
    background-color: #fafafa; }
  .theme--dark section.serv_section {
    background-color: #121212; }
  section.serv_section .row_serv {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }
    section.serv_section .row_serv .colm {
      flex-basis: 100%; }
      @media only screen and (min-width: 480px) {
        section.serv_section .row_serv .colm {
          flex-basis: 50%; } }
      @media only screen and (min-width: 1025px) {
        section.serv_section .row_serv .colm {
          flex-basis: 33.333333%; } }
  section.serv_section .card_mdl {
    margin: 0 15px 35px;
    transition: all .1s ease-in-out;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2); }

section.link_section {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 90px;
  background-color: #d8d8d8; }
  .theme--default section.link_section {
    background-color: #fafafa; }
  .theme--dark section.link_section {
    background-color: #121212; }
  section.link_section .btn_link a.mdl-button.custom {
    height: 60px;
    align-items: center;
    display: flex;
    flex-direction: row;
    text-transform: initial; }
    section.link_section .btn_link a.mdl-button.custom:hover {
      background-color: transparent; }
    section.link_section .btn_link a.mdl-button.custom span {
      margin-left: 20px;
      color: #8bc34a; }
  section.link_section .btn_link p.title {
    font-family: 'Roboto Condensed Bold';
    font-size: 24px;
    letter-spacing: -0.2px;
    color: #ffc107;
    margin-bottom: 0;
    color: rgba(0, 0, 0, 0.6); }
    .theme--default section.link_section .btn_link p.title {
      color: #ffc107; }
    .theme--dark section.link_section .btn_link p.title {
      color: #ffc107; }
    @media only screen and (min-width: 1025px) {
      section.link_section .btn_link p.title {
        font-size: 60px; } }

section.video_section {
  display: flex;
  min-height: 240px; }
  @media only screen and (min-width: 480px) {
    section.video_section {
      min-height: 434px; } }
  @media only screen and (min-width: 1025px) {
    section.video_section {
      min-height: 814px; } }
  section.video_section .box_action {
    position: relative;
    flex-basis: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden; }
    section.video_section .box_action iframe {
      height: 100% !important; }
    section.video_section .box_action .box_action_copy {
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transition: all 0.8s; }
      section.video_section .box_action .box_action_copy.is-inactive {
        display: none; }
      section.video_section .box_action .box_action_copy .box-black {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5); }
      section.video_section .box_action .box_action_copy img {
        max-width: 100%;
        height: 100%;
        object-fit: cover;
        width: 100%; }
    section.video_section .box_action .box_action_act {
      position: relative;
      z-index: 1;
      padding: 108px calc(8% - 16px); }
      section.video_section .box_action .box_action_act.is-inactive {
        display: none; }
      @media only screen and (min-width: 1025px) {
        section.video_section .box_action .box_action_act {
          padding: 108px calc(4% - 16px); } }
      section.video_section .box_action .box_action_act p {
        font-family: 'Roboto-Bold';
        color: #fff;
        letter-spacing: -1.5px;
        line-height: 1.2;
        font-size: 24px; }
        @media only screen and (min-width: 480px) {
          section.video_section .box_action .box_action_act p {
            font-size: 48px; } }
        @media only screen and (min-width: 1025px) {
          section.video_section .box_action .box_action_act p {
            font-size: 96px; } }
      section.video_section .box_action .box_action_act a.mdl-button.round_mdl {
        height: 48px;
        width: 48px;
        min-width: auto;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        border: 1px solid #ffff;
        border-radius: 50%; }
        section.video_section .box_action .box_action_act a.mdl-button.round_mdl span {
          color: #fff; }
