.hero_slider {
  background-color: #ddd;
  width: 100%;
  min-height: 220px;
  position: relative;

  @media #{$tablet-up} {
    min-height: 434px;
  }

  @media #{$large-up} {

    min-height: 815px;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      position: relative;
      margin: 0;
      width: 100%;
      display: flex;

      .box_dark {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);

        @media #{$tablet-up} {
          min-height: 434px;
        }

        @media #{$large-up} {
          min-height: 815px;
        }
      }
    }
  }

  .box_slider {
    position: relative;
    z-index: 2;
    display: flex;
    //align-items: center;
    flex-direction: column;
    justify-content: center;
    //padding: 20px;
    min-height: 220px;

    @media #{$tablet-up} {
      min-height: 434px;

    }

    @media #{$large-up} {

      min-height: 815px;
    }


    p {
      color: $text-white;
      font-family: 'Roboto-Bold';
      font-size: 24px;
      line-height: 1.4;
      letter-spacing: -0.75px;
      padding: 20px;

      @media #{$tablet-up} {
        font-size: 48px;
      }

      @media #{$large-up} {
        font-size: 96px;

        @include fontsized() {
          font-size: 96px + 2px;
        }
      }
    }

    a.mdl-button.box_link_hero {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      height: 40px;
      width: 40px;
      padding: 0;
      margin: 20px;
      min-width: initial;
      border: 1px solid #fff;

      @media #{$tablet-up} {
        height: 60px;
        width: 60px;
      }


      span {
        color: $text-white;
      }
    }
  }

  .slide__action {
    position: absolute;
    bottom: 40px;
    right: 40px;
    z-index: 100;

    a {
      font-family: 'Roboto Condensed Bold';
      text-decoration: none;
      font-size: 60px;
      color: transparent;
      -webkit-text-stroke-color: #fff;
      -webkit-text-stroke-width: 2px;
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }

      &.cycle-pager-active {
        -webkit-text-stroke-color: #fff;
        -webkit-text-stroke-width: 0;
        color: $text-white;
        font-size: 60px;
      }
    }
  }
}

.menu_primary {
  display: none;

  //align-items: center;
  //justify-content: center;
  padding: 0 20px;
  justify-content: space-between;
  min-height: 90px;
  background-color: $primary900;

  @media #{$large-up} {
    overflow: hidden;
    display: flex;
  }

  a.mdl-button {
    display: flex;
    align-items: center;
    color: $text-white;
    border-bottom: 3px solid transparent;
    transition: border 80ms linear;
    font-family: "Roboto-Regular";
    letter-spacing: 1.25px;
    font-size: 14px;
    line-height: 1.4;
    //|height: 100%;
    height: 90px;
    max-width: initial;
    text-decoration: none;
    padding: 0 5px;
    overflow: initial;
    text-align: center;

    //word-break: break-word;
    // @include themed() {
    //   color: t(text-primary);
    // }
    @include fontsized() {
      font-size: 14px + 2px;
    }

    span {
      text-transform: uppercase;
    }

    &:hover {
      background-color: transparent;
      border-bottom: 3px solid #fff;
    }
  }
}

section.news_section {
  padding: 50px calc(8% - 16px) 50px;

  @media #{$large-up} {
    padding: 108px calc(4% - 16px) 50px;

  }

  @include themed() {
    background-color: t(bg-primary);
  }

  .news_title {
    margin-bottom: 50px;

    p {
      position: relative;
      font-family: "Roboto Condensed Bold";
      font-size: 60px;
      color: $primary500;
      margin-bottom: 0;
      line-height: 1.2;

      @include fontsized() {
        font-size: 60px + 10px;
      }

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100px;
        height: 1px;
        border-bottom: 5px solid $border-title;
      }
    }
  }

  .box_comp {
    display: flex;
    flex-direction: column;

    a {
      text-decoration: none;
    }

    .box_img {
      max-width: 100%;
      object-fit: cover;
      width: 100%;
      height: 352px;

      @media #{$large-up} {
        width: 624px;

      }
    }

    .copy {
      padding: 28px 0;

      a {
        text-decoration: none;
      }

      p.tag {
        font-family: "Roboto-Regular";
        font-size: 12px;
        color: $primary500;
        line-height: 16px;
        letter-spacing: 0.4px;
        display: flex;
        flex-direction: row;
        align-items: center;

        @include fontsized() {
          font-size: 12px + 2px;
        }

        span {
          padding-right: 5px;
        }
      }

      p.title {
        font-family: "Roboto-Regular";
        font-size: 36px;
        line-height: 1;
        color: $text-primary;

        @include fontsized() {
          font-size: 36px + 2px;
        }

        @include themed() {
          color: t(text-primary);
        }
      }

      p.date_custom {
        font-family: "Roboto Condensed Italic";
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 2px;

        @include fontsized() {
          font-size: 12px + 2px;
        }

        @include themed() {
          color: t(text-secondary);
        }
      }
    }
  }

  .box_new {
    display: flex;
    flex-direction: column;
    padding: 0 20px;

    a {
      text-decoration: none;
    }

    .box_img {
      max-width: 100%;
      width: 100%;
      object-fit: cover;
      //width: 240px;
      height: 120px;
    }

    .copy {
      padding: 12px 0;

      a {
        text-decoration: none;
      }

      p.tag {
        font-family: "Roboto-Regular";
        font-size: 12px;
        color: $primary500;
        line-height: 16px;
        letter-spacing: 0.4px;
        margin-bottom: 5px;
        display: flex;
        flex-direction: row;
        align-items: center;

        @include fontsized() {
          font-size: 12px + 2px;
        }

        span {
          padding-right: 5px;
        }
      }

      p.title {
        font-family: "Roboto Condensed Bold";
        font-size: 1rem;
        line-height: 21px;
        letter-spacing: 0.15px;
        color: $text-secondary;
        margin-bottom: 5px;

        @include fontsized() {
          font-size: 16px + 2px;
        }

        @include themed() {
          color: $text-white;
        }
      }

      p.date_custom {
        font-family: "Roboto Condensed Italic";
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 2px;

        @include themed() {
          color: t(text-secondary);
        }
      }
    }
  }

  //display: flex;
  .grid_news {
    display: flex;
    flex-direction: column;

    @media #{$large-up} {
      flex-direction: row;

    }

    .colm_news {
      //border: 1px solid #ddd;
      flex-basis: 100%;

      &.not_tablet_news {
        display: none;

        @media #{$large-up} {
          display: block;
        }
      }

    }

    .row_news {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      .colm {
        flex-basis: 50%;
      }
    }
  }
}

section.cards_section {
  overflow: hidden;

  @media #{$large-up} {
    overflow: initial;
  }

  .row_cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @media #{$large-up} {
      flex-wrap: nowrap;

    }

    >.colm {
      flex-basis: 100%;

      @media #{$tablet-up} {

        flex-basis: 50%;
      }

      @media #{$large-up} {
        flex-basis: 25%;
      }
    }
  }
}

section.events_section {
  min-height: 320px;
  position: relative;
  display: flex;
  align-items: center;
  padding: 90px calc(8% - 16px);

  @media #{$tablet-up} {
    min-height: 540px;
    padding: 108px calc(4% - 16px);

  }

  @include themed() {
    background-color: t(bg-primary);
  }

  &::after {
    content: '';
    position: absolute;
    top: 10%;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 1;
    background: url('../images/pics/udea_bg_logo.png') center center no-repeat;
    width: auto;
    height: 630px;
    opacity: 0.04;

    @media #{$large-up} {
      width: 500px;
    }

    @include themed() {
      opacity: 0.3;
    }
  }

  .row_events {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;

    @media #{$tablet-up} {
      flex-direction: row;
      flex-wrap: nowrap;
    }

    .colm {
      flex-basis: 50%;
    }
  }

  .copy_widget {
    padding: 0 10px;
    position: relative;
    z-index: 2;
    margin-bottom: 10px;
  }

  .copy_elem_mdl {
    //background-color: #f50;

    .box_rdio {
      padding: 10px;
      position: relative;
      z-index: 4;
      box-shadow: $shadow-3;
      margin-bottom: 50px;
      background-color: #fff;

      @include themed() {
        background-color: #323232;
      }

      .head_rdio {
        display: flex;
        flex-direction: row;
        align-items: center;

        a.rdio_left {
          display: flex;
          margin-right: auto;
        }

        a.rdio_right {
          display: flex;
          margin-left: auto;

          @include themed() {
            color: $text-secondary-dark;
          }
        }

        .ms_tablet {
          display: block;

          @media #{$large-up} {
            display: none;
          }

          ul.playlist_custom {
            width: 300px;
            //margin-right: 10px;

            li.mdl-menu__item {
              display: flex;
              white-space: wrap;
              overflow: initial;
              height: initial;
              min-height: 48px;

              a {
                display: flex;
                flex-direction: row;
                text-decoration: none;
                width: 100%;
                font-size: 14px;
                align-items: center;
                color: $text-primary;

                @include themed() {
                  color: t(text-secondary);
                }

                .col-play {
                  flex-basis: 80%;
                  display: flex;
                  flex-direction: column;
                  //align-items: center;
                  justify-content: left;

                  &.-left {
                    flex-basis: 20%;
                  }
                }

                span {
                  line-height: 1.4;
                  text-align: left;
                  display: block;

                  &.title_play {
                    font-family: 'Roboto Condensed Bold';
                    font-size: 1rem;
                  }

                  &.desc_play {
                    font-family: 'Roboto';
                    font-size: .9rem;
                  }

                  &.ico_left {
                    margin-left: auto;
                  }
                }
              }
            }
          }
        }
      }

      .body_rdio {
        padding: 50px 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        p.box_title {
          font-family: 'Roboto Condensed Bold';
          font-size: 24px;
          color: $text-primary;

          @include themed() {
            color: $text-white;
          }

        }

        .box {
          z-index: 5;
          display: flex;
          width: 180px;
          height: 180px;
          position: relative;
          margin-bottom: 20px;

          img {
            max-width: 100%;
            width: 100%;
            object-fit: cover;
          }

          .orange {
            position: absolute;
            height: 100%;
            width: 100%;
            background-color: rgba(255, 193, 7, 0.7);

          }
        }

        .btn_rdio {
          a.mdl-button {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 60px;
            height: 60px;
            min-width: initial;
            padding: 5px;
            border-radius: 50%;
            border: 4px solid $secondary50;

            @include themed() {
              background-color: #fff;
            }

            span {
              color: $secondary500;
            }
          }
        }

      }
    }

    .list_sound {
      position: relative;
      z-index: 4;
      padding: 0 20px;
      display: none;

      @media #{$large-up} {
        display: block;
      }


      p.title_sound {
        font-family: 'Roboto Condensed Bold';
        font-size: 20px;
        color: $text-secondary;
        letter-spacing: 0.25px;
        margin-bottom: 20px;

        @include themed() {
          color: $secondary500;
        }
      }

      .list_item_sound {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 20px;

        .sound_img {
          //padding-left: 10px;
          flex-basis: 80px;

          img {
            max-width: 100%;
            //width: 60px;
            height: 60px;
            object-fit: cover;
          }
        }

        .sound_body {
          flex: 1 1 auto;
          padding-left: 10px;

          p.title_body_sound {
            font-family: 'Roboto Condensed Bold';
            font-size: 1rem;
            letter-spacing: 0.15px;
            line-height: 1;
            margin-bottom: 0;

            @include themed() {
              color: $text-white;
            }
          }

          p.desc_body_sound {
            font-family: 'Roboto-Regular';
            font-size: 12px;
            letter-spacing: 0.4px;
            margin-bottom: 0;

            @include themed() {
              color: $text-secondary-dark;
            }
          }
        }

        .sound_arrow {
          //padding-right: 20px;
          flex-basis: 60px;
          display: flex;
          justify-content: flex-end;
          margin-left: auto;

          a.mdl-button {
            @include themed() {
              color: $secondary500;
            }
          }
        }
      }
    }

    .elem_row {
      display: flex;
      flex-direction: row;

      .elem_colm {
        flex-basis: 100%;

        &.hd_view {
          display: none;

          @media #{$large-up} {
            display: block;
          }
        }

        @media #{$large-up} {

          flex-basis: 50%;
        }

      }
    }
  }

  .copy_events {
    padding: 0 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 2;
    margin: 0 auto;
    text-align: center;

    .ctn_img {
      margin: 0 auto;
      text-align: center;
      padding: 15px;
      border: 1px solid $primary900;
      margin-bottom: 10px;

      @media #{$tablet-up} {
        margin-top: 100px;
      }

      @media #{$large-up} {
        margin-top: 0;
      }

      img {
        max-width: 100%;
        object-fit: cover;
        width: 240px;
        height: 336px;
      }
    }

    a.mdl-button.custom {
      display: flex;
      padding: 0;
      height: 60px;
      width: 260px;
      align-items: center;
      text-transform: initial;
      flex-direction: row;
      font-family: 'Roboto-Regular';
      font-weight: bold;
      letter-spacing: -0.94px;
      color: $primary500;
      margin: 0 auto;
      text-align: center;

      &:hover {
        background-color: transparent;
      }

      p {
        font-size: 60px;
        margin: 0;
      }

      span {
        padding-left: 10px;
        color: $primary500;
      }
    }
  }
}

section.programs_section {
  .stn_title {
    margin-bottom: 50px;

    p {
      position: relative;
      font-family: "Roboto Condensed Bold";
      color: $text-white;
      font-size: 24px;
      letter-spacing: -0.2px;
      margin-bottom: 0;

      @media #{$tablet-up} {
        font-size: 48px;
      }

      @media #{$large-up} {
        //text-align: center;
        font-size: 60px;
        line-height: 1.2;
        letter-spacing: -0.5px;

      }

      &::after {
        content: "";
        position: absolute;
        bottom: -15px;
        width: 100px;
        left: 0;

        height: 1px;
        border-bottom: 5px solid $primary200;

        @media #{$tablet-up} {
          //bottom: 0;
          //left: 31px;

        }
      }
    }
  }

  .copy_stn {
    background-color: $primary900;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 66px calc(10% - 16px);

    .box_list {
      //border: 1px solid #ddd;
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      //text-align: center;
      width: 100%;

      @media #{$large-up} {
        width: 360px;

      }

      button.mdl-button.list-button-mdl,
      a.mdl-button.list-button-mdl {
        display: flex;
        text-transform: initial;
        padding: 5px;
        height: auto;
        margin-bottom: 5px;

        position: relative;
        text-decoration: none;

        .list_item {
          flex-basis: 50%;

          .elem_copy {
            display: flex;
            flex-direction: column;
            text-align: left;

            span.title {
              font-family: 'Roboto Condensed Bold';
              font-size: 1rem;
              color: $text-white;
              line-height: 24px;
              letter-spacing: 0.15px;

              @include fontsized() {
                font-size: 16px + 3px;
              }
            }

            span.desc {
              font-family: 'Roboto-Regular';
              font-size: 14px;
              color: $text-secondary-dark;
              line-height: 20px;
              letter-spacing: 0.15px;

              @include fontsized() {
                font-size: 14px + 3px;
              }
            }
          }

          .elem_icon {
            display: flex;
            height: 100%;
            justify-content: flex-end;
            align-items: center;

            span {
              color: $text-secondary-dark;
            }
          }
        }
      }
    }

    .action_btn {
      margin-top: 50px;
      padding: 0 20px;
      display: flex;
      flex-direction: column;

      @media #{$tablet-up} {
        flex-direction: row;

      }

      >.col_btn {
        flex-basis: 50%;


        .copy {
          display: flex;
          align-items: center;
          height: 100%;

          &.btn_right {
            justify-content: center;
            padding-top: 30px;

            @media #{$tablet-up} {
              padding-top: 0;
              justify-content: flex-end;
            }
          }

          button.mdl-button {
            height: 48px;
            color: $text-white;
            background-color: $secondary500;

            span {
              padding-right: 5px;
            }

          }

          p {
            color: #fff;
            margin-bottom: 0;
            font-family: 'Roboto-Regular';
            font-size: 1rem;
            line-height: 28px;
            letter-spacing: .5px;

            @include fontsized() {
              font-size: 16px + 2px;
            }
          }
        }

      }
    }
  }

  .row_programs {
    display: flex;
    flex-direction: column;

    @media #{$large-up} {
      flex-direction: row;

    }

    >.colm {
      //border: 1px solid #ddd;
      flex-basis: 50%;
      display: flex;
      flex-direction: column;

      &.not_tablet {
        display: none;

        @media #{$large-up} {
          display: flex;
        }
      }
    }

    .box_img {
      display: flex;
      height: 100%;

      .tag_img {
        max-width: 100%;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }
}

section.serv_section {
  padding: 25px calc(8% - 16px);

  @media #{$tablet-up} {
    padding: 108px calc(4% - 16px);

  }

  @include themed() {
    background-color: t(bg-primary);
  }

  .row_serv {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .colm {
      flex-basis: 100%;

      @media #{$tablet-up} {
        flex-basis: 50%;

      }

      @media #{$large-up} {

        flex-basis: 33.333333%;
      }
    }
  }

  .card_mdl {
    margin: 0 15px 35px;
    //margin-bottom: 5px;
    transition: all .1s ease-in-out;
    box-shadow: $shadow-1;
  }
}

section.link_section {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 90px;
  background-color: #d8d8d8;

  @include themed() {
    background-color: t(bg-primary);
  }

  .btn_link {
    a.mdl-button.custom {
      height: 60px;
      align-items: center;
      display: flex;
      flex-direction: row;
      text-transform: initial;

      &:hover {
        background-color: transparent;
      }

      span {
        margin-left: 20px;
        color: $primary500;

      }
    }

    p.title {
      font-family: 'Roboto Condensed Bold';
      font-size: 24px;
      letter-spacing: -0.2px;
      color: $secondary500;
      margin-bottom: 0;
      color: $text-secondary;

      @include themed() {

        color: $secondary500;
      }

      @media #{$large-up} {
        font-size: 60px;
      }
    }
  }
}

section.video_section {

  display: flex;
  min-height: 240px;

  @media #{$tablet-up} {
    min-height: 434px;
  }

  @media #{$large-up} {
    min-height: 814px;

  }

  //width: 100%;

  .box_action {
    position: relative;
    flex-basis: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;

    //display: flex;
    iframe {
      height: 100% !important;
    }

    .box_action_copy {
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transition: all 0.8s;

      &.is-inactive {
        display: none;
      }

      .box-black {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
      }

      img {
        max-width: 100%;
        height: 100%;
        object-fit: cover;
        width: 100%;

        @media #{$large-up} {}
      }

    }

    .box_action_act {
      position: relative;
      z-index: 1;
      padding: 108px calc(8% - 16px);

      //transition: cubic-bezier(0.19, 1, 0.22, 1);

      &.is-inactive {
        display: none;
      }

      @media #{$large-up} {
        padding: 108px calc(4% - 16px);

      }



      p {
        font-family: 'Roboto-Bold';
        color: $text-white;
        letter-spacing: -1.5px;
        line-height: 1.2;
        font-size: 24px;

        @media #{$tablet-up} {
          font-size: 48px;
        }

        @media #{$large-up} {

          font-size: 96px;
        }
      }

      a.mdl-button.round_mdl {
        height: 48px;
        width: 48px;
        min-width: auto;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        border: 1px solid #ffff;
        border-radius: 50%;

        span {
          color: #fff;
        }
      }
    }



  }
}
