.mdl-menu__container.is-visible .mdl-menu {
  @include themed() {
    background-color: t(bg-card);
  }

  &.custom_home {
    // min-width: 190px;

    .mdl-menu__item {}
  }

  .mdl-menu__item:active {
    @include themed() {
      background-color: $hover-list-dark;
    }
  }

  .mdl-menu__item:hover {
    @include themed() {
      background-color: $hover-list-dark;
    }
  }
}


/* Ripple effect */
.ripple-nojs {
  background-position: center !important;
  transition: background 0.8s !important;

  &:hover {
    background: #F2F2F2 radial-gradient(circle, transparent 1%, #F2F2F2 1%) center/15000% !important;
  }

  &:active {
    background-color: #6eb9f7;
    background-size: 100%;
    transition: background 0s;
  }
}

#form_inscripcion_programas iframe body {
  overflow-x: hidden;
}


// paginate global home noticias
.paginate_custom {
  display: flex;
  flex-direction: row;
  align-items: center;

  a.mdl-button.circle {
    display: flex;
    text-decoration: none;
    justify-content: center;
    align-items: center;
    border: 1px solid $primary900;
    border-radius: 50%;
    height: 61px;
    width: 61px;
    min-width: initial;
    padding: 0;

    &:hover {
      background-color: transparent;
    }

    span {
      color: $primary500;
    }
  }

  .action_page {
    padding-left: 20px;

    p.title_indi {
      font-family: 'Roboto-Bold';
      text-transform: uppercase;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 1.25px;
      margin-bottom: 5px;
      color: $primary900;

      @include themed() {
        color: $text-white;
      }
    }

    p.num_indi {
      font-family: 'Roboto-Regular';
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.25px;
      margin: 0;

      @include themed() {
        color: t(text-secondary);
      }
    }
  }
}
