@import "../../../node_modules/rfs/scss";
// flat icons
@import "./_flags";

/* fallback */


/* fallback */
@font-face {
  font-family: 'Roboto-Bold';
  src:
    url('../fonts/Roboto-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* fallback */
@font-face {
  font-family: 'Roboto-Regular';
  src:
    url('../fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* fallback */
@font-face {
  font-family: 'Roboto Condensed Bold';
  src:
    url('../fonts/RobotoCondensed-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* fallback */
@font-face {
  font-family: 'Roboto Condensed Regular';
  src:
    url('../fonts/RobotoCondensed-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto Condensed italic';
  src:
    url('../fonts/RobotoCondensed-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* fallback */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: local('Material Icons'), local('MaterialIcons-Regular'), url(https://fonts.gstatic.com/s/materialicons/v22/2fcrYFNaTjcS6g4U3t-Y5ZjZjT5FdEJ140U2DJYC3mY.woff2) format('woff2');
}


/* fallback */
@font-face {
  font-family: 'Material Icons Outlined';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/MaterialIcons-Regular.eot');
  src: local('Material Icons'),
    local('MaterialIcons-Regular'),
    url('../fonts/ico-material/gok-H7zzDkdnRel8-DQ6KAXJ69wP1tGnf4ZGhUcel5euIg.woff2') format('woff2');
}


// icons custom
@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icomoon.eot?h7od0q');
  src: url('../fonts/icomoon.eot?h7od0q#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?h7od0q') format('truetype'),
    url('../fonts/icomoon.woff?h7od0q') format('woff'),
    url('../fonts/icomoon.svg?h7od0q#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: $font24;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  @include fontsized() {
    font-size: f(font24);
  }

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';

  &.outlined {
    font-family: 'Material Icons Outlined' !important;
  }

  &.deg180 {
    transform: rotate(180deg);
  }
}


[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  font-size: $font24;
  speak-as: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  @include fontsized() {
    font-size: f(font24);
  }

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-agronomy:before {
  content: "\e900";
}

.icon-astronauta-ingravido:before {
  content: "\e901";
}

.icon-book:before {
  content: "\e902";
}

.icon-books .path1:before {
  content: "\e903";
  color: rgb(255, 255, 255);
}

.icon-books .path2:before {
  content: "\e904";
  margin-left: -1em;
  color: rgb(2, 36, 125);
}

.icon-books .path3:before {
  content: "\e905";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-books .path4:before {
  content: "\e906";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-books .path5:before {
  content: "\e907";
  margin-left: -1em;
  color: rgb(2, 36, 125);
}

.icon-books .path6:before {
  content: "\e908";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-books .path7:before {
  content: "\e909";
  margin-left: -1em;
  color: rgb(2, 36, 125);
}

.icon-books .path8:before {
  content: "\e90a";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-books .path9:before {
  content: "\e90b";
  margin-left: -1em;
  color: rgb(2, 36, 125);
}

.icon-books .path10:before {
  content: "\e90c";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-cap:before {
  content: "\e90d";
}

.icon-clasificacion:before {
  content: "\e90e";
}

.icon-compass:before {
  content: "\e90f";
}

.icon-conversation:before {
  content: "\e910";
}

.icon-cursor:before {
  content: "\e911";
}

.icon-facebook-logo:before {
  content: "\e912";
}

.icon-gear:before {
  content: "\e913";
}

.icon-graduation-hat:before {
  content: "\e914";
}

.icon-heart:before {
  content: "\e915";
}

.icon-heartbeat:before {
  content: "\e916";
}

.icon-help:before {
  content: "\e917";
}

.icon-instagram:before {
  content: "\e918";
}

.icon-lab-microscope:before {
  content: "\e919";
}

.icon-laurel:before {
  content: "\e91a";
}

.icon-lifesaver:before {
  content: "\e91b";
}

.icon-linkedin-logo:before {
  content: "\e91c";
}

.icon-lotus:before {
  content: "\e91d";
}

.icon-mortarboard:before {
  content: "\e91e";
}

.icon-office:before {
  content: "\e91f";
}

.icon-office365:before {
  content: "\e920";
}

.icon-paint-palette:before {
  content: "\e921";
}

.icon-shooting-star:before {
  content: "\e922";
}

.icon-social-care:before {
  content: "\e923";
}

.icon-solar-energy:before {
  content: "\e924";
}

.icon-star:before {
  content: "\e925";
}

.icon-starfish:before {
  content: "\e926";
}

.icon-student .path1:before {
  content: "\e927";
  color: rgb(0, 0, 0);
}

.icon-student .path2:before {
  content: "\e928";
  margin-left: -1em;
  color: rgb(2, 36, 125);
}

.icon-student .path3:before {
  content: "\e929";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-student .path4:before {
  content: "\e92a";
  margin-left: -1em;
  color: rgb(2, 36, 125);
}

.icon-student .path5:before {
  content: "\e92b";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-student .path6:before {
  content: "\e92c";
  margin-left: -1em;
  color: rgb(2, 36, 125);
}

.icon-student .path7:before {
  content: "\e92d";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-student .path8:before {
  content: "\e92e";
  margin-left: -1em;
  color: rgb(2, 36, 125);
}

.icon-student .path9:before {
  content: "\e92f";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-student .path10:before {
  content: "\e930";
  margin-left: -1em;
  color: rgb(2, 36, 125);
}

.icon-student .path11:before {
  content: "\e931";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-student .path12:before {
  content: "\e932";
  margin-left: -1em;
  color: rgb(2, 36, 125);
}

.icon-student .path13:before {
  content: "\e933";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-student .path14:before {
  content: "\e934";
  margin-left: -1em;
  color: rgb(2, 36, 125);
}

.icon-student .path15:before {
  content: "\e935";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-student .path16:before {
  content: "\e936";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-student .path17:before {
  content: "\e937";
  margin-left: -1em;
  color: rgb(255, 254, 255);
}

.icon-student .path18:before {
  content: "\e938";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-student .path19:before {
  content: "\e939";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-student .path20:before {
  content: "\e93a";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-student .path21:before {
  content: "\e93b";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-student .path22:before {
  content: "\e93c";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-student .path23:before {
  content: "\e93d";
  margin-left: -1em;
  color: rgb(2, 36, 125);
}

.icon-student .path24:before {
  content: "\e93e";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-student .path25:before {
  content: "\e93f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-student .path26:before {
  content: "\e940";
  margin-left: -1em;
  color: rgb(2, 36, 125);
}

.icon-student .path27:before {
  content: "\e941";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-student .path28:before {
  content: "\e942";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-student .path29:before {
  content: "\e943";
  margin-left: -1em;
  color: rgb(2, 36, 125);
}

.icon-student .path30:before {
  content: "\e944";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-student .path31:before {
  content: "\e945";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-student .path32:before {
  content: "\e946";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-student .path33:before {
  content: "\e947";
  margin-left: -1em;
  color: rgb(2, 36, 125);
}

.icon-student .path34:before {
  content: "\e948";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-student .path35:before {
  content: "\e949";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-student .path36:before {
  content: "\e94a";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-student .path37:before {
  content: "\e94b";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-student .path38:before {
  content: "\e94c";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-student .path39:before {
  content: "\e94d";
  margin-left: -1em;
  color: rgb(2, 36, 125);
}

.icon-student .path40:before {
  content: "\e94e";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-student .path41:before {
  content: "\e94f";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-student .path42:before {
  content: "\e950";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-student .path43:before {
  content: "\e951";
  margin-left: -1em;
  color: rgb(2, 36, 125);
}

.icon-student .path44:before {
  content: "\e952";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-teamwork:before {
  content: "\e953";
}

.icon-training:before {
  content: "\e954";
}

.icon-trophy:before {
  content: "\e955";
}

.icon-twitter-logo-silhouette:before {
  content: "\e956";
}

.icon-university:before {
  content: "\e957";
}

.icon-university-building:before {
  content: "\e958";
}

.icon-veterinary:before {
  content: "\e959";
}

.icon-whatsapp-logo:before {
  content: "\e95a";
}

.icon-winner .path1:before {
  content: "\e95b";
  color: rgb(255, 144, 29);
}

.icon-winner .path2:before {
  content: "\e95c";
  margin-left: -1em;
  color: rgb(255, 144, 29);
}

.icon-winner .path3:before {
  content: "\e95d";
  margin-left: -1em;
  color: rgb(255, 144, 29);
}

.icon-winner .path4:before {
  content: "\e95e";
  margin-left: -1em;
  color: rgb(255, 144, 29);
}

.icon-winner .path5:before {
  content: "\e95f";
  margin-left: -1em;
  color: rgb(255, 144, 29);
}

.icon-winner .path6:before {
  content: "\e960";
  margin-left: -1em;
  color: rgb(255, 144, 29);
}

.icon-winner .path7:before {
  content: "\e961";
  margin-left: -1em;
  color: rgb(255, 144, 29);
}

.icon-winner .path8:before {
  content: "\e962";
  margin-left: -1em;
  color: rgb(255, 144, 29);
}

.icon-winner .path9:before {
  content: "\e963";
  margin-left: -1em;
  color: rgb(255, 144, 29);
}

.icon-winner .path10:before {
  content: "\e964";
  margin-left: -1em;
  color: rgb(255, 144, 29);
}

.icon-winner .path11:before {
  content: "\e965";
  margin-left: -1em;
  color: rgb(255, 144, 29);
}

.icon-winner .path12:before {
  content: "\e966";
  margin-left: -1em;
  color: rgb(255, 144, 29);
}

.icon-winner .path13:before {
  content: "\e967";
  margin-left: -1em;
  color: rgb(255, 144, 29);
}

.icon-winner .path14:before {
  content: "\e968";
  margin-left: -1em;
  color: rgb(255, 144, 29);
}

.icon-winner .path15:before {
  content: "\e969";
  margin-left: -1em;
  color: rgb(247, 188, 20);
}

.icon-winner .path16:before {
  content: "\e96a";
  margin-left: -1em;
  color: rgb(247, 188, 20);
}

.icon-winner .path17:before {
  content: "\e96b";
  margin-left: -1em;
  color: rgb(255, 177, 37);
}

.icon-winner .path18:before {
  content: "\e96c";
  margin-left: -1em;
  color: rgb(255, 177, 37);
}

.icon-winner .path19:before {
  content: "\e96d";
  margin-left: -1em;
  color: rgb(254, 209, 48);
}

.icon-winner .path20:before {
  content: "\e96e";
  margin-left: -1em;
  color: rgb(247, 188, 20);
}

.icon-winner .path21:before {
  content: "\e96f";
  margin-left: -1em;
  color: rgb(254, 209, 48);
}

.icon-winner .path22:before {
  content: "\e970";
  margin-left: -1em;
  color: rgb(247, 188, 20);
}

.icon-winner .path23:before {
  content: "\e971";
  margin-left: -1em;
  color: rgb(255, 177, 37);
}

.icon-winner .path24:before {
  content: "\e972";
  margin-left: -1em;
  color: rgb(255, 144, 29);
}

.icon-winner .path25:before {
  content: "\e973";
  margin-left: -1em;
  color: rgb(255, 177, 37);
}

.icon-winner .path26:before {
  content: "\e974";
  margin-left: -1em;
  color: rgb(255, 144, 29);
}

.icon-youtube:before {
  content: "\e975";
}
