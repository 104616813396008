@import './color-theme';

$themes:(default:(bg-primary: $bg-primary,
    text-primary: $text-primary,
    text-secondary: $text-secondary,
    text-white: $text-white,
    bg-card: $text-white,
    // bgdash-head: $bg-head,
    // logoWhite: url('../images/logo/Logo_Universidad-de-La-Salle.svg'),
    // bgdash-body: $bg-dashboard,
    // yellowPrimary500: $palette-primary-500,
    // blueSecondary500: $palette-secondary-500,
    // svgBook: url('../images/svgs/book-dark.svg'),
    // svgVirtual: url('../images/svgs/training-dark.svg'),
    // bgDashLink: $bg-dash-link,
    // borderLink: $border-link,
    // bgCard: $bg-card,
    // bgHover: $bg-hover,
    // textNumber: $text-number,
    // textPrimary: $text-primary,
    // textSecondary: $text-secondary
  ),
  dark: (bg-primary: $bg-primary-dark,
    text-primary: $text-primary-dark,
    text-secondary: $text-secondary-dark,
    text-white: $text-white,
    bg-card: $bg-card-dark, // bgdash-head: $bg-head-dark,
    // textBlack: $text-primary-black,
    // logoWhite: url('../images/logo/Logo_Universidad-de-La-Salle_WHITE.svg'),
    // bgdash-body: $bg-dashboard-dark,
    // yellowPrimary500: $palette-primary-500,
    // blueSecondary500: $palette-secondary-500,
    // svgBook: url('../images/svgs/book-dark.svg'),
    // svgVirtual: url('../images/svgs/training-dark.svg'),
    // bgDashLink: $bg-dash-link-dark,
    // borderLink: $border-link-dark,
    // bgCard: $bg-card-dark,
    // bgHover: $bg-hover-dark,
    // textNumber: $text-number,
    // textPrimary: $text-primary-dark,
    // textSecondary: $text-secondary-dark
  ));

$fontsizes:(default:(font12: $font12,
    font14: $font14,
    font16: $font16,
    font18: $font18,
    font20: $font20,
    font24: $font24,
    font34: $font34,
    font36: $font36,
    font48: $font48,
    font50: $font50,
    font60: $font60,
    font66: $font66,
    font70: $font70,
    font72: $font72,
    font90: $font90,
    font91: $font91,
    font92: $font92,
    font93: $font93,
    font120: $font120),
  x2: (font12: $font12x,
    font14: $font14x,
    font16: $font16x,
    font18: $font18x,
    font20: $font20x,
    font24: $font24x,
    font34: $font34x,
    font36: $font36x,
    font48: $font48x,
    font50: $font50x,
    font60: $font60x,
    font66: $font66x,
    font70: $font70x,
    font72: $font72x,
    font90: $font90x,
    font91: $font91x,
    font92: $font92x,
    font93: $font93x,
    font120: $font120x));

@mixin themed() {

  @each $theme,
  $map in $themes {
    .theme--#{$theme} & {
      $theme-map: () !global;

      @each $key,
      $submap in $map {
        $value: map-get(map-get($themes, $theme), '#{$key}');
        $theme-map: map-merge($theme-map, ($key: $value)) !global;
      }

      @content;
      $theme-map: null !global;
    }
  }
}

@function t($key) {
  @return map-get($theme-map, $key);
}

@mixin fontsized() {

  @each $fontsize,
  $map in $fontsizes {
    .fontx--#{$fontsize} & {
      $fontsize-map: () !global;

      @each $keyfont,
      $submap in $map {
        $value: map-get(map-get($fontsizes, $fontsize), '#{$keyfont}');
        $fontsize-map: map-merge($fontsize-map, ($keyfont: $value)) !global;
      }

      @content;
      $fontsize-map: null !global;
    }
  }
}


@function f($keyfont) {
  @return map-get($fontsize-map, $keyfont);
}
