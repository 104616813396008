.top_bar {
  display: none;
  background-color: $primary900;
  flex-direction: row;

  @media #{$large-up} {
    display: flex;
  }

  .top_colm_equal {
    padding: 0 25px;
    display: flex;
    //flex-basis: 50%;
    flex: 1 1 auto;
    flex-direction: row;

    button.mdl-button.custom {
      color: $text-secondary-dark;
      font-weight: bold;
      font-family: "Roboto-Bold";
      letter-spacing: 1.25px;

      @include fontsized() {
        font-size: f(font18);
      }

      &.icon_ctn {
        font-family: initial;
        font-weight: normal;
        margin-right: 15px;
      }
    }

    ul {
      li.mdl-menu__item {
        display: flex;

        a {
          text-decoration: none;
          width: 100%;
          font-size: 12px;
          color: $text-primary;

          @include themed() {
            color: t(text-secondary);
          }
        }
      }
    }

    &._small {
      flex-basis: 25%;
    }

    &._left {
      margin-left: auto;
      justify-content: flex-end;
    }

    .ctn_form_search {
      margin-right: 15px;

      .mdl-textfield--expandable {
        padding: 0;

        .mdl-textfield__expandable-holder {
          margin-left: 0;
          margin-right: 32px;

          .mdl-textfield__input {
            color: rgba(255, 255, 255, 0.815);
            font-family: 'Roboto-Regular';

          }
        }

        .mdl-button--icon {
          top: 0;
          right: 0;
          color: hsla(0, 0%, 100%, .6);
        }
      }
    }
  }

  .mdl-menu.lang_mdl {
    width: 200px;

    .mdl-menu__item {
      padding: 0;

      &:first-child {
        cursor: initial;
        border-bottom: 1px solid #ddd;

        @include themed() {
          border-bottom: 1px solid rgba(221, 221, 221, 0.15);
        }

        &:hover {
          background: transparent;
        }
      }

      a {
        font-family: 'Roboto-Regular';
        padding: 0 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        //width: 190px;
        //min-width: 190px;

        span {
          color: transparent;
          margin-left: auto;
        }

        &.is-active {
          background: $secondary50;

          @include themed() {
            //background-color: t(bg-card);
            background: rgba(255, 248, 225, 0.04);
          }

          span {
            color: $secondary500;
          }
        }
      }

      p.tlt_home {
        padding: 0 20px;
        display: flex;
        flex-direction: row;
        font-family: 'Roboto Condensed Bold';
        align-items: center;
        color: $text-primary;
        font-size: 16px;
        letter-spacing: .15px;
        margin-bottom: 0;
        width: 100%;

        @include themed() {
          color: $text-white;
        }

        span {
          color: $text-secondary;
          margin-left: auto;

          @include themed() {
            color: $text-white;
          }
        }
      }
    }

  }

  .mdl-menu.access_mdl {
    width: 200px;

    .mdl-menu__item {
      padding: 0;

      &:first-child {
        cursor: initial;
        border-bottom: 1px solid #ddd;

        @include themed() {
          border-bottom: 1px solid rgba(221, 221, 221, 0.15);
        }

        &:hover {
          background: transparent;
        }
      }

      a {
        font-family: 'Roboto-Regular';
        padding: 0 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        //width: 190px;
        //min-width: 190px;

        span {
          color: $text-secondary;
          margin-left: auto;

          @include themed() {
            color: $text-secondary-dark;
          }
        }

        &.is-active {
          background: $secondary50;

          @include themed() {
            //background-color: t(bg-card);
            background: rgba(255, 248, 225, 0.04);
          }

          span {
            color: $secondary500;
          }
        }
      }

      p.tlt_home {
        padding: 0 20px;
        display: flex;
        flex-direction: row;
        font-family: 'Roboto Condensed Bold';
        align-items: center;
        color: $text-primary;
        font-size: 16px;
        letter-spacing: .15px;
        margin-bottom: 0;
        width: 100%;

        @include themed() {
          color: $text-white;
        }

        span {
          color: $text-secondary;
          margin-left: auto;

          @include themed() {
            color: $text-white;
          }
        }
      }
    }

  }
}

header {
  &.custom {
    position: relative;
    min-height: 56px;
    display: flex;
    align-items: center;
    //border-bottom: 1px solid #ddd;
    background-color: $bg-primary;

    @media #{$tablet-up} {
      min-height: 77px;
    }

    @include themed() {
      background-color: t(bg-card);
    }

    .box_logo {
      position: absolute;
      z-index: 101;
      box-shadow: $shadow-1;
      display: none;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      text-align: center;
      background-color: $bg-green;
      top: 0;
      left: 0;
      right: 50px;
      height: 132px;
      width: 460px;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;

      @media #{$large-up} {
        display: flex;
      }

      img {
        max-width: 100%;
        width: 300px;
        object-fit: contain;
        height: 90px;
      }
    }

    .custom_colm {
      flex-basis: 50%;
      padding: 0 3px;

      @media #{$large-up} {
        padding: 0 25px;
      }

      .mv_mdl {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        @media #{$large-up} {
          display: none;
        }

        a.mdl-button.hamb,
        button.mdl-button.hamb {
          display: flex;
          align-items: center;
          flex-direction: row;
          text-transform: initial;


          span {
            color: $text-secondary;

            @include themed() {
              color: t(text-secondary);
            }
          }

          span.name_hamb {
            font-family: 'Roboto Condensed Bold';
            color: $primary500;
            letter-spacing: 0.25px;
            font-size: 20px;
            padding-left: 10px;
          }
        }

        a.mdl-button.icons,
        button.mdl-button.icons {
          margin-right: 15px;

          &:last-child {
            margin-right: 5px;
          }

          span {
            color: $text-secondary;

            @include themed() {
              color: t(text-secondary);
            }
          }
        }

        .ctn_lang {
          ul.mdl-menu {

            .mdl-menu__item {
              a {
                &.lang {
                  font-family: 'Roboto-Regular';
                  text-decoration: none;
                  width: 100%;
                  font-size: 12px;
                  color: $text-primary;

                  @include themed() {
                    color: $text-secondary-dark;
                  }

                  span {
                    color: transparent;
                    visibility: 0;
                    margin-left: auto;
                    padding-right: 0;
                  }

                  &.is-active {
                    background-color: $secondary50;

                    @include themed() {
                      background-color: rgba(255, 248, 225, 0.07);
                    }

                    span {
                      color: $secondary500;
                      visibility: 1;

                    }
                  }
                }
              }
            }
          }
        }
      }

      ._right {
        text-align: right;
        margin-left: auto;

        a.mdl-button.login,
        button.mdl-button.login {
          color: $text-white;
          font-weight: bold;
          padding: 0 20px;
          // display: flex;
          // flex-direction: row;
          // justify-content: center;
          // align-items: center;
          height: 48px;
          background-color: $secondary500;
          letter-spacing: 1.25px;
          line-height: 16px;
          min-width: 180px;

          span.material-icons {
            padding-right: 10px;
          }

          span.text {
            position: relative;
            top: 2px;
          }
        }

        a.mdl-button.custom,
        button.mdl-button.custom {
          color: $text-primary;
          font-weight: bold;
          height: 48px;
          letter-spacing: 1.25px;
          line-height: 16px;
          margin-right: 20px;

          @include themed() {
            color: $primary500;
          }

          span {
            padding-right: 15px;
          }
        }

        .mv_mdl {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;

          @media #{$large-up} {
            display: none;
          }
        }

        .hd_mdl {
          display: none;

          @media #{$large-up} {
            display: flex;
            justify-content: flex-end;
          }

          a.mdl-button.enlace,
          button.mdl-button.enlace {
            span {
              color: $primary500;
            }
          }

          .act-login {
            position: relative;
          }

          ul.login_access {
            width: 200px;
            //margin-right: 10px;

            li.mdl-menu__item {
              display: flex;

              a {
                text-decoration: none;
                width: 100%;
                font-size: 14px;
                color: $text-primary;

                @include themed() {
                  color: t(text-secondary);
                }
              }
            }
          }
        }
      }
    }
  }
}

.menu_mv_mdl {
  position: fixed;
  z-index: 102;
  top: 0;
  overflow: scroll;
  height: 100%;
  transform: translateX(-101%);
  background-color: #fff;
  transform-style: preserve-3d;
  will-change: transform;
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: transform;

  &.style_right {
    right: 0;
    transform: translateX(100%);

    &.is-active {
      width: 100%;
      box-shadow: $shadow-2;
      transform: translateX(0);

      @media #{$tablet-up} {
        width: 320px;
      }
    }
  }

  @include themed() {
    background-color: t(bg-primary);
  }

  &.is-active {
    width: 100%;
    box-shadow: $shadow-2;
    transform: translateX(0);

    @media #{$tablet-up} {
      width: 320px;
    }
  }



  .ctn_mdl_resp {


    .menu_mdl_header {
      padding: 0 18px;
      display: flex;
      flex-direction: row;
      align-items: center;
      min-height: 56px;
      background-color: $primary500;

      span.auth {
        color: $primary50;
      }

      span.logo_name {
        font-family: 'Roboto Condensed Bold';
        color: $text-white;
        font-size: 20px;
        letter-spacing: 0.25px;
      }

      a.mdl-button {
        position: relative;
        right: -16px;
        min-width: 20px;
        margin-left: auto;
        color: $primary50;
      }
    }

    .ctn_user {
      padding: 0 18px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-height: 56px;
      background-color: $primary500;

      p {
        font-family: 'Roboto Condensed Bold';
        color: $text-white;
        font-size: 20px;
        letter-spacing: 0.25px;
      }
    }

  }

  .action_primary {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #FAFAFA;

    &:last-child {
      margin-bottom: 20px;
    }

    @include themed() {
      border-bottom: 1px solid 1px solid rgba(250, 250, 250, 0.07);
    }



    p.title_stn {
      font-family: 'Roboto Condensed Bold';
      line-height: 24px;
      letter-spacing: 0.15px;
      margin: 0;
      height: 48px;
      padding: 0 16px;
      width: auto;
      display: flex;
      flex-direction: row;
      align-items: center;

      &.second {
        color: $text-secondary;

        @include themed() {
          color: $text-secondary-dark;
        }
      }

      @include themed() {
        color: $text-white;
      }

      span {
        color: $text-secondary;
        margin-left: auto;
        padding-right: 0;

        @include themed() {
          color: $text-secondary-dark;
        }
      }
    }

    a.mdl-button {
      font-family: 'Roboto Condensed Bold';
      line-height: 24px;
      letter-spacing: 0.15px;
      position: relative;
      text-align: left;
      text-transform: initial;
      height: 48px;
      width: auto;
      display: flex;
      flex-direction: row;
      align-items: center;

      @include themed() {
        color: $text-white;
      }

      span {
        padding-right: 10px;
      }

      &.access {
        font-family: 'Roboto-Regular';
        color: $text-secondary;

        @include themed() {
          color: $text-secondary-dark;
        }

        span {
          //color: transparent;          
          margin-left: auto;
          padding-right: 0;
        }

        &.is-active {
          //background-color: $secondary50;

          span {
            color: $secondary500;
            //visibility: 1;

          }
        }
      }

      &.lang {
        font-family: 'Roboto-Regular';
        color: $text-secondary;

        @include themed() {
          color: $text-secondary-dark;
        }

        span {
          color: transparent;
          visibility: 0;
          margin-left: auto;
          padding-right: 0;
        }

        &.is-active {
          background-color: $secondary50;

          @include themed() {
            background-color: rgba(255, 248, 225, 0.07);
          }

          span {
            color: $secondary500;
            visibility: 1;

          }
        }
      }

      &.green_link {
        color: $text-secondary;

        @include themed() {
          color: $text-secondary-dark;
        }

        span {
          color: $primary500;
        }

        &:hover {
          color: $primary500;
        }
      }

      &.login {
        color: $secondary500;
      }
    }
  }
}

.bg_black {
  display: none;
  position: fixed;
  top: 0;
  z-index: 101;
  background-color: rgba(250, 250, 250, 0.75);
  height: 100%;
  width: 100%;

  &.is-active {
    display: block;
  }
}