/* For use in app/styles/color-palette.scss */
// primary
$primary50:#f1f8e9;
$primary100:#dcedc8;
$primary200:#c5e1a5;
$primary300:#aed581;
$primary400:#9ccc65;
$primary500:#8bc34a;
$primary600:#7cb342;
$primary700:#689f38;
$primary800:#558b2f;
$primary900:#33691e;
$primaryA100:#ebffdd;
$primaryA200:#ceffaa;
$primaryA400:#b0ff77;
$primaryA700:#a2ff5d;

// secondary 
$secondary50:#fff8e1;
$secondary100:#ffecb3;
$secondary200:#ffe082;
$secondary300:#ffd54f;
$secondary400:#ffca28;
$secondary500:#ffc107;
$secondary600:#ffb300;
$secondary700:#ffa000;
$secondary800:#ff8f00;
$secondary900:#ff6f00;
$secondaryA100:#ffe57f;
$secondaryA200:#ffd740;
$secondaryA400:#ffecb3;
$secondaryA700:#ffab00;

// base
$bg-primary: #fafafa;
$bg-green: $primary900;
$text-primary: rgba(0, 0, 0, 0.87);
$text-white: #fff;
$text-secondary: rgba(0, 0, 0, 0.6);
$border-title: #64dd17;

// dark 
$bg-primary-dark: #121212;
$bg-card-dark: #222222;
$text-primary-dark: #fff;
$hover-list-dark: #2b2b2b;
$text-secondary-dark: rgba(255, 255, 255, 0.6);

// fonts x
$font12: 12px;
$font14: 14px;
$font16: 16px; //--
$font18: 18px; //--
$font20: 20px;
$font24: 24px;
$font34: 34px;
$font36: 36px;
$font48: 48px;
$font50: 50px;
$font60: 60px;
$font66: 66px;
$font70: 70px;
$font72: 72px;
$font90: 90px;
$font91: 91px;
$font92: 92px;
$font93: 93px;
$font120: 120px;

$zoom: 2px;
// zoom
$font12x: 12px+$zoom;
$font14x: 14px+$zoom;
$font16x: 16px+$zoom; //--
$font18x: 18px+$zoom; //--
$font20x: 20px+$zoom;
$font24x: 24px+$zoom;
$font34x: 34px+$zoom;
$font36x: 36px+$zoom;
$font48x: 48px+$zoom;
$font50x: 50px+$zoom;
$font60x: 60px+$zoom;
$font66x: 66px+$zoom;
$font70x: 70px+$zoom;
$font72x: 72px+$zoom;
$font90x: 90px+$zoom;
$font91x: 91px+$zoom;
$font92x: 92px+$zoom;
$font93x: 93px+$zoom;
$font120x: 120px+$zoom;

//elevation shadows
$shadow-0: none !important;
$shadow-1: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
0 3px 1px -2px rgba(0, 0, 0, 0.12),
0 1px 5px 0 rgba(0, 0, 0, 0.2);
$shadow-2: 0 4px 5px 0 rgba(0, 0, 0, 0.14),
0 1px 10px 0 rgba(0, 0, 0, 0.12),
0 2px 4px -1px rgba(0, 0, 0, 0.3);
$shadow-3: 0 8px 17px 2px rgba(0, 0, 0, 0.14),
0 3px 14px 2px rgba(0, 0, 0, 0.12),
0 5px 5px -3px rgba(0, 0, 0, 0.2);
$shadow-4: 0 16px 24px 2px rgba(0, 0, 0, 0.14),
0 6px 30px 5px rgba(0, 0, 0, 0.12),
0 8px 10px -7px rgba(0, 0, 0, 0.2);
$shadow-5: 0 24px 38px 3px rgba(0, 0, 0, 0.14),
0 9px 46px 8px rgba(0, 0, 0, 0.12),
0 11px 15px -7px rgba(0, 0, 0, 0.2);

// primario
