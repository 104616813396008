footer {
  &.custom {
    padding: 45px 20px;
    background-color: $primary900;
  }

  .copy_footer_ifo {

    margin-right: 40px;

    .ctn_logo {
      img {
        width: 300px;
        height: 90px;
        object-fit: contain;
      }
    }

    .ctn_social {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      margin-bottom: 10px;

      a.mdl-button {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 24px;

        span {
          font-size: 20px;
          color: $text-secondary-dark;
        }
      }
    }

    .ctn_body {
      p {
        color: $text-secondary-dark;
        font-family: 'Roboto-Regular';
        line-height: 1;
        font-size: 14px;
        letter-spacing: 0.25px;

        @include fontsized() {
          font-size: 14px + 2px;
        }
      }
    }
  }

  .row_footer {
    display: flex;
    flex-direction: column;

    @media #{$tablet-up} {
      flex-direction: row;
      //flex-wrap: wrap;

    }

    >.colm_footer {
      flex-basis: 100%;

      @media #{$tablet-up} {
        flex-basis: 100%;
      }

      &.mdl_custom {
        @media #{$tablet-up} {
          flex-basis: 33.33333%;
        }
      }

      .list_footer {
        display: flex;
        align-items: center;
        height: 100%;
        padding-top: 15px;

        .list_footer_center {
          width: 100%;
        }

        a.mdl-button {
          display: flex;
          flex-direction: row;
          align-items: center;
          color: $text-white;
          margin-bottom: 20px;

          span {
            color: $text-secondary-dark;
            margin-left: auto;
          }
        }

        @media #{$large-up} {
          display: none;
        }
      }

      .row_int {
        display: none;

        @media #{$large-up} {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          // styles for medium screens and larger
        }

        .colm_int {
          flex-basis: 33.333333%;

          .copy_links {
            display: flex;
            width: 85%;
            flex-direction: column;

            p.title_links {
              font-family: 'Roboto Condensed Bold';
              font-size: 16px;
              line-height: 24px;
              color: $text-white;
              letter-spacing: 0.15px;
              margin-bottom: 5px;

              @include fontsized() {
                font-size: 16px + 2px;
              }
            }

            a.mdl-button {
              display: flex;
              //width: max-content;
              color: $text-secondary-dark;
              overflow: initial;
              align-items: center;
              text-align: left;
              padding: 2px 3px 3px;
              height: auto;
              min-width: initial;
              font-family: 'Roboto-Regular';
              font-size: 14px;
              line-height: 21px;
              letter-spacing: 0.25px;
              text-transform: initial;
              margin-bottom: 0px;

              @include fontsized() {
                font-size: 16px + 2px;
              }

              &:hover {
                //background-color: transparent;
              }
            }
          }
        }
      }
    }
  }
}


// footer {
//   background: $bg-primary;
//   padding-top: 30px;
//   z-index: 2;
//   position: relative;

//   @include themed() {
//     background: t(bgdash-body);
//   }

//   //padding: 20px;
//   .menu_footer {
//     ul {
//       margin: 0;
//       padding: 0;
//       list-style: none;

//       &.hd-list {
//         p.title {
//           font-family: "Roboto Condensed Bold";
//           color: $text-primary;
//           font-size: $font16;
//           line-height: 24px;
//           letter-spacing: 0.15px;

//           @include fontsized() {
//             font-size: f(font16);
//           }

//           @include themed() {
//             color: t(textPrimary);
//           }

//           &.blank {
//             margin-bottom: 40px;
//           }
//         }

//         a.link__footer_hd {
//           font-family: "Roboto-Regular";
//           color: $text-secondary;
//           font-size: $font16;
//           line-height: 35px;
//           letter-spacing: 0.5px;
//           text-decoration: none;

//           @include fontsized() {
//             font-size: f(font16);
//           }

//           @include themed() {
//             color: t(textSecondary);
//           }

//           &:focus {
//             text-decoration: underline;
//             color: $palette-secondary-500;
//           }

//           &:hover {
//             text-decoration: underline;
//             color: $palette-secondary-500;
//           }
//         }
//       }
//     }

//     a.link__footer-arrow {
//       font-family: "Roboto Condensed Bold";
//       display: flex;
//       font-size: $font16;
//       align-items: center;
//       flex-direction: row;
//       text-decoration: none;
//       padding: 12px 17px;
//       color: $text-link-color;
//       border-bottom: 1px solid $divider-color;

//       @include fontsized() {
//         font-size: f(font16);
//       }

//       @include themed() {
//         color: t(textSecondary);
//         border-bottom: 1px solid $divider-btn;
//       }

//       @media #{$medium-and-up} {
//         border-bottom: 1px solid transparent;
//       }

//       &:focus {
//         color: $text-primary;
//         border-bottom: 1px solid transparent;
//       }

//       &:hover {
//         color: $text-primary;
//         border-bottom: 1px solid transparent;

//         @include themed() {
//           background: none;
//           color: t(textPrimary);
//         }

//         @media #{$medium-and-up} {
//           font-size: $font16;
//           background: $palette-primary-50;

//           @include fontsized() {
//             font-size: f(font16);
//           }
//         }

//         span.arrow {
//           @include themed() {
//             color: t(textPrimary);
//           }
//         }
//       }

//       span.arrow {
//         color: $text-link-color;
//         margin-left: auto;

//         @include themed() {
//           color: t(textSecondary);
//         }
//       }
//     }

//     a.link__footer {
//       display: block;
//       font-family: "Roboto Condensed Bold";
//       font-size: $font16;
//       color: $text-primary;
//       line-height: 24px;
//       letter-spacing: 0.15px;
//       text-decoration: none;
//       padding: 12px;

//       @include fontsized() {
//         font-size: f(font16);
//       }

//       @include themed() {
//         color: t(textPrimary);
//       }

//       @media #{$medium-and-up} {
//         font-size: $font16;

//         @include fontsized() {
//           font-size: f(font16);
//         }
//       }

//       &:hover {
//         text-decoration: underline;
//         color: $palette-secondary-500;
//       }

//       &.link_md {
//       }
//     }

//     .social-info {
//       margin-bottom: 20px;

//       p.line {
//         border-bottom: 1px solid $divider-color;
//       }

//       p.title {
//         font-family: "Roboto-Regular";
//         font-size: $font12;
//         color: $text-secondary;
//         margin-bottom: 5px;

//         @include fontsized() {
//           font-size: f(font12);
//         }

//         @include themed() {
//           color: t(textSecondary);
//         }
//       }

//       .redes {
//         a {
//           padding: 5px;
//           //color: $social-color;
//           text-decoration: none;
//           color: $social-color;

//           @include themed() {
//             color: t(social-link);
//           }

//           span {
//           }

//           &:hover {
//             @include themed() {
//               color: t(blueSecondary500);
//             }
//           }
//         }
//       }
//     }
//   }

//   .bottom_footer {
//     display: flex;
//     flex-direction: column;
//     position: relative;

//     .column_footer {
//       display: flex;
//       flex-direction: column;

//       @media #{$medium-and-up} {
//         //flex-grow: 1;
//         //flex-wrap: wrap;
//         align-items: center;
//         flex-direction: row;
//         //width: 100%;
//         flex-direction: row;
//       }

//       .item {
//         width: 100%;

//         @media #{$medium-and-up} {
//           display: flex;
//           justify-content: center;
//         }

//         a {
//           display: block;
//           margin-bottom: 10px;
//           font-family: "Roboto-Regular";
//           font-size: $font14;
//           line-height: 16px;
//           letter-spacing: 1.25px;
//           color: $text-link-color;

//           @include fontsized() {
//             font-size: f(font14);
//           }

//           @include themed() {
//             color: t(textSecondary);
//           }

//           &:focus {
//             color: $palette-secondary-500;
//             text-decoration: underline;
//           }

//           &:hover {
//             text-decoration: underline;
//             color: $palette-secondary-500;
//           }

//           @media #{$medium-and-up} {
//             text-decoration: none;
//             margin-bottom: 0;
//           }
//         }
//       }
//     }

//     p {
//       &.copy {
//         font-family: "Roboto-Regular";
//         font-size: $font14;
//         line-height: 20px;
//         letter-spacing: 0.25px;
//         color: $text-primary;
//         margin: 0;
//         margin-bottom: 10px;

//         @include fontsized() {
//           font-size: f(font14);
//         }

//         @include themed() {
//           color: t(textPrimary);
//         }
//       }
//     }

//     .country {
//       display: flex;
//       //background: $bg-dashboard;
//       //overflow: hidden;
//       position: relative;
//       width: 100%;

//       @media #{$tablet-up} {
//         display: flex;
//         justify-content: center;
//         width: 80%;
//       }

//       // @media #{$medium-and-up} {
//       //   width: 80%;
//       // }

//       .mdl-textfield {
//         display: flex;
//         //width: 180px;
//         justify-content: flex-end;
//         width: 100%;

//         @media #{$tablet-up} {
//           width: 180px;
//         }
//       }

//       .getmdl-select .mdl-icon-toggle__label {
//         margin-top: 0;
//       }

//       .mdl-menu__container {
//         @media #{$tablet-up} {
//           top: -100px;
//         }
//       }

//       //position: relative;
//       .mdl-textfield__input {
//         font-family: "Roboto-Bold";
//         font-size: $font16;
//         line-height: 24px;
//         letter-spacing: 0.15px;
//         border: none;

//         @include fontsized() {
//           font-size: f(font16);
//         }

//         //width: 180px;
//         @include themed() {
//           color: t(textPrimary);
//         }
//       }
//     }
//   }
// }
